import React from 'react';
import { Button, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CustomLoader from '../components/common/CustomLoader';

interface ButtonProps {
  label: string;
  disabled?:boolean;
  onClick: () => void;
  variant?: 'text' | 'outlined' | 'contained';
}

interface DialogLayoutProps {
  open: boolean;
  drawerWidth?: string;
  title: string;
  handleClose: () => void;
  children?: React.ReactNode;
  negativeActions?: ButtonProps[]|null;
  positiveActions?: ButtonProps[]|null;
  actionLoader?: boolean;
}

const DialogLayout: React.FC<DialogLayoutProps> = ({ open, drawerWidth, title, handleClose, children, negativeActions, positiveActions, actionLoader }) => {

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: drawerWidth || '60vw',
          maxWidth: 800,
        },
      }}
      onClose={handleClose}>
      <div className="d-flex flex-column overflow-hidden" style={{ maxHeight: '100vh' }}>
        <div className="d-flex gap-3 align-items-center" style={{ padding: '20px' }}>
          <div className="d-flex flex-grow-1">
            <span className="fs-med-lg-semibold">{title}</span>
          </div>
          <div className="d-flex flex-shrink-0">
            <div className="d-flex justify-content-center align-items-center cursor-pointer" style={{ border: '1px solid #E5E5FF', borderRadius: '50%', width: '40px', height: '40px' }} onClick={handleClose}>
              <CloseIcon color="primary" />
            </div>
          </div>
        </div>

        <div className=" flex-grow-1 w-100  d-flex overflow-auto" style={{ padding: '20px' }}>
          {actionLoader ? <div className='w-100 justify-content-center align-items-center'>


            <CustomLoader height='10' size={60} />
          </div> :
            <>
              {children}
            </>
          }
        </div>

        <div className="d-flex gap-2 justify-content-end align-items-center" style={{ padding: '20px' }}>
          {
            negativeActions?.map((data, key) => (
              <Button
                sx={{ textTransform: 'none', minWidth: '100px' }}
                variant="text"
                color="primary"
                onClick={data.onClick}
                disabled={actionLoader|| data.disabled}
                key={key}>
                <span className="fs-normal-med">{data.label}</span>
              </Button>
            ))
          }
          {
            positiveActions?.map((data, key) => (
              <Button
                sx={{ textTransform: 'none', minWidth: '100px' }}
                variant={data.variant ? data.variant : "contained"}
                color="primary"
                onClick={data.onClick}
                disableElevation
                disabled={actionLoader || data.disabled}
                key={key}>
                <span className="fs-normal-med">{data.label}</span>
              </Button>
            ))
          }
        </div>
      </div>
    </Dialog>
  );
};

export default DialogLayout;