export interface userDateProps {
  fullName: string;
  mobileNo: string;
  email: string;
  password: string;
  dob: string | null;
  address: string | null;
}


export enum UserRole{
  ADMIN = "ROLE_ADMIN",
  CLAIMANT="ROLE_CLAIMANT",
  RESPONDENT="ROLE_RESPONDENT",
  CLAIMANT_ADVOCATE="ROLE_CLAIMANT_ADVOCATE",
  RESPONDENT_ADVOCATE="ROLE_RESPONDENT_ADVOCATE",
  SUPER_ADMIN="ROLE_SUPER_ADMIN",
  NEUTRAL="ROLE_NEUTRAL"
}