import { Badge, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import FileService from "../../network/FileService";

interface NotificationCardProps {
  imgUrl: string | null;
  userUniqueId:any;
  userName: string;
  content: string;
  read: boolean;
  time: string;
  onClick: () => void
}

const NotificationCard = ({
  imgUrl,
  userUniqueId,
  userName,
  content,
  time,
  read,
  onClick,
}: NotificationCardProps) => {

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [url, setUrl] = useState<string>("");

  const getProfileImage = async (uniqueId: any, fileName: string): Promise<string | undefined> => {
      try {
          setIsLoadingImage(true);
          const res: ArrayBuffer = await FileService.downloadUserFile([uniqueId, "profile", fileName]) as ArrayBuffer;
          const blob = new Blob([res]);
          const url = window.URL.createObjectURL(blob);
          return url;
      } catch (error) {
          console.error("Error fetching profile image:", error);
          return undefined;
      } finally {
          setIsLoadingImage(false);
      }
  };

  useEffect(() => {
      if (!imgUrl || !userUniqueId) return;

      const fetchImage = async () => {
          try {
              const tempUrl = await getProfileImage(userUniqueId, imgUrl);
              if (tempUrl) {
                  setUrl(tempUrl);
              }
          } catch (error) {
              console.error("Error fetching profile image:", error);
          }
      };

      fetchImage();
  }, [userUniqueId,imgUrl]);




  return (
    <Box className="d-flex gap-3 m-2 cursor-pointer" onClick={onClick}>
      <div>
        <Badge variant={read ?"standard":"dot"} color="error">
        {isLoadingImage ? (
                            <img
                                src={"/user-placeholder.png"}
                                alt="profile"
                                width={40}
                                height={40}
                                className="rounded-circle mt-4"
                            />
                        ) : imgUrl && url ? (
                            <img
                                src={url}
                                alt="profile"
                                width="36.4892578125px"
                                height="36.1602783203125px"
                                className="rounded-circle"
                            />
                        ) : (
                            <Box
                                className="d-flex justify-content-center align-items-center rounded-circle bg-light-blue1 text-primary fw-bold"
                                width="36.4892578125px"
                                height="36.1602783203125px"
                            >
                                {userName[0]?.toUpperCase()}
                            </Box>
                        )}
        </Badge>
      </div>
      <Box className="me-3 gap-5">
        <div className="fs-sm-lg-semibold">{userName}</div>
        <div className="fs-normal-light  text-grey" style={{ lineHeight: '20px' }}>{content}</div>
        <div>
          <div className="fs-sm-light py-1 px-2 rounded-2 bg-light-blue2 d-inline-block">
            {time}
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default NotificationCard;
