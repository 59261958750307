import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";

import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";

import ReasoningRow from "./ReasoningRow";
import {
  addGrounds,
  deleteGrounds,
  setReasoningExpanded,
} from "../../../../redux/slice/caseSlice";
import { Grounds } from "../../../../model/caseDocument/grounds";
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
} from "../../../../constants/nav-constants";
import { useSnackbar } from "../../../../context/SnackbarContext";

const Reasoning = () => {

  const { showSnackbar } = useSnackbar()
  const { caseFileDetail, groundsExpanded, response, hasResponse, isRespondent } =
    store.getState().case;
  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;
  const isAdmin = roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN;


  let caseFileResponse = {};
  let caseFileResponseDetail = {};
  if (response) {
    caseFileResponse = response;
    caseFileResponseDetail = response;
  }

  const dispatch: AppDispatch = useDispatch();

  const { grounds }: any =
    isRespondent || hasResponse ? caseFileResponseDetail : caseFileDetail;
  if (!grounds.length) {
    dispatch(
      addGrounds()
    );
  }


  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        dispatch(setReasoningExpanded(groundsExpanded === panel ? -1 : panel));
      }
    };

  const handleDeletereasoning = (index: number) => {
    dispatch(deleteGrounds(index));
    showSnackbar("Deleted Grounds / Reasoning")

  };
  return (
    <div className="d-flex flex-column  w-100 overflow-auto">
      {grounds.map((grounds: Grounds, i: number) => (
        <Accordion
          key={i}
          className="border-0"
          expanded={groundsExpanded === i}
          onChange={handleChangeAccordion(i)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="d-flex gap-3 justify-content-between align-items-center w-100">
              <div className="d-flex gap-3 align-items-center">
                <img src={DotIcon} alt="dot" width={24} height={24} />
                <Box
                  className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                  width={28.21}
                  height={28.21}
                >
                  {i + 1}
                </Box>
                {!(groundsExpanded === i) && (
                  <>
                    {grounds.groundsDetail && (
                      <Box
                        className="text-grey d-inline-block text-truncate"
                        maxWidth={400}
                      >
                        {grounds.groundsDetail}
                      </Box>
                    )}
                    <IconButton className="p-0">
                      <img src={PenIcon} alt="pen" width={24} height={24} />
                    </IconButton>
                  </>
                )}
              </div>

              {groundsExpanded === i && (
                <div className="fs-med-slg-medium  p-0 m-0">
                  Grounds / Reasoning
                </div>
              )}
              {!isAdmin ? (
                <IconButton
                  className="p-0"
                  onClick={() => handleDeletereasoning(i)}
                >
                  <Box
                    className={`${groundsExpanded === i
                      ? "bg-light-red "
                      : " bg-light-blue1"
                      } p-3 rounded-circle d-flex justify-content-center align-items-center`}
                    width={40}
                    height={40}
                  >
                    <DeleteOutlinedIcon fontSize="large" className={`${groundsExpanded === i ? "text-danger " : null}`} />

                  </Box>
                </IconButton>
              ) : (
                <Box></Box>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex h-100 flex-column">
              <ReasoningRow index={i} grounds={grounds} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Reasoning;
