import { SCRUTINY_REVIEW } from "../../constants/case-constants";
import { CaseResponseDetailsType } from "../../redux/slice/components/initCaseResponseSlice";

export const checkForChangesResponse = (
  caseResponseDetails: CaseResponseDetailsType,
  caseStatus:string,
) => {
  const { previousSynopsis, synopsis, statementOfObjection, previousStatementOfObjection } = caseResponseDetails;

  let hasResponseChanges = false;
  let updatedSynopsis = synopsis ;
  let updatedResponseScrutinyCount = {};

  let updatedStatementOfObjection: any = statementOfObjection;

  const checkFunction = () => {
    if (
      synopsis &&
      previousSynopsis &&
      synopsis.length === previousSynopsis.length
    ) {
      let evidencesCount = 0;

      for (let i = 0; i < synopsis.length; i++) {
        for (let j = 0; j < synopsis[i].evidences.length; j++) {
          if (synopsis[i]?.evidences[j]?.reUpload) {
            evidencesCount++;
          }
        }
      }

      let count = 0;
      updatedSynopsis = synopsis.map((data, index) => {
        if (data.eventDetail !== previousSynopsis[index]?.eventDetail
          || data?.dateType !== previousSynopsis[index]?.dateType
          || data?.dates?.length !== previousSynopsis[index]?.dates?.length
          || (data?.dates && previousSynopsis[index]?.dates && data?.dates.some((date, i) => date !== previousSynopsis[index].dates[i]))
      ) {
        hasResponseChanges = true;
        count++;
        return { ...data, hasChanges: true };
      } else {
        return data;
      }
      
      });

      updatedResponseScrutinyCount = {
        ...caseResponseDetails.responseScrutinyCount,
        synopsis: count,
        evidence: evidencesCount,
      };

    }

    if(previousStatementOfObjection?.statement !== statementOfObjection?.statement){
      updatedStatementOfObjection = {
        ...updatedStatementOfObjection,
        hasChanges: true
      }
      updatedResponseScrutinyCount = {
        ...updatedResponseScrutinyCount,
        statementOfObjection: 1,
      };
    }
  };

  checkFunction();

  if(caseStatus!==SCRUTINY_REVIEW){
    updatedResponseScrutinyCount={}
  }

  const tempData = {
    ...caseResponseDetails,
    synopsis: updatedSynopsis,
    statementOfObjection: updatedStatementOfObjection,
    responseScrutinyCount: updatedResponseScrutinyCount,
    hasResponseChanges,
  };

  return tempData;
};
