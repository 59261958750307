import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useRef } from "react";
import { AppDispatch, RootState, store } from "../../../../redux/store";
import { useDispatch } from "react-redux";
import {
  setResponse,
  setStatementOfObjection,
} from "../../../../redux/slice/caseResponseSlice";
import { useParams } from "react-router-dom";
import { caseDetailThunk } from "../../../../redux/slice/caseSlice";
import { useSelector } from "react-redux";

const StatementOfObjectionRow = () => {
  const editorRef = useRef<any>(null);

  const dispatch: AppDispatch = useDispatch();
  const caseFileResponseDetail = useSelector((state: RootState) => state.caseResponse.caseFileResponseDetail);

  const handleChangeInput = () => {
    if (editorRef.current) {
      dispatch(setStatementOfObjection(editorRef.current.getContent()));
    }
  };

  return (
    <>
      <div className="d-flex  flex-column  w-100 overflow-auto">
        <div className="d-flex  gap-4 w-100">
          <div className="p-5  rounded-3 h-100 w-100 bg-white ">
            <div className="fs-med-slg-medium  text-center p-0 m-0 mb-3">
              Statement Of Defence
            </div>

            <Editor
              onInit={(evt, editor) => (editorRef.current = editor)}
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              onEditorChange={() => {
                handleChangeInput();
              }}
              value={caseFileResponseDetail.statementOfObjection?.statement}
              init={{
                height: 400,

                branding: false,
                menubar: false,

                plugins:
                  "advlist autolink lists link image charmap print preview anchor " +
                  "searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount",

                toolbar:
                  "undo redo | formatselect | bold italic underline  | " +
                  "bullist numlist outdent indent | " +
                  "fullscreen",

                content_style:
                  "body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }",
              }}
            />
            <span className="text-red">{caseFileResponseDetail.statementOfObjectionError}</span>
          </div>
        </div>
      </div>
    </>
  );
};
export default StatementOfObjectionRow;
