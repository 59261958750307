import React, { useState, useEffect } from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useNavigate, useLocation } from "react-router-dom";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import BalanceOutlinedIcon from "@mui/icons-material/BalanceOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { ListItem } from "@mui/material";
import CustomAppBar from "./AppBar";
import { useUserContext } from "../../context/UserContext";

import logoIcon from "../../assets/icon/logo_icon.png";
import logo from "../../assets/logo.png";
import { CASE_LIST, DASHBOARD, NOTIFICATION_PAGE, ORG_LIST, SUPPORT_PAGE, USER_LIST } from "../../routes/routes";
import { LSK_SELECTED_MENU } from "../../constants/local-storage-constants";
import SecureStorage from "../../util/SecureStorage";
import GroupIcon from '@mui/icons-material/Group';
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../constants/nav-constants";
import BusinessIcon from '@mui/icons-material/Business';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';


export const menuList = [
  {
    name: "Dashboard",
    key: "dashboard",
    path: DASHBOARD,
  },
  {
    name: "Cases",
    key: "file-case",
    path: CASE_LIST,
  },
  {
    name: "Notification",
    key: "notification",
    path: NOTIFICATION_PAGE,
  },
  {
    name: "Users",
    key: "users",
    path: USER_LIST,
  },
  {
    name: "Organization",
    key: "organization",
    path: ORG_LIST,
  },
];

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: "100%",
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),

  '@media (min-width: 768px)': {
    width: drawerWidth,
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: 0,

  '@media (min-width: 768px)': {
    width: "75px",
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBarLayout({
  // children,
  open,
  toggleSidebar,
}: {
  // children: React.ReactNode;
  open: boolean;
  toggleSidebar: any;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMenuItem, setSelectedMenuItem] = useState("");

  const { logout } = useUserContext();

  const setSelectedItem = (menuItem: any) => {

    SecureStorage.setItem(LSK_SELECTED_MENU, menuItem.key);
    setSelectedMenuItem(menuItem.key);
  };

  useEffect(() => {
    // const selectedMenu: any = SecureStorage.getItem(LSK_SELECTED_MENU);
    // if (selectedMenu) {
    //   setSelectedMenuItem(selectedMenu);
    //   return;
    // }
    // SecureStorage.setItem(LSK_SELECTED_MENU, menuList[0].key);
    // setSelectedMenuItem(menuList[0].key);
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const { profileDetail } = useSelector(
    (state: RootState) => state.profileDetail
  );

  return (
    <Drawer variant="permanent" open={open}>

      <div className="d-flex gap-3 flex-column vh-100">
        <div className="d-flex justify-content-center py-3">
          {open ? (
            <img
              src="/rad-logo-v2.png"
              onClick={() => {
                navigate("/");
              }}
              alt="logo"
              // width={104.79}
             height={46.16}
            />
          ) : (
            <img
              src="/rad-icon-v2.png"
              alt="logo"
              width={46} height={46}
              onClick={() => {
                navigate("/");
              }}
            />
          )}
        </div>

        <div className="d-flex justify-content-start flex-grow-1">
          <List sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
            {menuList.map((menuItem, index) => (
              <ListItem
                key={index}
                sx={{ display: "block" }}
                className={`sidebar-item p-0 ${(['users', 'organization'].includes(menuItem.key) && ![ROLE_SUPER_ADMIN].includes(profileDetail?.personalDetail?.roleRid)) ? 'd-none' : ''}`}
              >
                <Link
                  to={menuItem.path}
                  className={`${location.pathname === menuItem.path ? "menu-active" : "text-dark"
                    } text-decoration-none`}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: "center",
                      px: 2.5,
                      py: 2.5,
                    }}
                    onClick={() => setSelectedItem(menuItem)}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                      className={`${location.pathname === menuItem.path
                        ? "menu-active"
                        : "menu-inactive"
                        }`}
                    >
                      {menuItem.key === "dashboard" ? (
                        <DashboardOutlinedIcon />
                      ) : menuItem.key === "file-case" ? (
                        <BalanceOutlinedIcon />
                      ) : menuItem.key === "notification" ? (
                        <MarkunreadOutlinedIcon />
                      ) : menuItem.key === "users" ? (
                        <GroupIcon />
                      ) : menuItem.key === "organization" ? (
                        <BusinessIcon />
                      ) : ''}
                    </ListItemIcon>
                    <ListItemText
                      primary={menuItem.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </ListItem>
            ))}

            {/* <ListItem sx={{ marginTop: "auto" }} className="p-0">
              <div className="text-center vw-100">
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                    py: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <HelpOutlineIcon className="text-primary" />
                  </ListItemIcon>
                  <ListItemText
                    className="fs-normal-light text-primary"
                    primary={"Support"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
                {open && (
                  <>
                    <div className="fs-sm-light">Copyright © 2023</div>
                    <div className="fs-sm-light">all rights reserved</div>
                  </>
                )}
              </div>
            </ListItem> */}

            <ListItem
              sx={{ display: "block", marginTop: "auto" }}
              className={`sidebar-item p-0`}
            >
              <Link
                to={SUPPORT_PAGE}
                className={`${location.pathname === SUPPORT_PAGE ? "menu-active" : "text-dark"
                  } text-decoration-none`}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: "center",
                    px: 2.5,
                    py: 2.5,
                  }}
                  onClick={() => setSelectedItem({key: SUPPORT_PAGE})}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                    className={`${location.pathname === SUPPORT_PAGE
                      ? "menu-active"
                      : "menu-inactive"
                      }`}
                  >
                    <HelpOutlineIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={'Support'}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>

              {open && (
                <div className="text-center py-2">
                  <div className="fs-sm-light">Copyright © 2023</div>
                  <div className="fs-sm-light">all rights reserved</div>
                </div>
              )}
            </ListItem>
          </List>
        </div>
      </div>


    </Drawer>
  );
}
