import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Evidence } from "../../model/caseDocument/synopsis";
import { CounterPrayerModel } from "../../model/caseDocument/prayer";

import NetworkService from "../../network/NetworkService";

import { initResponse } from "./components/initCaseResponseSlice";
import {
  RESPONDENT_PRAYER,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  RESPONDENT_SYNOPSIS,
} from "../../constants/case-state";
import {
  convertPermissionString,
  formatDateTime,
} from "../../util/stringUtils";
import { removeResponseData } from "./components/removeData";
import { checkForChangesResponse } from "../../util/case/checkForChangesResponse";
import { DateOption } from "./components/initCaseSliceState";
import { SynopsisType } from "./components/initCaseResponseSlice";
import FileService from "../../network/FileService";

const initState = initResponse;
export const caseResponseThunk = createAsyncThunk(
  "/case-response",
  async (body: any, thunkApi) => {
    return await NetworkService.getCaseResponse(body);
  }
);
export const saveCaseFileResponseThunk = createAsyncThunk(
  "/save-case-response",
  //TODO : use type for body
  async (body: any, thunkApi) => {
    const dataToSend = removeResponseData(body);
    await NetworkService.saveCaseFileResponse(dataToSend);
  }
);

export const reUploadResponseAgreementThunk = createAsyncThunk(
  "/re-upload-ResponseAgreement",
  //TODO : use type for body
  async (body: any, thunkApi) => {
    const { reqBody, uniqueId } = body;
    await FileService.reUploadAgreement(reqBody, uniqueId);
  }
);

export const caseResponseSlice = createSlice({
  name: "caseResponse",
  initialState: { ...initState },
  reducers: {
    reSetResponseState: (state, { payload }) => {
      return { ...initState, ...payload };
    },
    setResponse: (state, { payload }) => {
      return { ...state, ...payload };
    },
    setCaseFileResponse: (state, { payload }) => {
      const tempData = {
        ...state,
        caseFileResponse: {
          ...state.caseFileResponse,
          ...payload,
        },
      };

      return { ...tempData };
    },
    updateResponseCaseAgreementList: (state, { payload }) => {
      const tempState = {
        ...state,
        caseFileResponse: {
          ...state.caseFileResponse,
          caseAgreementList: payload,
        },
      };

      return {
        ...tempState,
      };
    },
    removeResponseCaseAgreementDoc: (state, { payload }) => {
      const agreementType = payload;
      let agreementList = [] as any;

      if (state.caseFileResponse?.caseAgreementList.length) {
        agreementList = state?.caseFileResponse?.caseAgreementList?.filter(
          (agreement) => agreement.agreementType !== agreementType
        );
      } else {
        agreementList = null;
      }
      return {
        ...state,
        caseFileResponse: {
          ...state.caseFileResponse,
          caseAgreementList: agreementList,
        },
      };
    },

    onClickTab: (state, action) => {
      const section = action.payload;
      const tempState = { ...state, currentSection: section };
      return { ...tempState };
    },
    goToNextSection: (state) => {
      const tempState = {
        ...state,
        currentSection: (() => {
          if (
            state.currentSection < RESPONDENT_PRAYER &&
            state.currentSection >= RESPONDENT_SYNOPSIS
          ) {
            return state.currentSection + 1;
          }
          return state.currentSection;
        })(),
      };

      return { ...tempState };
    },
    goToPrevSection: (state) => {
      if (state.currentSection > 0) {
        const tempState = {
          ...state,
          currentSection: (() => {
            if (state.currentSection > RESPONDENT_SYNOPSIS) {
              return state.currentSection - 1;
            }
            return state.currentSection;
          })(),
        };
        return { ...tempState };
      }
    },

    setStatementOfObjection: (state, action) => {
      const data = action.payload;

      const tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          statementOfObjection: {
            ...state.caseFileResponseDetail.statementOfObjection,
            statement: data,
          },
          statementOfObjectionError: "",
        },
      };

      return { ...tempData };
    },

    editResponseSynopsis: (state, action) => {
      const { index, key, value } = action.payload;

      let tempData = state;
      tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: state.caseFileResponseDetail.synopsis.map((item, idx) =>
            idx === index ? { ...item, [key]: value } : item
          ),
        },
      };

      return { ...tempData };
    },
    editResponseSynopsisEvidence: (state, action) => {
      const { synopsysIndex, evidenceIndex, key, value } = action.payload;

      state.caseFileResponseDetail.synopsis[synopsysIndex].evidences[
        evidenceIndex
      ] = {
        ...state.caseFileResponseDetail.synopsis[synopsysIndex].evidences[
          evidenceIndex
        ],
        [key]: value,
        error: "",
      };
    },
    addSynopsisEvidence: (state, action) => {
      const { index, ...data } = action.payload;
      const evidence: Evidence = data;

      const updatedSynopsis = state.caseFileResponseDetail.synopsis.map(
        (item, i) => {
          if (i === index) {
            return {
              ...item,
              evidences: [...item?.evidences, evidence],
            };
          }
          return item;
        }
      );

      return {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: updatedSynopsis,
        },
      };
    },
    reUploadSynopsisEvidence: (state, action) => {
      const {
        synopsisIndex,
        evidenceIndex,
        tempReUpload,
        removeFile,
        ...data
      } = action.payload;
      const evidence = data; // Corrected the assignment of evidence

      let updatedSynopsis = [];
      // Deleted evidence name is added to the deleted list
      let deletedFileName =
        state.caseFileResponseDetail.synopsis[synopsisIndex].evidences[
          evidenceIndex
        ].uploadedFileName;

      updatedSynopsis = state.caseFileResponseDetail.synopsis.map((item, i) => {
        if (i === synopsisIndex) {
          const updatedEvidences = item.evidences.map((val, index) => {
            if (index === evidenceIndex) {
              return {
                ...val,
                tempReUpload: tempReUpload,
                ...evidence,
              };
            }
            return val;
          });

          return {
            ...item,
            evidences: updatedEvidences,
          };
        }
        return item;
      });

      let updatedState = state;

      updatedState = {
        ...state,

        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: updatedSynopsis,
          responseScrutinyCount: {
            ...state.caseFileResponseDetail.responseScrutinyCount,
            evidence:
              removeFile === false
                ? state.caseFileResponseDetail.responseScrutinyCount
                    .evidence !== null
                  ? state.caseFileResponseDetail.responseScrutinyCount
                      .evidence - 1
                  : state.caseFileResponseDetail.responseScrutinyCount.evidence
                : state.caseFileResponseDetail.responseScrutinyCount
                    .evidence !== null
                ? state.caseFileResponseDetail.responseScrutinyCount.evidence +
                  1
                : state.caseFileResponseDetail.responseScrutinyCount.evidence,
          },
        },

        deletedFiles: [...state.deletedFiles, deletedFileName],
      };

      return {
        ...updatedState,
      };
    },
    updateSynopsis: (state, action) => {
      const { index, key } = action.payload;
      state.caseFileResponseDetail.synopsis[index] = {
        ...state.caseFileResponseDetail.synopsis[index],
        errors: {
          ...state.caseFileResponseDetail.synopsis[index].errors,
          [key]: "",
        },
      };
    },

    addEvidenceDocName: (state) => {
      let currentId = 1;
      return {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: state.caseFileResponseDetail?.synopsis?.map((synopsis) => {
            const updatedEvidences = synopsis?.evidences?.map((doc) => {
              const updatedDoc = { ...doc, docName: `Doc ${currentId}` };
              currentId++;
              return updatedDoc;
            });

            return { ...synopsis, evidences: updatedEvidences };
          }),
        },
      };
    },
    setSynopsisExpanded: (state, action) => {
      state.synopsisExpanded = action.payload;
    },

    setPrayers: (state, action) => {
      const prayers = action.payload;
      let tempData = state;
      tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          prayer: {
            ...state.caseFileResponseDetail.prayer,
            prayers: [...prayers],
          },
        },
      };

      return { ...tempData };
    },

    addPrayer: (state) => {
      const counterPrayer = {
        prayerDetail: "",
        intensity: 0,
        objection: "",
        status: 0,
        percentage: 0,
      } as any;

      const tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          prayer: {
            ...state.caseFileResponseDetail.prayer,

            counterPrayer: [
              ...state.caseFileResponseDetail.prayer.counterPrayer,
              counterPrayer,
            ],
          },
        },

        counterPrayerExpanded:
          state.caseFileResponseDetail.prayer.counterPrayer.length,
      };

      return { ...tempData };
    },
    addSynopsis: (state) => {
      let tempData = state;
      const tempSynopsis = {
        dates: [],
        dateType: DateOption.SINGLE_DATE,
        eventDetail: "",
        evidences: [] as Evidence[],
        rejected: null,
        reason: "",
      } as any;

      tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: [
            ...(state?.caseFileResponseDetail?.synopsis || []),
            { ...tempSynopsis },
          ],
        },
        synopsisExpanded: state.caseFileResponseDetail?.synopsis?.length || 0,
      };

      return { ...tempData };
    },

    deleteSynopsis: (state, action) => {
      let tempData = state;

      tempData = {
        ...state,
        caseFileResponseDetail: {
          ...state.caseFileResponseDetail,
          synopsis: [
            ...state.caseFileResponseDetail.synopsis?.filter(
              (_, index) => index !== action.payload
            ),
          ],
        },
      };

      return { ...tempData };
    },

    deleteSynopsisEvidence: (state, action) => {
      const { index, i } = action.payload;

      const deletedFileName =
        state.caseFileResponseDetail.synopsis[index].evidences[i]
          .uploadedFileName;
      if (deletedFileName) {
        state.caseFileResponseDetail.synopsis[index].evidences.splice(i, 1);
      }

      state.deletedFiles.push(deletedFileName);
    },

    deletePrayers: (state, action) => {
      state.caseFileResponseDetail.prayer.counterPrayer?.splice(
        action.payload,
        1
      );
    },

    editPrayers: (state, action) => {
      const { index, key, value } = action.payload;

      if (state.caseFileResponseDetail.prayer.prayers)
        state.caseFileResponseDetail.prayer.prayers[index] = {
          ...state.caseFileResponseDetail.prayer.prayers[index],
          [key]: value,
        };
    },
    editCounterPrayers: (state, action) => {
      const { index, key, value } = action.payload;
      if (state.caseFileResponseDetail.prayer.counterPrayer)
        state.caseFileResponseDetail.prayer.counterPrayer[index] = {
          ...state.caseFileResponseDetail.prayer.counterPrayer[index],
          [key]: value,
        };
    },

    setPrayersExpanded: (state, action) => {
      state.prayerExpanded = action.payload;
    },
    setCounterPrayersExpanded: (state, action) => {
      state.counterPrayerExpanded = action.payload;
    },
    setResponseCurrentSection: (state, { payload }) => {
      state.currentSection = payload;
    },

    setResponseScrutinyCount: (state, action) => {
      state.caseFileResponseDetail.responseScrutinyCount = action.payload;
    },

    editResponseStatementOfObj: (state, action) => {
      const { key, value } = action.payload;

      state.caseFileResponseDetail = {
        ...state.caseFileResponseDetail,
        statementOfObjection: {
          ...state.caseFileResponseDetail.statementOfObjection,
          [key]: value,
        },
      };
    },

    validateResponse: (state) => {
      switch (state.currentSection) {
        case RESPONDENT_SYNOPSIS:
          validateSynopsis();
          break;
        case RESPONDENT_STATEMENT_OF_OBJECTION:
          validateStatementOfObjection();
          break;
        case RESPONDENT_PRAYER:
          validatePrayer();
          break;
        default:
          break;
      }

      function checkDates(dates: any, dateType: any) {
        const currentDate = new Date().toISOString().split("T")[0];
        const mindate = formatDateTime("01-01-1900");

        if (
          dates[0] > currentDate ||
          dates[1] > currentDate ||
          dates[0] < mindate ||
          dates[1] < mindate
        ) {
          if (dateType === DateOption.ONLY_YEAR) {
            return "Year is Invalid";
          } else {
            return "Date is Invalid";
          }
        }
        if (dateType === DateOption.SINGLE_DATE) {
          if (!dates.length || dates[0] === "") {
            return "Date is required!";
          }
        }
        if (dateType === DateOption.MULTIPLE_DATES) {
          if (!dates.length) {
            return "Date is required!";
          }
        }
        if (dateType === DateOption.RANGE_OF_DATES) {
          if (!(dates.length === 2) || dates[0] === "" || dates[1] === "") {
            return "Date is required!";
          } else if (dates[0] > dates[1]) {
            return "From Date Can't be later than To Date.";
          } else if (dates[0] === dates[1]) {
            return "From Date and To Date can't be same.";
          }
        }
        if (dateType === DateOption.ONLY_YEAR) {
          if (!dates.length || dates[0] === "") {
            return "Year is required!";
          } else if (dates[0] !== "") {
            if (
              dates[0].length < 4 ||
              dates[0] > currentDate ||
              dates[0] < "1947"
            ) {
              return "Year is Invalid";
            }
          }
        }
      }
      function validateSynopsis() {
        // Check if the synopsis array is empty
        if (
          !state.caseFileResponseDetail.synopsis ||
          state.caseFileResponseDetail.synopsis.length === 0
        ) {
          state.isValid = false;
          state.errorMessage = "Synopsis is Required!";
          return;
        }

        state.isValid = state.caseFileResponseDetail.synopsis.every(
          (synopsis, index) => {
            // Initialize the errors object for each synopsis
            synopsis.errors = {
              dates: checkDates(synopsis.dates, synopsis.dateType) ?? "",
              dateType: synopsis.dateType ? "" : "Please select date option",
              eventDetail: synopsis.eventDetail ? "" : "Event is required!",
              evidences: synopsis.evidences.length
                ? ""
                : "Evidences is required!",
            };

            synopsis.evidences?.map((evidence) => {
              evidence.error = evidence.description
                ? ""
                : "Description is required !";
            });

            // Set the current synopsis index to expand
            state.synopsisExpanded = index;

            // Check if all error messages are empty
            const isValidEvidence = Object.values(synopsis.evidences).every(
              (evidence) => evidence.error === ""
            );
            const isValidSynopsis = Object.values(synopsis.errors).every(
              (error) => error === ""
            );

            return isValidEvidence && isValidSynopsis;
          }
        );

        // Set the error message if validation failed for any synopsis
        state.errorMessage = state.isValid
          ? ""
          : "Please fill in the necessary details before proceeding";
      }

      function validateStatementOfObjection() {
        state.isValid =
          !!state.caseFileResponseDetail?.statementOfObjection?.statement
            ?.length;
        state.errorMessage = state.isValid
          ? ""
          : "Statement of Defence cannot be empty";
      }

      function validatePrayer() {
        state.isValid = state.caseFileResponseDetail?.prayer?.prayers.every(
          (prayer) => {
            if (!prayer.status || prayer.status === 0) {
              state.errorMessage =
                "Please respond to Prayer before proceeding!";
              return false;
            } else {
              state.errorMessage = "";
              return true;
            }
          }
        );
      }
    },
  },

  extraReducers(builder) {
    builder

      //case response
      .addCase(caseResponseThunk.pending, (state) => {
        return { ...state, isResponseLoading: true };
      })
      .addCase(caseResponseThunk.fulfilled, (state, action) => {
        const data = action.payload as any;
        const { permissionState } = data;
        const permissionResponse = convertPermissionString(permissionState);

        const tempCaseResponseDetails = checkForChangesResponse(
          data?.caseFileResponseDetail,
          data?.caseFileResponse?.status ?? ""
        );

        const temp = {
          ...data,

          respondentlegalHandover: data.legalHandover,
          permissionState: permissionResponse,
          // finalState: state.finalState,
          synopsisExpanded: 0,
          caseFileResponseDetail: tempCaseResponseDetails,
          currentSection: state.currentSection,
          isResponseLoading: false,
          errorMessage: "",
          prayerExpanded: 0,
          counterPrayerExpanded: 0,
        };

        return temp;
      })
      .addCase(caseResponseThunk.rejected, (state, action) => {
        state = {
          ...state,
          // finalState: state.finalState,
          currentSection: state.currentSection,
          isResponseLoading: false,
          // sending: false,
          synopsisExpanded: 0,
          hasResponseDateInSlice: true,
          errorMessage: action.error.message ?? "",
        };
        return state;
      })

      //save response
      .addCase(saveCaseFileResponseThunk.pending, (state) => {
        return { ...state, isResponseLoading: true };
      })
      .addCase(saveCaseFileResponseThunk.fulfilled, (state, action) => {
        return { ...state };
      })
      .addCase(saveCaseFileResponseThunk.rejected, (state, action) => {
        state = {
          ...state,
          // finalState: state.finalState,
          currentSection: state.currentSection,
          isResponseLoading: false,
          // sending: false,
          errorMessage: action.error.message ?? "",
        };
        return state;
      });
  },
});

export const {
  reSetResponseState,
  setResponse,
  setCaseFileResponse,
  setSynopsisExpanded,
  editResponseSynopsis,
  editResponseSynopsisEvidence,
  reUploadSynopsisEvidence,
  updateSynopsis,

  addSynopsisEvidence,
  addEvidenceDocName,
  addSynopsis,

  deleteSynopsis,
  deleteSynopsisEvidence,

  setPrayers,
  addPrayer,

  deletePrayers,
  editPrayers,

  editCounterPrayers,
  setStatementOfObjection,
  validateResponse,

  goToNextSection,
  goToPrevSection,
  onClickTab,

  setResponseCurrentSection,

  setResponseScrutinyCount,

  setPrayersExpanded,
  setCounterPrayersExpanded,

  editResponseStatementOfObj,

  updateResponseCaseAgreementList,
  removeResponseCaseAgreementDoc,
} = caseResponseSlice.actions;
