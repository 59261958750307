import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  SwipeableDrawer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import profilePic from "../assets/profile.png";
import closeIcon from "../assets/icon/navigation/close_24px.png";
import phoneIcon from "../assets/icon/navigation/phone_in_talk_24px.png";
import changePassIcon from "../assets/icon/List item/lock_open_24px.png";
import logOutIcon from "../assets/icon/List item/exit_to_app_24px.png";

import { useUserContext } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { DASHBOARD, PROFILE } from "../routes/routes";
import { AppDispatch, RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import SecureStorage from "../util/SecureStorage";
import { LSK_USER_DETAIL } from "../constants/local-storage-constants";
import {
  profileDetailThunk,
  resetUserDetail,
  setProfileDetail,
  setSelectedTab,
  setShowChangePasswordMenu,
  setShowProfileDetailsMenu,
} from "../redux/slice/userSlice";
import {
  CHANGE_PASSWORD,
  INDIVIDUAL,
  OTHER_ORG,
  PART_OF_ORG,
  PROFILE_PAGE,
} from "../constants/nav-constants";
import PersonIcon from "@mui/icons-material/Person";

import UserService from "../network/UserService";

import BusinessIcon from '@mui/icons-material/Business';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LogoutIcon from '@mui/icons-material/Logout';

const ProfileSideBar = ({
  openProfile,
  toggleProfileSideBar,
}: {
  openProfile: boolean;
  toggleProfileSideBar: () => void;
}) => {
  const { logout } = useUserContext();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { profileDetail } = useSelector(
    (state: RootState) => state.profileDetail
  );

  const goToProfile = (navType: any = null) => {
    if (navType !== null) {
      if (navType === PROFILE_PAGE) {
        dispatch(setShowProfileDetailsMenu(true));
      }
      if (navType === CHANGE_PASSWORD) {
        dispatch(setShowChangePasswordMenu(true));
      }
    }
    navigate(`${PROFILE}`);
    dispatch(setSelectedTab(0));
    toggleProfileSideBar();
  };

  useEffect(() => {
    let userData: any = SecureStorage.getItem(LSK_USER_DETAIL);
    if (userData) {
      dispatch(
        setProfileDetail({
          personalDetail: {
            ...profileDetail.personalDetail,
            ...userData.personalDetail,
          },
          orgDetail: { ...profileDetail.orgDetail, ...userData.orgDetail },
        })
      );
    }
  }, []);

  const switchContext = async (data: any) => {
    setIsLoading(!isLoading);
    await UserService.switchUserContext({
      uniqueId: data.uniqueId,
    });
    dispatch(profileDetailThunk())
      .unwrap()
      .then((data: any) => {
        let userDetail = {
          personalDetail: {
            ...profileDetail.personalDetail,
            ...data.personalDetail,
          },
          orgDetail: { ...profileDetail.orgDetail, ...data.orgDetail },
          kycDetail: { ...profileDetail.kycDetail },
        };

        SecureStorage.setItem(LSK_USER_DETAIL, userDetail);
        dispatch(setProfileDetail(userDetail));
        setIsLoading(false);
        // toggleProfileSideBar()
        navigate(DASHBOARD)
      })
      .catch((error: any) => {});
  };

  return (
    <div>
      <SwipeableDrawer
        anchor="right"
        open={openProfile}
        onClose={toggleProfileSideBar}
        onOpen={toggleProfileSideBar}
        PaperProps={{ style: { borderRadius: "5px 0 0 5px" } }}
      >
        <div className="p-3">
          <div className="w-100 d-flex justify-content-between align-items-center mx-2">
            <div className="fs-med-lg-semibold">Profile</div>
            <IconButton className="p-3 " onClick={toggleProfileSideBar}>
              <img src={closeIcon} alt="close icon" />
            </IconButton>
          </div>

          <Box
            className="d-flex justify-content-center  flex-column  "
            sx={{ width: 320 }}
          >
            {profileDetail ? (
              <div className="text-center w-100 ">
                <img
                  className="profile-picture"
                  src={
                    profileDetail?.personalDetail?.profilePic
                    ? profileDetail?.personalDetail?.profilePictureUrl
                    : '/user-placeholder.png'
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = '/user-placeholder.png';
                  }}
                  alt="..."
                />
                <div className="fs-sm-lg-semibold mt-3">
                  {profileDetail?.personalDetail?.fullName}
                </div>
                <div className="fs-sm-lg-light gap-3 ">
                  <img src={phoneIcon} alt="phone icon" />
                  <span className="text-light-dark px-2 fs-normal-light">
                    {profileDetail?.personalDetail?.mobileNo}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="w-100 text-center">
              <Button
                className="fs-normal-med  my-3"
                variant="contained"
                sx={{ textTransform: "none" }}
                onClick={() => {
                  goToProfile(PROFILE_PAGE);
                }}
              >
                View Profile
              </Button>
            </div>
            {/* other context switch */}
            
           {profileDetail?.personalDetail?.contextList.length>1&&(
            <>
             <Divider
              variant="fullWidth"
              sx={{ backgroundColor: "#E5E5FF", border: "1px 1px 1px 1px" }}
            />
            <span className="text-grey">Other Contexts</span>

            {isLoading ? (
              <>
                {" "}
                <Skeleton
                  className="mt-3 w-100"
                  variant="rounded"
                  height={40}
                />
                <Skeleton
                  className="mt-3 w-100"
                  variant="rounded"
                  height={40}
                />
              </>
            ) : (
              <>
                {profileDetail?.personalDetail?.contextList?.map(
                  (data, index) => (
                    <Box
                      className="d-flex gap-3 m-2 cursor-pointer mb-4  "
                      onClick={() => {
                        switchContext(data);
                      }}
                      key={index}
                    >
                      <Box
                        sx={{
                          backgroundColor:
                            data.contextType ===
                            profileDetail?.personalDetail?.currentContextType
                              ? "#D7B24D"
                              : "transparent",
                        }}
                        width={"4px"}
                        height={"30px"}
                      ></Box>
                      <div>
                        {/* {profileDetail?.personalDetail. ? (
                 
                 <img
                   className="rounded-circle"
                   src={imgUrl}
                   alt="profile"
                   width="36.4892578125px"
                   height="36.1602783203125px"
                 />
               ) : ( */}
                        <Box
                          className="d-flex justify-content-center align-items-center rounded-circle bg-light-blue1 text-primary fw-bold"
                          width="36.4892578125px"
                          height="36.1602783203125px"
                        >
                          {data?.contextType === PART_OF_ORG ? (
                            // <img src={profilePic} alt="profilePic" />
                            <BusinessIcon className="p-1" fontSize="large" />
                          ) : (
                            <PersonIcon className="p-1" fontSize="large" />
                          )}
                        </Box>
                        {/* )}  */}
                      </div>
                      <Box className="me-3 gap-5 d-flex align-items-center">
                        <div className="fs-sm-med">
                          {data.contextType === INDIVIDUAL
                            ? profileDetail.personalDetail.fullName
                            : data.orgName?data.orgName:"Organization"}

                        </div>
                      </Box>
                    </Box>
                  )
                )}
              </>
            )}
</>
           )}
            {/* ---------------------------------------------------------------- */}
            <Divider
              variant="fullWidth"
              sx={{ backgroundColor: "#E5E5FF", border: "1px 1px 1px 1px" }}
            />

            <Button
              className="d-flex gap-3 justify-content-start align-items-center p-3"
              onClick={() => {
                goToProfile(CHANGE_PASSWORD);
              }}
              sx={{ color: '#000', textTransform: "none" }}>
                <LockOpenIcon sx={{fontSize: '22px'}} />
                <span className="fs-sm-lg-light">Change Password</span>
            </Button>

            <Button
              className="d-flex gap-3 justify-content-start align-items-center p-3"
              onClick={() => {
                dispatch(resetUserDetail());
                logout();
              }}
              sx={{ color: '#000', textTransform: "none" }}>
                <LogoutIcon sx={{fontSize: '22px'}} />
                <span className="fs-sm-lg-light">Logout</span>
            </Button>
          </Box>
        </div>
      </SwipeableDrawer>
    </div>
  );
};

export default ProfileSideBar;
