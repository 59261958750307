import { CT_ORGANIZATION } from "../../constants/case-constants";
import { Claimant } from "../../model/fileCase";
import { setClaimantExpanded } from "../../redux/slice/caseSlice";
import { validateEmail, validatePhoneNumber } from "../validation";

export const validateClaimantList = (claimantList: Claimant[]) => {
  let isValid = true;
  let errorMessageList = [] as string[];
  const tempList: Claimant[] = claimantList.map((claimant, index) => {
    const error: any = {};
     

    if (!claimant.name) {
      error.name = "Name is required";
         errorMessageList.push("Name is required");
      isValid = false;
    }
    const isRepeatedMobileNo = claimantList.some(
      (c, i) => i !== index && c.mobileNo === claimant.mobileNo
    );
    const isRepeatedEmail = claimantList.some(
      (c, i) => i !== index && c.email === claimant.email
    );

    if (isRepeatedMobileNo) {
      errorMessageList.push(
        "Claimant with the same Mobile Number already exists"
      );
      isValid = false;
    }
    if (isRepeatedEmail) {
      errorMessageList.push("Claimant with the same email already exists");
      isValid = false;
    }

    if (!claimant.mobileNo) {
      error.mobileNo = "Phone number is required";
         errorMessageList.push("Phone number is required");

      isValid = false;
    } else {
      if (validatePhoneNumber(claimant.mobileNo)) {
        error.mobileNo = validatePhoneNumber(claimant.mobileNo);
        if (error.mobileNo) {
          isValid = false;
        }
      }
    }

    if (!claimant.email) {
      error.email = "Email is required";
      errorMessageList.push("Email is required");
      isValid = false;
    } else {
      if (validateEmail(claimant.email)) {
        let err = validateEmail(claimant.email);
          error.email = err;
         errorMessageList.push(err);

        if (error.email) {
          isValid = false;
        }
      }
    }

    if (claimant.claimantType === CT_ORGANIZATION) {
      if (!claimant.orgRepresentative) {
        error.orgRepresentative = "Organization Representative is required";
        errorMessageList.push("Organization Representative is required");
        isValid = false;
      }
      if (!claimant.designation) {
        error.designation = "Organization Designation is required";
        errorMessageList.push("Organization Designation is required");
        isValid = false;
      }
      if (!claimant.authorizationLetter) {
        error.authorizationLetter = "Authorization Letter is required";
        errorMessageList.push("Authorization Letter is required");
        isValid = false;
      }
      if (!claimant.eAadhaar) {
        error.eAadhaar = "ID proof is required";
        errorMessageList.push("ID proof is required");
        isValid = false;
      }
    }

    if (!isValid) {
      setClaimantExpanded(index);
    }

    return {
      ...claimant,
      errors: error,
    };
  });

  return { tempList: tempList, isValid: isValid, errorMessageList };
};
