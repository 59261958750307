import React, { ChangeEvent } from "react";
import { ReasoningProps } from "../../../../model/fileCase";
import { Box, Slider, TextField } from "@mui/material";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { editGrounds, updateGrounds } from "../../../../redux/slice/caseSlice";
import { Grounds } from "../../../../model/caseDocument/grounds";

interface ReasoningRowProps {
  grounds: Grounds;

  index: number;
}
const ReasoningRow: React.FC<ReasoningRowProps> = ({
  grounds,

  index,
}) => {
  const dispatch: AppDispatch = useDispatch();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;
    dispatch(editGrounds({ index, value, key }));
    dispatch(updateGrounds({ index }));
  };
  return (
    <div className="h-100">
      <Box className="d-flex flex-column justify-content-center align-items-center gap-4 pb-4">
        <TextField
          error={!!grounds.errors}
          // className="w-50"
          sx={{ width: 600 }}
          multiline
          label="Reasoning"
          name="groundsDetail"
          value={grounds.groundsDetail ? grounds.groundsDetail : ""}
          rows={10}
          helperText={grounds.errors}
          onChange={onInputChange}

        />
      </Box>
    </div>
  );
};

export default ReasoningRow;
