import React, {    useState } from "react";

import {
  Box,

  Button,

  FormControl,

  FormHelperText,


  InputLabel,

  MenuItem,

  Select,

  SelectChangeEvent,

  TextField,
  Tooltip,
} from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CancelIcon from "@mui/icons-material/Cancel";


import {
  Evidence,
  SynopsisModel,
} from "../../../../model/caseDocument/synopsis";
import { useDispatch, useSelector } from "react-redux";
import {
  addEvidenceDocName,
  addSynopsisEvidence,
  deleteSynopsisEvidence,
  editSynopsis,
  editSynopsisEvidence,
  updateSynopsis,
  updateSynopsisEvidence,
} from "../../../../redux/slice/caseSlice";
import { AppDispatch, RootState, store } from "../../../../redux/store";

import {
  DRAFT,

  NEW_CASE,
} from "../../../../constants/case-constants";

import {  useParams } from "react-router-dom";
import {
  isEditing,
} from "../../../../util/case/roleUtils";
import {
  ROLE_LAW_FIRM_ADMIN,
  ROLE_LAW_FIRM_USER,
} from "../../../../constants/nav-constants";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useSnackbar } from "../../../../context/SnackbarContext";
import EvidenceItem from "./EvidenceItem";
import { formatDateTime } from "../../../../util/stringUtils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CaseAgreement, DateOption } from "../../../../redux/slice/components/initCaseSliceState";
import FileUploadLayout from "../../../../components/common/FileUploadLayout";
import { FileUploadApiType } from "../../../../constants/api-constants";


interface SynopsisEditBoxProps {
  synopsis: SynopsisModel;
  index: number;
}

const SynopsisEditBox: React.FC<SynopsisEditBoxProps> = ({
  synopsis,
  index,
}) => {

  const { uniqueId } = useParams()
  const { showSnackbar } = useSnackbar();

  const dispatch: AppDispatch = useDispatch();

  const { caseFile, isRespondent } = useSelector((state: RootState) => state.case);




  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;

  const isLawyer =
    roleRid === ROLE_LAW_FIRM_ADMIN || roleRid === ROLE_LAW_FIRM_USER;










const addSynopsisDoc=(file:CaseAgreement|Evidence)=>{
  dispatch(
    addSynopsisEvidence({
      index: index,
      actualFileName: file.actualFileName,
      uploadedFileName: file.uploadedFileName,
      mineType:file.mimeType,
    })
  );
  // dispatch(updateSynopsis({ index, key: "evidences" }));
  dispatch(addEvidenceDocName());

  showSnackbar("Added evidence")
}

  const handelEvidenceChange = (
    key: string,
    value: any,
    evidenceIndex: number
  ) => {
    const synopsysIndex = index;
    dispatch(
      editSynopsisEvidence({ synopsysIndex, evidenceIndex, key, value })
    );

    dispatch(updateSynopsisEvidence({ synopsysIndex, evidenceIndex, key }));

  };
  const deleteEvidence = (i: number) => {
    dispatch(deleteSynopsisEvidence({ index: index, i: i }));
    dispatch(addEvidenceDocName());
  };

  // const [selectedDate,setSelectedDate]=useState();
  const handlechange = (event: SelectChangeEvent) => {

    //added to slice
    dispatch(editSynopsis({ index, value: event.target.value, key: "dateType" }));
    //remove existing date
    dispatch(editSynopsis({ index, value: [], key: "dates" }));
    dispatch(editSynopsis({ index, value: { ...synopsis.errors, dateType: "" }, key: "errors" }));

  }
  const [selectedDate, setSelectedDate] = useState(null);

  const currentDate = new Date().toISOString().split('T')[0];

  const currentYear = new Date().getFullYear();

  const handleSelectDate = (e: any) => {
    
    if (!selectedDate) {
      showSnackbar("Please select a date.");
      return;
    }

    if (synopsis?.dates?.length >= 3) {
      showSnackbar("Maximum dates exceeded.");
      return;
    }else if(synopsis.dates.includes(selectedDate) ){
      dispatch(editSynopsis({ index, value: { ...synopsis.errors, dates: "Date is Invalid" }, key: "errors" }));
    }else{
      const updatedDates = [...synopsis.dates, selectedDate];
      dispatch(editSynopsis({ index, value: updatedDates, key: "dates" }));
      setSelectedDate(null);
    }
  };
  const onInputChange = (e: any) => {
    const { name: key, value } = e.target;
    dispatch(editSynopsis({ index, value, key }));
    dispatch(updateSynopsis({ index, key }));
  };

  const onDateChange = (e: any) => {
    dispatch(editSynopsis({ index, value: { ...synopsis.errors, dates: "" }, key: "errors" }));

    const { name: key, value } = e.target;
  

      if (synopsis.dateType === DateOption.SINGLE_DATE) {
        const date = [value];
        dispatch(editSynopsis({ index, value: date, key }));
      } else if (synopsis.dateType === DateOption.RANGE_OF_DATES) {
        let fromDate = synopsis?.dates[0] ?? "";
        let toDate = synopsis?.dates[1] ?? "";

        if (key === "fromDate") {
          fromDate = value;
        } else {
          toDate = value;
        }
        dispatch(editSynopsis({ index, value: [fromDate, toDate], key: "dates" }));
      }
      else if (synopsis.dateType === DateOption.MULTIPLE_DATES) {
        setSelectedDate(e.target.value);
      }
      else if (synopsis.dateType === DateOption.ONLY_YEAR) {
        
        if (value.length < 5) {
          const date = [value];
          dispatch(editSynopsis({ index, value: date, key }));
        }
      }
      
    
    

    dispatch(updateSynopsis({ index, key }));
  };

  
  const removeDate = (i: number) => {



    // Make a copy of the dates array
    let newDateList = [...(synopsis?.dates || [])];
    // Remove the item at the specified index
    newDateList.splice(i, 1);


    // Dispatch the action to edit the synopsis with the updated list
    dispatch(editSynopsis({ index, value: [...newDateList], key: "dates" }));
    // dispatch(updateSynopsis({ index, key:"datetime" }));

  }


  

  return (
    <div className=" h-100 ">
      <div className="d-flex gap-4 justify-content-center overflow-auto" >
        <div className="d-flex flex-column justify-content-center" >
          <div className="bg-white h-100  d-flex flex-grow-1 flex-column align-items-center justify-content-center px-2 py-2">
            <Box className="d-flex flex-column gap-2 pt-2">
              <div className="d-flex gap-1  flex-wrap align-items-center pb-2" style={{ width: 650 }} >
              <FormControl>
                  <InputLabel id="demo-simple-select-error-label">Date Option</InputLabel>
                  <Select

                    labelId="demo-simple-select-error-label"
                    id="demo-simple-select-error"
                    value={synopsis?.dateType?.toString()}
                    label="Date Option"
                    onChange={handlechange}
                    size="small"
                    sx={{ width: 200 }}
                    error={!!synopsis.errors?.dateType}

                    name="selectedOption"
                    className="p-1"
                  >
                    <MenuItem value={DateOption.SINGLE_DATE}>Date</MenuItem>
                    <MenuItem value={DateOption.MULTIPLE_DATES}>Multiple Dates</MenuItem>
                    <MenuItem value={DateOption.RANGE_OF_DATES}>Range of Dates</MenuItem>
                    <MenuItem value={DateOption.ONLY_YEAR}>Only year</MenuItem>
                    <MenuItem value={DateOption.NOT_APPLICABLE}>NA(Not Applicable)</MenuItem>
                  </Select>
                  <FormHelperText >
                    <span className="text-red">
                    {synopsis.errors?.dateType}
                      </span></FormHelperText>
                </FormControl>
                {synopsis.dateType === DateOption.SINGLE_DATE && (
                  <TextField
                    error={!!synopsis.errors?.dates}
                    type="date"
                    label="Date"
                    placeholder="Select a date"
                    variant="outlined"
                    value={synopsis?.dates?.length ? synopsis.dates[0] : null}
                    name="dates"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                      max: currentDate,
                      min: '1753-01-01',
                      sx: {
                        "& input::placeholder": {
                          color: "grey",
                        },
                        "&.Mui-disabled input::placeholder": {
                          WebkitTextFillColor: "grey",
                        },
                      
                    }
                  }}
                    helperText={synopsis.errors?.dates}
                    sx={{ width: 200 }}
                    onChange={onDateChange}
                    size="small"
                  />
                )}
                {synopsis.dateType === DateOption.MULTIPLE_DATES && (
                  <>
                    <TextField
                      error={!!synopsis.errors?.dates}
                      helperText={synopsis.errors?.dates}
                      type="date"
                      variant="outlined"
                      label="Custom Date"
                      name="dates"
                      value={selectedDate?selectedDate:'yyyy-MM-dd'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        max: currentDate,
                        min: '1753-01-01',
                        sx: {
                          '::placeholder': {
                            color: 'lightgrey',
                          },

                        }
                      }}
                      size="small"
                      sx={{ width: 200 }}
                      onChange={onDateChange}
                      InputProps={{
                        endAdornment: (
                          <div className="ms-1 cursor-pointer" onClick={handleSelectDate}>
                            <CheckCircleIcon color="primary" />
                          </div>
                        ),
                      }}
                    />
                    {synopsis.dates?.map((item: any, index: number) => (
                      <div className="d-flex align-items-center border rounded flex-wrap" key={index}>
                        <div className="d-flex gap-2 align-items-center px-1 py-1">
                          <DateRangeIcon sx={{ fontSize: "18px", color: "grey" }} />
                          <span className="fs-normal-med text-grey">{formatDateTime(item)}</span>
                        </div>
                        <div className="d-flex align-items-center">
                          <Button
                            color="error"
                            className="d-flex gap-1 align-items-center border-start"
                            onClick={() => removeDate(index)}
                          >
                            <CancelIcon sx={{ fontSize: "18px" }} />
                          </Button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
                {synopsis.dateType === DateOption.RANGE_OF_DATES && (
                  <><TextField
                    error={!!synopsis.errors?.dates}
                    type="date"
                    label="From Date"
                    variant="outlined"
                    value={synopsis?.dates?.length ? synopsis.dates[0] : null}
                    name="fromDate"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{
                     
                      max: synopsis?.dates?.[1] || currentDate,
                      min: '1753-01-01',
                      sx: {
                        '::placeholder': {
                          color: 'lightgrey',
                        },

                      }
                    }}
                    //helperText={synopsis.errors?.dates}
                    sx={{ width: 200 }}
                    onChange={onDateChange}
                    size="small"
                  />
                    <TextField
                      error={!!synopsis.errors?.dates}
                      type="date"
                      label="To Date"
                      variant="outlined"
                      value={synopsis?.dates?.length > 1 ? synopsis.dates[1] : null}
                      name="toDate"
                      InputLabelProps={{ shrink: true }}
                      inputProps={{
                        min: synopsis?.dates?.[0],
                        max: currentDate,
                        mxLength: 10,
                        sx: {
                          '::placeholder': {
                            color: 'lightgrey',
                          },

                        }
                      }}
                     // helperText={synopsis.errors?.dates}
                      sx={{ width: 200 }}
                      onChange={onDateChange}
                      size="small"
                    // inputProps={{ min: synopsis.fromDate }}
                    />
                     {DateOption?.RANGE_OF_DATES?<span className="text-red">
                    {synopsis.errors?.dates}
                  </span>:null}
                    </>

                )}
                 {synopsis.dateType === DateOption.ONLY_YEAR && (
                  <>
                    <TextField
                      error={!!synopsis.errors?.dates}
                      type="number"
                      label="Year"
                      variant="outlined"
                      value={synopsis?.dates?.length ? synopsis.dates[0] : null}

                      name="dates"
                      InputLabelProps={{ shrink: true }}
                      helperText={synopsis.errors?.dates}
                      sx={{ width: 200 }}

                      size="small"
                      inputProps={{
                        Step: 1,
                        min: 1900,
                        max: currentYear,
                        mxLength: 4,
                        sx: {
                          '::placeholder': {
                            color: 'lightgrey',
                          },

                        }
                      }}
                      onChange={onDateChange}
                    />
                  </>

                )}
                <Tooltip title={"Fill the Date of Event"}>
                  <InfoOutlinedIcon />
                </Tooltip>
              </div>
              <TextField
                error={!!synopsis.errors?.eventDetail}
                name="eventDetail"
                label="Events"
                value={synopsis.eventDetail}
                multiline
                helperText={synopsis.errors?.eventDetail}
                rows={5}
                onChange={onInputChange}
              />

      
              {(isEditing() && isRespondent) ||
                (!isEditing() && !isRespondent) ||
                isLawyer ? (
                <div className=" d-block  justify-content-center ">
                  <span>Attach additional evidences</span>
              
                    <FileUploadLayout
                      url={""}
                      fileToView={null}
                      isLoading={false}
                      addFile={addSynopsisDoc}
                      fileUploadApiType={FileUploadApiType.CASE}
                      uploadArguments={[
                        caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                        uniqueId,
                      ]}

                      errorMessage={synopsis.errors?.evidences ? synopsis.errors?.evidences : ""}
                    />
                

                  {synopsis.evidences?.length > 0 &&
                    (
                      <>
                        <div className="text-start fs-med-slg-medium text-grey mt-2">Selected Files</div>
                        {synopsis.evidences?.map((evidence, evidenceIndex) => (
                          <EvidenceItem
                            key={evidenceIndex}
                            evidence={evidence}
                            evidenceIndex={evidenceIndex}
                            handelEvidenceChange={handelEvidenceChange}
                            deleteEvidence={deleteEvidence}
                            scrollEnabled={synopsis.evidences.length === evidenceIndex + 1}
                          />
                        ))}</>
                    )}
                </div>
              ) : null}
            </Box>

          </div>
        </div>
      </div>

    </div>
  );

};

export default SynopsisEditBox;
