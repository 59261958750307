import { Evidence } from "../../../model/caseDocument/synopsis";

export const removeData = (tempBody: any) => {
  delete tempBody.isLoading;
  delete tempBody.errorMessage;
  // delete tempBody.searchQuery
  delete tempBody.valid;
  delete tempBody.claimantExpanded;
  delete tempBody.respondentExpanded;
  delete tempBody.synopsisExpanded;
  delete tempBody.groundsExpanded;
  delete tempBody.prayersExpanded;

  delete tempBody.previousDetailedFacts;
  delete tempBody.previousSynopsis;

  delete tempBody.respondentCaseFileDetail; //removed respondent caseDetails

  delete tempBody.isAdmin;
  delete tempBody.isAdvocate;
  delete tempBody.isClaimant;
  delete tempBody.isRespondent;
  delete tempBody.response;
  tempBody.permissionState = "";

  tempBody.claimantList = tempBody.claimantList.map((item: any) => {
    const tempObj = { ...item, errors: undefined };
    const temp1=JSON.stringify(tempObj.eAadhaar )
    const temp2 = JSON.stringify(tempObj.authorizationLetter);
    tempObj.eAadhaar=temp1.toString(); 
    tempObj.authorizationLetter=temp2.toString();
    delete tempObj.errors;
    
    
    return tempObj;
  });
  tempBody.respondentList = tempBody.respondentList.map((item: any) => {
    const tempObj = { ...item, errors: undefined };
    delete tempObj.errors;
    return tempObj;
  });

  tempBody.prayers = tempBody.prayers?.map((item: any) => {
    const tempObj = { ...item, errors: undefined };
    delete tempObj.errors;
    return tempObj;
  });
  tempBody.grounds = tempBody.grounds?.map((item: any) => {
    const tempObj = { ...item, errors: undefined };
    delete tempObj.errors;
    return tempObj;
  });
  tempBody.synopsis = tempBody.synopsis?.map((item: any) => {
    const tempObj = {
      ...item,
      evidence: item.evidence?.map((evi: any) => {
        const tempEv = { ...evi };
        if ("tempReUpload" in tempEv) {
          delete tempEv.tempReUpload;
        }
        return tempEv;
      }),
    };
    return tempObj;
  });

  tempBody.caseFile = { ...tempBody.caseFile, errors: undefined };

  return tempBody;
};

export const removeResponseData = (tempBody: any) => {
  delete tempBody.isLoadingRespondentData;
  delete tempBody.errorMessage;

  delete tempBody.responseScrutinyCount;
  delete tempBody.synopsisExpanded;
  delete tempBody.prayerExpanded;

  tempBody.permissionState = "";

  return tempBody;
};
