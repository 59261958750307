import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SecureStorage from "../util/SecureStorage";
import { IS_VIEW_MEDIA } from "../constants/local-storage-constants";
import FileService from "../network/FileService";
import CustomLoader from "./common/CustomLoader";
import ErrorImg from "../assets/errorPage.svg";
const enum FileOrigin {
  CASE = "case",
  USER = "user",
}

const ViewMedia = ({
  isPdf,
  url,
  fileOrigin = FileOrigin.CASE,
}: {
  isPdf: boolean;
  url: string;
  fileOrigin?: FileOrigin | string;
}) => {
  const [blobUrl, setBlobUrl] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const downloadFile = async (url: string, mimeType: string) => {
    try {
      setIsDownloading(true);
      if (url) {
        const args = url.split("/");
        const res: Blob =
          fileOrigin === FileOrigin.USER
            ? ((await FileService.downloadUserFile(args)) as Blob)
            : ((await FileService.downloadFileBlob(args)) as Blob);

        const blobUrl = window.URL.createObjectURL(
          new Blob([res], { type: mimeType })
        );

        setBlobUrl(blobUrl);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setIsDownloading(false);
    }
  };

  useEffect(() => {
    const mimeType = isPdf ? "application/pdf" : "image/jpeg";
    downloadFile(url, mimeType);
  }, []);

  // const handleGoBack = () => {
  //   SecureStorage.setItem(IS_VIEW_MEDIA, true);
  //   navigate(-1);
  // };

  return (
    <div className="w-100 h-100">
      <div className="d-flex justify-content-between ps-4 pe-5"></div>
      <div className="d-flex flex-column justify-content-center align-items-center overflow-auto">
        {isDownloading ? (
          <CustomLoader />
        ) : (
          <>
            {isPdf && blobUrl ? (
              <iframe
                src={blobUrl}
                title="PDF Viewer"
                style={{ width: "100%", height: "600px" }}
              />
            ) : blobUrl ? (
              <img
                src={blobUrl}
                alt="doc"
                style={{ maxWidth: "80%", maxHeight: "80%" }}
              />
            ) : (
              <img
                src={ErrorImg}
                alt="Error Image "
                width={"50%"}
                height={"50%"}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ViewMedia;
