import React, { ChangeEvent } from "react";
import { Box, Button, InputAdornment, Slider, TextField, Tooltip } from "@mui/material";
import { MuiTextFieldBorderStyle } from "../../../../util/mui-helper";
import { PrayerModel } from "../../../../model/caseDocument/prayer";

import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


import {
  editCounterPrayers,
  editPrayers,
} from "../../../../redux/slice/caseResponseSlice";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import ConditionalWrapper from "../../../../Layout/ConditionalWrapper";

interface PrayersEditBoxProps {
  prayers: PrayerModel;
  index: number;
  isCounterPrayer: boolean;
  showObjectionsBox: boolean;
}

const PrayersRow: React.FC<PrayersEditBoxProps> = ({
  prayers,
  index,
  isCounterPrayer,
  showObjectionsBox,
}) => {
  const marks = [
    {
      label: "Low",
      value: 0,
    },

    {
      label: "Medium",
      value: 5,
    },

    {
      label: "High",
      value: 10,
    },
  ];

  const marksForPercentage = [
    {
      value: 0,
      label: "0%",
    },
    {
      value: 1,
    },
    {
      value: 2,
    },
    {
      value: 3,
    },
    {
      value: 4,
    },
    {
      value: 5,
      label: "50%",
    },
    {
      value: 6,
    },
    {
      value: 7,
    },
    {
      value: 8,
    },
    {
      value: 9,
    },
    {
      value: 10,
      label: "100%",
    },
  ];
  function Percentage_valuetext(value: number) {
    return `${value * 10}%`;
  }
  function valuetext(value: number) {
    return `${value}`;
  }

  const dispatch: AppDispatch = useDispatch();

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: key, value } = e.target;

    if (isCounterPrayer) {
      dispatch(editCounterPrayers({ index, value, key }));
    } else {
      dispatch(editPrayers({ index, value, key }));
    }
  };
  interface AcceptRejectButtonsProps {
    status: number;
    onAccept: () => void;
    onReject: () => void;
    onReset: () => void;
  }

  const AcceptRejectButtons = ({
    status,
    onAccept,
    onReject,
    onReset,
  }: AcceptRejectButtonsProps) => {
    return (
      <div className="d-flex gap-3">
        <Button
          variant={"outlined"}
          className={`${status === 1 ? " text-success" : ""}`}
          startIcon={<DoneIcon />}
          disabled={status === 1 || status === 2}
          sx={{ textTransform: "none" }}
          onClick={onAccept}
        >
          {status === 1 ? "Accepted" : "Accept"}
        </Button>

        <Button
          className={`${status === 2 ? " text-danger" : ""}`}
          startIcon={<CancelIcon />}
          variant={"outlined"}
          disabled={status === 1}
          sx={{ textTransform: "none" }}
          onClick={onReject}
        >
          {status === 2 ? "Rejected" : "Reject"}
        </Button>

        {(status === 1 || status === 2) && (
          <Button
            variant="outlined"
            sx={{ textTransform: "none" }}
            onClick={onReset}
          >
            Reset
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="h-100">
      <Box className="container d-flex flex-column justify-content-center align-items-center gap-2">
        <ConditionalWrapper condition={!showObjectionsBox || isCounterPrayer}>
          <TextField
            multiline
            error={!!prayers.errors?.prayerDetail}
            type="text"
            placeholder={
              isCounterPrayer ? "Add Your Counter Prayer" : "Add Your Prayer"
            }
            variant="standard"
            name="prayerDetail"
            helperText={prayers.errors?.prayerDetail}
            value={prayers.prayerDetail ? prayers.prayerDetail : ""}
            sx={{ ...MuiTextFieldBorderStyle, width: 600 }}
            onChange={onInputChange}
          />
          <p className="fs-sm-lg-med pt-2 p-0 m-0" style={{ width: 600 }}>
            Intensity of wants
          </p>
          <Box sx={{ width: 600 }}>
            <Slider
              aria-label="Always visible"
              getAriaValueText={valuetext}
              step={1}
              marks={marks}
              value={prayers.intensity as number}
              min={0}
              max={10}
              valueLabelDisplay="auto"
              onChange={(event, value) =>
                onInputChange({
                  target: {
                    name: "intensity",
                    value: value as any,
                  },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            />
          </Box>
        </ConditionalWrapper>

        <ConditionalWrapper condition={showObjectionsBox}>
          {/* Only if status is rejected  i.e status = 2 */}
          <ConditionalWrapper condition={prayers?.status === 2}>
            <div className="fs-sm-lg-semibold mb-2">
              <span> Add Your Objections here </span>
             
            </div>
           
            <TextField
              error={!!prayers?.errors?.objection}
              className="rounded d-flex "
              type="text"
              placeholder="Add Your Objections"
              variant="outlined"
              name="objection"
              helperText={prayers?.errors?.objection}
              multiline={true}
              rows={3}
              value={prayers?.objection}
              sx={{ ...MuiTextFieldBorderStyle, width: 500 }}
              onChange={onInputChange}
            />
            <div className="fs-sm-lg-semibold mt-3 mb-5">
              How much percentage do you agree ?
            </div>
            <Box sx={{ width: 500 }}>
              <Slider
                aria-label="Always visible"
                defaultValue={5}
                step={1}
                min={0}
                max={10}
                valueLabelDisplay="auto"
                getAriaValueText={Percentage_valuetext}
                valueLabelFormat={Percentage_valuetext}
                marks={marksForPercentage}
                value={(prayers?.percentage as number) || 0}
                onChange={(event, value) =>
                  onInputChange({
                    target: {
                      name: "percentage",
                      value: value as any,
                    },
                  } as React.ChangeEvent<HTMLInputElement>)
                }
              />
            </Box>
          </ConditionalWrapper>
        </ConditionalWrapper>
        {/* Allow to accept reject only for claimant side prayers */}
        <ConditionalWrapper className="mb-2" condition={!isCounterPrayer}>
          <AcceptRejectButtons
            status={prayers.status}
            onAccept={() =>
              dispatch(editPrayers({ index, value: 1, key: "status" }))
            }
            onReject={() =>
              dispatch(editPrayers({ index, value: 2, key: "status" }))
            }
            onReset={() => {
              dispatch(editPrayers({ index, value: 0, key: "status" }));
              dispatch(editPrayers({ index, value: "", key: "objection" }));
            }}
          />
        </ConditionalWrapper>
      </Box>
    </div>
  );
};

export default PrayersRow;
