import React, { useEffect } from "react";
import ProfileDetails from "../../components/Profile/ProfileDetails";
import { Box } from "@mui/material";
import ChangePassword from "../../components/Profile/ChangePassword";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import {
  inviteMemberListThunk,
  setShowChangePasswordMenu,
  setShowProfileDetailsMenu,
  setUserList,
} from "../../redux/slice/userSlice";
import { CHANGE_PASSWORD, PROFILE_PAGE } from "../../constants/nav-constants";

const Profile = () => { 

  const [selectedLabel, setSelectedLabel] = React.useState({
    id: PROFILE_PAGE,
    label: "Profile details",
  });
  const dispatch: AppDispatch = useDispatch();
  const uData = useSelector((state: RootState) => state.profileDetail);

  const { showChangePasswordMenu, showProfileDetailsMenu } =
    uData;

  const handleSelection = (pageLabel: any) => {
    setSelectedLabel({
      id: pageLabel.id,
      label: pageLabel.label,
    });
  };

  useEffect(() => {
    if (showChangePasswordMenu) {
      dispatch(setShowChangePasswordMenu(false));
      setSelectedLabel(OPTIONS[1]);
    }
    if (showProfileDetailsMenu) {
      dispatch(setShowProfileDetailsMenu(false));
      setSelectedLabel(OPTIONS[0]);
    }
  }, [showChangePasswordMenu, showProfileDetailsMenu]);

  useEffect(() => {
    dispatch(inviteMemberListThunk())
      .unwrap()
      .then((data: any) => {
        dispatch(setUserList(data));
      })
      .catch((error) => { });
  }, []);

  const OPTIONS = [
    { id: PROFILE_PAGE, label: "Profile details" },
    { id: CHANGE_PASSWORD, label: "Change password" },
  ];

  return (
    <div className="px-md mb-md mx-0 h-100 ps-4  overflow-hidden ">
      <div className="d-flex align-items-center gap-4 mb-3">
        <div className="fs-sm-heading-med">{selectedLabel.label}</div>
        <span className="fs-sm-light">
          {" "}
          {`Dashboard > `}
          <span className="text-grey">{`${selectedLabel.label}`}</span>
        </span>
      </div>
      <div className="row  h-100 ">
        <div className="col-2">
          <Box>
            {OPTIONS.map((item, index) => (
              <div
                className={`d-flex align-items-center gap-3 fs-sm-lg-light bg-white p-3 br-5 mb-3 cursor-pointer ${selectedLabel.id === item.id ? "text-primary fw-bold" : ""
                  }`}
                key={index}
                onClick={() => handleSelection(item)}
              >
                <Box
                  sx={{
                    backgroundColor:
                      selectedLabel.id === item.id ? "#D7B24D" : "transparent",
                  }}
                  width={"4px"}
                  height={"30px"}
                ></Box>
                <div className="fw-semibold">{item.label}</div>
              </div>
            ))}
          </Box>
        </div>

        <div className="col-10 h-100   ">
          {selectedLabel.id === OPTIONS[0].id && <ProfileDetails />}
          {selectedLabel.id === OPTIONS[1].id && <ChangePassword />}
          {/* {selectedLabel === OPTIONS[2] && <DeleteAccount />} */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
