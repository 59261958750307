import { RM_CONCILIATION, RM_MEDIATION } from "../../constants/case-constants"
import { CLAIMANT_DETAILS, RESPONDENT_DETAILS } from "../../constants/case-state"

export const  getSectionLable=(methodOfResolution:string,sectionId:number)=>{

if(methodOfResolution===RM_CONCILIATION||methodOfResolution===RM_MEDIATION){
      if(sectionId===CLAIMANT_DETAILS){
        return "First Party Details"
    }
      if(sectionId===RESPONDENT_DETAILS){
        return "Second Party Details"
    }
}else{

   if(sectionId===CLAIMANT_DETAILS){
        return "Claimant Details"
    }
   if(sectionId===RESPONDENT_DETAILS){
        return "Respondent Details"
    }
}
}