import {
  Box,
  IconButton,
  Accordion,
  AccordionDetails,
  AccordionSummary,

} from "@mui/material";
import React, { useState } from "react";

import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import { PrayerModel } from "../../../../model/caseDocument/prayer";
import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";


import {
  deletePrayers,
  // deletePrayers,
  setPrayersExpanded,
} from "../../../../redux/slice/caseSlice";
import PrayersRow from "./PrayersRow";
import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
} from "../../../../constants/nav-constants";

const PrayerList = () => {
  const dispatch: AppDispatch = useDispatch();
  const { caseFileDetail, prayersExpanded, response, isRespondent } = store.getState().case;


  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;

  const isAdmin = roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN;
  const prayers: PrayerModel[] = caseFileDetail.prayers


  const [showObjectionDialog, setShowObjectionDialog] = useState(false)

  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        dispatch(setPrayersExpanded(prayersExpanded === panel ? -1 : panel));
      }
    };

  const handleDeletePrayers = (index: number) => {
    dispatch(deletePrayers(index));
  };

  return (
    <div className="d-flex flex-column  w-100 overflow-auto">
      {prayers?.map((prayers, i: number) => (
        <Accordion
          key={i}
          className="border-0"
          expanded={prayersExpanded === i}
          onChange={handleChangeAccordion(i)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="d-flex w-100 gap-3 justify-content-between align-items-center">
              <div className="col d-flex gap-3 align-items-center">
                <img src={DotIcon} alt="dot" width={24} height={24} />
                <Box
                  className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                  width={28.21}
                  height={28.21}
                >
                  {i + 1}
                </Box>
                {(!(prayersExpanded === i) || isRespondent) && (
                  <>
                    {prayers?.prayerDetail && (
                      <Box className=" text-grey  text-truncate" maxWidth={400}>
                        {prayers?.prayerDetail}
                      </Box>
                    )}

                    {!isRespondent ? (
                      <IconButton className="p-0 ">
                        <img src={PenIcon} alt="pen" width={24} height={24} />
                      </IconButton>
                    ) : null}
                  </>
                )}
              </div>

              {prayersExpanded === i && !isRespondent && (
                <div className=" col text-center fs-med-slg-medium  p-0 m-0">Prayer</div>
              )}

              {!(ROLE_ADMIN === roleRid || roleRid === ROLE_SUPER_ADMIN) && (
                <>
                  <div className="col d-flex gap-3 justify-content-end align-items-center">
                    {prayers.intensity <= 3
                      ? <div className="bg-light-blue1 px-3 rounded-4">low</div>
                      : prayers.intensity > 3 && prayers.intensity <= 7
                        ? <div className="bg-light-blue1 px-3 rounded-4">
                          medium
                        </div>
                        : prayers.intensity > 7 && prayers.intensity <= 10 ?
                          <div className="bg-light-red text-red px-3 rounded-4 ">
                            high
                          </div>
                          :
                          <div className="bg-light-blue1  px-3 rounded-4 ">
                            low
                          </div>
                    }

                    {!isAdmin ? (
                      <>
                        <IconButton
                          className="p-0"
                          onClick={() => handleDeletePrayers(i)}
                        >
                          <Box
                            className={`${prayersExpanded === i
                              ? "bg-light-red "
                              : " bg-light-blue1"
                              } p-3 rounded-circle d-flex justify-content-center align-items-center`}
                            width={40}
                            height={40}
                          >
                            <DeleteOutlinedIcon fontSize="large" className={`${prayersExpanded === i ? "text-danger " : null}`} />

                          </Box>
                        </IconButton>
                      </>
                    ) : (
                      <Box></Box>
                    )}
                  </div>
                </>
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex h-100 flex-column">
              <PrayersRow index={i} prayers={prayers} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))
      }

    </div >
  );
};
export default PrayerList;
