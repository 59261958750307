import React from 'react';

interface BannerProps {
    message: string;
}

const bannerStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '0',
    width: 'fit-content',
    borderTopRightRadius: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'yellow',
    padding: '8px',
    textAlign: 'center',
    zIndex: 9999,
};

const Banner: React.FC<BannerProps> = ({ message }) => {
    return (
        <div className='fs-sm-med ' style={bannerStyle}>
            {message}
        </div>
    );
};

export default Banner;
