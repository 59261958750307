import { Box, Button } from "@mui/material";
import React from "react";

import documentIcon from "../../assets/icon/insert_drive_file_24px.png";
import calandarIcon from "../../assets/icon/date_range_24px.png";
import locationIcon from "../../assets/icon/maps/add_location_24px.png";
import { PersonalDetail } from "../../model/PersonalDetail";
import { AppDispatch, RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { KYC_PENDING, KYC_REJECTED, KYC_VERIFIED, ROLE_INDIVIDUAL, ROLE_NEUTRAL } from "../../constants/nav-constants";
import FileService from "../../network/FileService";
import ReportIcon from '@mui/icons-material/Report';
import { formatDateTime } from "../../util/stringUtils";

interface profileDetailsProps {
  personalDetail: PersonalDetail;
}

const ProfileDetailsTab = ({ personalDetail }: profileDetailsProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { profileDetail } = useSelector(
    (state: RootState) => state.profileDetail
  );

  const downloadFile = async (fileName: string) => {
    try {
      const res: ArrayBuffer = (await FileService.downloadUserFile([
        profileDetail?.personalDetail?.uniqueId,
        'kyc',
        fileName,
      ])) as ArrayBuffer;
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");

      link.href = url;
      link.setAttribute("download", fileName);

      document.body.appendChild(link);
      link.click();
    } catch (error) {}
  };

  return (
    <Box>
      <div className="bg-light-pink p-3 mt-3 br-5">
        <div className="d-flex mb-3">
          <div>
            <img src={calandarIcon} alt="Date of birth" />{" "}
          </div>
          <div>
            <span className="fs-sm-light ms-1 text-grey">Date of birth</span>
            <div className="fs-normal-light">
              {personalDetail?.dob ? formatDateTime(personalDetail.dob) : "N/A"}
            </div>
          </div>
        </div>

        <div className="d-flex">
          <div>
            <img src={locationIcon} alt="Address" />
          </div>
          <div>
            <span className="fs-sm-light ms-2 text-grey">Address</span>
            <div className="fs-normal-light">
              {personalDetail?.address ? personalDetail.address : "N/A"}
            </div>
          </div>
        </div>
      </div>

      {((profileDetail?.personalDetail?.roleRid === ROLE_INDIVIDUAL || profileDetail?.personalDetail?.roleRid === ROLE_NEUTRAL)  &&
        [KYC_REJECTED, KYC_PENDING, KYC_VERIFIED].includes(profileDetail?.kycDetail?.kycStatus)) && (
          <div className="outer-border br-5 mt-4 mb-5">
            <span className="fs-sm-lg-semibold label">KYC Documents</span>

            <div className="d-flex gap-4 inner-content flex-column">
              <div className="position-relative w-100 mb-3">
                <div className="d-flex gap-3 flex-column bg-light-pink rounded p-3 fit-content flex-grow-1">
                <div className="text-grey fs-sm-light text-center">
                  {`${profileDetail?.kycDetail?.kycData[0]?.kycType || "N/A"} ${profileDetail?.kycDetail?.kycData[0]?.fieldValue ? '/ ' + profileDetail?.kycDetail?.kycData[0]?.fieldValue : ''}`}
                </div>

                  <div className="text-center bg-white py-4 px-5">
                    <img src={documentIcon} alt="Documents" />
                  </div>
                  {profileDetail?.kycDetail?.kycData[0]?.status === KYC_REJECTED &&
                    <div className="d-flex gap-2 align-items-center">
                      <ReportIcon fontSize="small" color="error" />
                      <p className="fs-sm-med text-danger p-0 m-0">
                        {profileDetail?.kycDetail?.kycData[0]?.remark}
                      </p>
                    </div> }
                </div>
                <div className="position-absolute top-100 end-0">
                  <Button
                    className={`text-primary fs-sm-med ${
                      (!profileDetail?.kycDetail?.kycData[0]?.attachment || !profileDetail?.kycDetail?.kycData[0]?.attachment?.trim()) &&
                      "d-none"
                    }`}
                    variant="text"
                    onClick={() =>
                      downloadFile(
                        profileDetail?.kycDetail?.kycData[0]?.attachment
                      )
                    }
                    sx={{ textTransform: "none" }}
                  >
                    Download
                  </Button>
                </div>
              </div>

              {profileDetail?.kycDetail?.kycStatus === KYC_REJECTED &&
                <div className="d-flex gap-2 align-items-center">
                  <ReportIcon fontSize="small" color="error" />
                  <p className="fs-sm-med text-danger p-0 m-0">
                    Your KYC request has been rejected.
                  </p>
                </div> }
            </div>
          </div>
        )}
    </Box>
  );
};

export default ProfileDetailsTab;
