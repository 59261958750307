import React, { useEffect } from 'react';

import { ZoomMtg } from '@zoomus/websdk';
import { AppDispatch, RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { CaseTimeline, getMeetingSignatureThunk } from '../../redux/slice/neutralTimelineSlice';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomLoader from '../../components/common/CustomLoader';
import useMeeting from '../../hooks/useMeeting';

const MeetingRoot = () => {
    const location = useLocation();
    const meetingDetail: CaseTimeline = location.state?.meetingDetail;
    const leaveUrl = location.state?.leaveUrl
    const { primaryNeutral } = useSelector((state: RootState) => state.case);
    const { profileDetail } = useSelector(
        (state: RootState) => state.profileDetail
    );

    const zoomIntegrationData = {
        sdkKey: "SJuI5WNqRsS2kNmfDbBUJA",   //? create constants from configuration
        sdkSecret: "GkunYwQqGczLTVzfooSg5Hr89JObd4eQ",
        meetingNumber: meetingDetail.meetingId,
        passWord: meetingDetail.meetingPassword,
        role: primaryNeutral ? 1 : 0,
        userName: profileDetail?.personalDetail?.fullName,
        userEmail: profileDetail?.personalDetail?.email,
        registrantToken: "",
        zakToken: "",
        leaveUrl: leaveUrl
    };


    const { joinMeeting } = useMeeting(zoomIntegrationData)
    joinMeeting()
    return (
        <div><CustomLoader /></div>
    );
}

export default MeetingRoot;