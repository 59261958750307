import {
  NO_PERMISSION,
  PERMISSION_ALLOWED,
  VIEW,
  VIEW_ADD_REMARKS,
  VIEW_EDIT,
  VIEW_ACCEPT_REJECT,
  VIEW_RESOLVE,
} from "../../constants/permission";



export const isNoPermissionEnabled = (
  sectionCode: any,
  permissionState: any
) => {
  if (permissionState) {
    return permissionState[sectionCode] === NO_PERMISSION;
  } else {
    return false;
  }
};

export const isPermissionAllowedEnabled = (
  sectionCode: any,
  permissionState: any
) => {
  if (permissionState) {
    return permissionState[sectionCode] === PERMISSION_ALLOWED;
  } else {
    return false;
  }
};

export const isViewEnabled = (sectionCode: any, permissionState: any) => {
  if (permissionState) {
    return permissionState[sectionCode] === VIEW;
  } else {
    return false;
  }
};

export const isViewEditEnabled = (sectionCode: any, permissionState: any) => {
  
  if (permissionState) {
    return permissionState[sectionCode] === VIEW_EDIT;
  } else {
    return false;
  }
};

export const isViewAddRemarksEnabled = (
  sectionCode: any,
  permissionState: any
) => {
  if (permissionState) {
    return permissionState[sectionCode] === VIEW_ADD_REMARKS;
  } else {
    return false;
  }
};
export const isAcceptRejectEnabled = (
  sectionCode: any,
  permissionState: any
) => {
  if (permissionState) {
    return permissionState[sectionCode] === VIEW_ACCEPT_REJECT;
  } else {
    return false;
  }
};
export const isViewResolve = (sectionCode: any, permissionState: any) => {
  if (permissionState) {
    return permissionState[sectionCode] === VIEW_RESOLVE;
  } else {
    return false;
  }
};
