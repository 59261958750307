import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../network/UserService";

// export const orgListThunk = createAsyncThunk(
//   "/organization-list",
//   async (_params: any, thunkApi) =>
//     await UserService.apiOrganizationList(_params)
// );



const initUiState = {
 caseDetailedPage:{
    tab:0, //0 timeLine 1 caseDetails 2 response  
 },
};
export const uiStateSlice = createSlice({
  name: "user-details",
  initialState: initUiState,
  reducers: {
    setUiState:(state,action)=>{
      const data=action.payload
      const tempState={...state,...data};
      return tempState;
    },
    setUiTab: (state, action) => {
     state.caseDetailedPage.tab = action.payload
    },
  },
//   extraReducers(builder) {
   
//   },
});

export const { setUiTab,  setUiState } = uiStateSlice.actions;
