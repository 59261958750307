import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Skeleton,
  Switch,
} from "@mui/material";
import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";


import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";

import {
  deleteClaimant,
  editPrimaryClaimant,
  setClaimantExpanded,
} from "../../../../redux/slice/caseSlice";
import ClaimantRow from "./ClaimantRow";

import {
  ROLE_ADMIN,
  ROLE_SUPER_ADMIN,
} from "../../../../constants/nav-constants";
import { CLAIMANT_DETAILS } from "../../../../constants/case-state";
import { getSectionLable } from "../../../../util/case/getSectionLable";

const ClaimantList = () => {
  const dispatch: AppDispatch = useDispatch();

  const { claimantList, isLoading, claimantExpanded, caseFile } =
    store.getState().case;

  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;
  const isAdmin = roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN;

  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        dispatch(setClaimantExpanded(claimantExpanded === panel ? -1 : panel));
      }
    };

  
  const handleDeleteClaimant = (index: number) => {
    dispatch(deleteClaimant(index));
  };

  return (
    <>
      {isLoading ? (
        <Box className="bg-white py-5 px-3 d-flex justify-content-center flex-column gap-3  align-items-center ">
          <div className="d-flex gap-2">
            <Skeleton variant="rounded" width={150} height={150} />
            <Skeleton variant="rounded" width={150} height={150} />
          </div>
          <Skeleton variant="rounded" width={350} height={30} />
          <Skeleton variant="rounded" width={350} height={30} />
          <Skeleton variant="rounded" width={350} height={30} />
          <Skeleton variant="rounded" width={350} height={30} />
          <Skeleton variant="rounded" width={350} height={30} />
        </Box>
      ) : (
        <div className="d-flex flex-column w-100 overflow-auto">
          {claimantList.map((claimant, i) => (
            <Accordion
              key={i}
              className="border-0"
              expanded={claimantExpanded === i}
              onChange={handleChangeAccordion(i)}
            >
              <AccordionSummary
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <div className="d-flex w-100 gap-3 justify-content-between align-items-center position-relative">
                  <div className="d-flex gap-3 align-items-center">
                    <img src={DotIcon} alt="dot" width={24} height={24} />
                    <Box
                      className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                      width={28.21}
                      height={28.21}
                    >
                      {i + 1}
                    </Box>

                    {!(claimantExpanded === i) && (
                      <>
                        <div className="body-large fw-bold">
                          {claimant.name}
                        </div>
                        <IconButton className="p-0">
                          <img src={PenIcon} alt="pen" width={24} height={24} />
                        </IconButton>
                      </>
                    )}
                  </div>

                  {claimantExpanded === i && (
                    <div className="position-absolute top-50 start-50 translate-middle">
                      <div className="fs-med-slg-medium text-center  p-0 m-0">
                       {getSectionLable(caseFile.resolutionMethod,CLAIMANT_DETAILS)}
                      </div>
                    </div>
                  )}

                  <div className="d-flex gap-4 align-items-center">
                    <>
                      {claimantExpanded === i ? (
                        <Box height={40}>
                          <FormControlLabel
                            control={
                              <Switch
                                color="primary"
                                checked={!!claimant.isPrimary}
                              />
                            }
                            label={
                              <span className="fs-normal-med">
                                Set as Primary
                              </span>
                            }
                            labelPlacement="start"
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(editPrimaryClaimant(i));
                            }}
                          />
                        </Box>
                      ) : claimant.isPrimary ? (
                        <Chip
                          variant="outlined"
                          color="success"
                          label={<span className="fs-small-med">Primary</span>}
                        />
                      ) : (
                        ""
                      )}
                    </>

                    {!isAdmin ? (
                      <IconButton
                        className="p-0"
                        onClick={() => handleDeleteClaimant(i)}
                      >
                        <Box
                          className={`${
                            claimantExpanded === i
                              ? "bg-light-red "
                              : " bg-light-blue1"
                          } p-3 rounded-circle d-flex justify-content-center align-items-center`}
                          width={40}
                          height={40}
                        >
                          <DeleteOutlineIcon
                            className={`${
                              claimantExpanded === i ? "text-danger" : ""
                            }`}
                            fontSize="medium"
                          />
                        </Box>
                      </IconButton>
                    ) : (
                      <Box></Box>
                    )}
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div className="d-flex gap-3 h-100 flex-column">
                  <ClaimantRow index={i} claimant={claimant} />
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </>
  );
};

export default ClaimantList;
