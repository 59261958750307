import React, { useEffect, useRef, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../../../redux/store";
import { setDetailedFacts } from "../../../../redux/slice/caseSlice";

import { Editor } from "@tinymce/tinymce-react";

const DetailedFacts = () => {
  const caseData = useSelector((state: RootState) => state.case);

  const { hasResponse, isRespondent } = caseData;
  const { detailedFacts }: any =
    isRespondent || hasResponse
      ? caseData.response.caseFileResponseDetail
      : caseData.caseFileDetail;

  const editorRef = useRef<any>(null);

  const dispatch: AppDispatch = useDispatch();

  const handleChangeInput = () => {
    if (editorRef.current) {
      dispatch(
        setDetailedFacts({
          ...detailedFacts,
          factsDetail: editorRef.current.getContent(),
        })
      );
    }
  };

  return (
    <>
      <div className="d-flex  flex-column  w-100 overflow-auto">
        <div className="d-flex  gap-4 w-100">
          <div className="p-5  rounded-3 h-100 w-100 bg-white ">
            <div className="fs-med-slg-medium  text-center p-0 m-0 mb-3">
              Detailed Facts
            </div>
            <div className="d-flex justify-content-center">
            <Editor 
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={() => {
                handleChangeInput();
              }}
              value={detailedFacts?.factsDetail}
              apiKey={process.env.REACT_APP_TINY_MCE_KEY}
              init={{
                height: 400,
                width:650,
                
                branding: false,
                menubar: false,

                plugins:
                  "advlist autolink lists link image charmap print preview anchor " +
                  "searchreplace visualblocks code fullscreen insertdatetime media table paste wordcount",

                toolbar:
                  "undo redo | formatselect | bold italic underline  | " +
                  "bullist numlist outdent indent | " +
                  "fullscreen",

                content_style:
                  "body { font-family: Arial, sans-serif; font-size: 16px; line-height: 1.6; }",
              }}
            />

            </div>

            
            <span className="text-red">{detailedFacts?.errors}</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailedFacts;
