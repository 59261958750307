// pages
export const PROFILE_PAGE = 0;
export const CHANGE_PASSWORD = 1;

export const INDIVIDUAL = "INDIVIDUAL";
export const LEGAL_PROFESSIONAL = "LEGAL_PROFESSIONAL";
export const OTHER_ORG = "OTHER_ORG";
export const NEUTRAL = "NEUTRAL";

export const PART_OF_ORG = "PART_OF_ORG";

// sign-up
export const REGISTER_TYPE = "SIGN_UP";
export const INVITATION_TYPE = "INVITATION";

// roleId types
export const ROLE_INDIVIDUAL = 1;
export const ROLE_LAW_FIRM_ADMIN = 2;
export const ROLE_ORG_ADMIN = 3;
export const ROLE_SUPER_ADMIN = 4;
export const ROLE_ADMIN = 5;
export const ROLE_ORG_USER = 6;
export const ROLE_LAW_FIRM_USER = 7;
export const ROLE_NEUTRAL = 8;

// kyc status
export const KYC_PENDING = "PENDING";
export const KYC_VERIFIED = "VERIFIED";
export const KYC_REJECTED = "REJECTED";

// email verification status
export const EMAIL_PENDING = 2;
export const EMAIL_REQUEST_SENT = 1;
export const EMAIL_VERIFIED = 2;

// name sorting
export const NAME_ASC = "NAME_ASC";
export const NAME_DESC = "NAME_DESC";
