import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import NetworkService from "../../network/NetworkService";
import { DRAFT, NON_DRAFT } from "../../constants/case-constants";
import { PositionType } from "../../model/caseListApiModel";

export const caseCountThunk = createAsyncThunk(
  "/case-count",
  async (_data: PositionType | null, thunkApi) =>
    await NetworkService.getCaseCount(_data)
);

export const caseListThunk = createAsyncThunk(
  "/case-list",
  async (data: any, thunkApi) =>
    await NetworkService.caseList(data.status, data)
);

export const caseListSlice = createSlice({
  name: "caseList",
  initialState: {
    caseCount: 0,
    draftCaseCount: 0,
    caseCountAsClaimant: 0,
    caseCountAsRespondent: 0,
    caseCountAsAdvocate: 0,

    asArbitrator: 0,
    asConciliator: 0,
    asMediator: 0,

    caseList: [],
    isLoading: false,
    errorMessage: "",
    recordCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(caseListThunk.pending, (state, action) => {
        state.isLoading = true;
        state.errorMessage = "";
      })
      .addCase(caseListThunk.fulfilled, (state, action) => {
        const { recordCount, data } = action.payload as any;
        const { caseList } = data;

        state.caseList = caseList;
        state.isLoading = false;
        state.errorMessage = "";
        state.recordCount = recordCount;
      })
      .addCase(caseListThunk.rejected, (state, action) => {
        state.errorMessage = action.error.message ?? "";
        state.isLoading = false;
      })

      .addCase(caseCountThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(caseCountThunk.fulfilled, (state, action) => {
        const {
          caseCountAsClaimant,
          draftCaseCount,
          caseCountAsRespondent,
          caseCount,
          asArbitrator,
          asConciliator,
          asMediator,
        } = action.payload as any;

        state.caseCount = caseCount;
        state.draftCaseCount = draftCaseCount;
        state.caseCountAsClaimant = caseCountAsClaimant;
        state.caseCountAsRespondent = caseCountAsRespondent;
        state.asArbitrator=asArbitrator;
        state.asConciliator=asConciliator;
        state.asMediator=asMediator;
        state.isLoading = false;
      })
      .addCase(caseCountThunk.rejected, (state, action) => {
        state.isLoading = false;
      });
  },
});
