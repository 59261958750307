import React, { ChangeEvent, useEffect, useState } from "react";
import ViewMoreContainer from "./ViewMoreContainer";
import { AppDispatch, RootState, store } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { formatDateTime } from "../../../util/stringUtils";

import {
  CLAIMANT_DETAILS,
  COUNTER_PRAYER,
  DETAILED_FACTS,
  EVIDENCES,
  GROUNDS_REASONING,
  PRAYERS,
  RESPONDENT_DETAILS,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  SYNOPSIS,
} from "../../../constants/case-state";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  editSynopsisEvidence,
  reUploadSynopsisEvidence,
  updateSynopsis,
} from "../../../redux/slice/caseSlice";
import {
  isAcceptRejectEnabled,
  isViewAddRemarksEnabled,
  isViewEditEnabled,
} from "../../../util/permission/role-permission";
import { CD, CE, DF, GR, PR, RD, SY } from "../../../constants/permission";
import StringDifferenceHighlighter from "../../../components/StringDifferenceHighlighter";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import {
  DRAFT,
  NEW_CASE,
  RM_CONCILIATION,
  RM_MEDIATION,
  SCRUTINY_REVIEW,
  SENT_FOR_RESCRUTINY,
} from "../../../constants/case-constants";
import { RejectApproveBtns } from "./RejectApproveBtns";
import FileService from "../../../network/FileService";
import { DASHBOARD, MEDIA, VERSION_COMPARISON } from "../../../routes/routes";
import { useNavigate } from "react-router";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../../constants/nav-constants";
import FileUpload from "../../../components/common/FileUpload";
import { editResponseSynopsisEvidence } from "../../../redux/slice/caseResponseSlice";
import { Evidence, SynopsisModel } from "../../../model/caseDocument/synopsis";
import { ShowLabel } from "../../../components/common/ShowLabel";
import { Claimant, Respondent, StatusType } from "../../../model/fileCase";
import CustomLoader from "../../../components/common/CustomLoader";
import DialogLayout from "../../../Layout/DialogLayout";
import ViewMedia from "../../../components/ViewMedia";
import { SynopsisType } from "../../../redux/slice/components/initCaseResponseSlice";
import { Grounds } from "../../../model/caseDocument/grounds";
import MediaLayout from "../../../Layout/MediaLayout";
import { useLocation } from "react-router-dom";
import { CaseAgreement, DateOption } from "../../../redux/slice/components/initCaseSliceState";
import HTMLDiffComponent from "../../../components/HTMLDiffComponent";
import { DetailedFacts } from "../../../model/caseDocument/detailedFacts";
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { Prayer, PrayerModel } from "../../../model/caseDocument/prayer";
import FileUploadLayout from "../../../components/common/FileUploadLayout";
import { FileUploadApiType } from "../../../constants/api-constants";


interface ViewCaseContentProps {
  showLoadBtn: boolean;
  showHighlighter?: boolean;
  showPreVersion?: boolean;
  isVersionPage?: boolean;
  isExpanded?: boolean;
}

const ViewCaseContent: React.FC<ViewCaseContentProps> = ({
  showLoadBtn,
  showHighlighter = false,
  showPreVersion = false,
  isVersionPage = false,
  isExpanded = false
}) => {
  const [isUploading, setUploading] = useState<boolean>(false);

  const isVersionComparision = useLocation().pathname.split('/')[1] === VERSION_COMPARISON.split('/')[1]

  const { claimantList,
    respondentList,
    caseFileDetail,
    caseFile,
    hasResponse,
    isRespondent,
    permissionState,
    scrutinyCount,
    isClaimant,
    isAdmin
  } = useSelector((state: RootState) => state.case);
  const {
    caseFileResponse,
    caseFileResponseDetail,
    permissionState: permissionStateResponse,
  } = useSelector((state: RootState) => state.caseResponse);


  const [claimantListData, setClaimantListData] = useState<Claimant[] | null>(null)
  const [respondentListData, setResponseListData] = useState<Respondent[] | null>(null)
  const [synopsisListData, setSynopsisListData] = useState<SynopsisModel[] | SynopsisType | null>(null)
  const [groundsData, setGroundsData] = useState<Grounds[] | null>(null)
  const [detailedFacts, setDetailedfacts] = useState<DetailedFacts | null>(null);
  const [prayerData, setPrayerData] = useState<PrayerModel[] | null>(null);


  const [synopsisTab, selectedsynopsisTab] = useState(0);
  const [groundsTab, selectedGroundsTab] = useState(0);
  const [detailedTab, selectedDetailedTab] = useState(0);
  const [evidenceTab, selectedEvidenceTab] = useState(0);
  const [prayersTab, setPrayersTab] = useState(0);

  useEffect(() => {

    if (showPreVersion) {
      setClaimantListData(caseFileDetail.previousClaimants)
      setResponseListData(caseFileDetail.previousRespondents)
      setGroundsData(caseFileDetail.previousGrounds)
      if (synopsisTab === 1) {
        setSynopsisListData(caseFileResponseDetail?.previousSynopsis)
      } else {
        setSynopsisListData(caseFileDetail?.previousSynopsis)
      }
      setDetailedfacts(caseFileDetail.previousDetailedFacts);
      setPrayerData(caseFileDetail?.previousPrayers)
    } else {

      setClaimantListData(claimantList)
      setResponseListData(respondentList)
      setGroundsData(caseFileDetail.grounds)

      if (synopsisTab === 1) {
        setSynopsisListData(caseFileResponseDetail?.synopsis)
      } else {
        setSynopsisListData(caseFileDetail?.synopsis)
      }
      setDetailedfacts(caseFileDetail.detailedFacts);
      setPrayerData(caseFileDetail?.prayers)

    }

  }, [caseFileDetail.previousClaimants, caseFileResponseDetail, claimantList, respondentList])

  const { synopsis } = store.getState().case.caseFileDetail



  useEffect(() => {
    if (showPreVersion) {
      setSynopsisListData(caseFileDetail?.previousSynopsis)

    } else {
      setSynopsisListData(caseFileDetail?.synopsis)

    }
  }, [synopsis])






  const [loadClaimantStatus, setLoadClaimantStatus] = useState(!showLoadBtn);
  const [loadRespondentStatus, setLoadRespondentStatus] = useState(
    !showLoadBtn
  );
  const [loadSynopsisStatus, setLoadSynopsisStatus] = useState(!showLoadBtn);
  const [loadFactsStatus, setLoadFactsStatus] = useState(!showLoadBtn);
  const [loadGroundsStatus, setLoadGroundsStatus] = useState(!showLoadBtn);
  const [loadPrayersStatus, setLoadPrayersStatus] = useState(!showLoadBtn);
  const [loadCounterPrayersStatus, setLoadCounterPrayersStatus] = useState(
    !showLoadBtn
  );
  const [loadEvidencesStatus, setLoadEvidencesStatus] = useState(!showLoadBtn);
  const [loadStatementOfObjection, setStatementOfObjection] = useState(
    !showLoadBtn
  );





  const [openFile, setOpenFile] = useState(false);
  const [isFilePdf, setIsFilePdf] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [fileToOpen, setFileToOpen] = useState<Evidence | null>(null);




  const [activeEvidenceIndex, setActiveEvidenceIndex] = useState<
    number | null
  >();
  const [activeSynopsisIndex, setActiveSynopsisIndex] = useState<
    number | null
  >();

  const getIntensity = (scale: number) => {
    if (scale >= 0 && scale <= 3) {
      return "Low";
    } else if (scale >= 4 && scale <= 7) {
      return "Medium";
    } else if (scale >= 8 && scale <= 10) {
      return "High";
    } else {
      return "";
    }
  };

  const dispatch: AppDispatch = useDispatch();

  const handelEvidenceChange = (
    key: string,
    value: any,
    synopsysIndex: number,
    evidenceIndex: number
  ) => {
    if (hasResponse) {
      dispatch(
        editResponseSynopsisEvidence({
          synopsysIndex,
          evidenceIndex,
          key,
          value,
        })
      );
    } else {
      dispatch(
        editSynopsisEvidence({ synopsysIndex, evidenceIndex, key, value })
      );
    }
  };

  const navigate = useNavigate();





  const reuploadEvidence = (file: CaseAgreement | Evidence, synopsisIndex: any, evidenceIndex: any) => {


    dispatch(
      reUploadSynopsisEvidence({
        removeFile: false,
        synopsisIndex,
        evidenceIndex,
        actualFileName: file.actualFileName,
        uploadedFileName: file.uploadedFileName,
        tempReUpload: true,
        mineType: file.mimeType,
      })
    );
    // dispatch(updateSynopsis({ synopsisIndex, key: "evidences" }));


  }

  const removeFile = (synopsisIndex: any, evidenceIndex: any) => {
    dispatch(
      reUploadSynopsisEvidence({
        removeFile: true,
        synopsisIndex,
        evidenceIndex,
        actualFileName: "",
        uploadedFileName: "",
        tempReUpload: false,
      })
    );
  };


  const handleViewFile = async (evidence: Evidence) => {
    const url = [
      caseFile.status,
      caseFile.uniqueId ?? "",
      "SYNOPSIS",
      evidence.uploadedFileName,
    ].join("/");

    setIsFilePdf(url.endsWith(".pdf"));
    setOpenFile(true)
    setFileUrl(url)
    setFileToOpen(evidence)
  };

  const isUnderScrutinyReview = isRespondent
    ? caseFileResponse?.status === SCRUTINY_REVIEW
    : caseFile.status === SCRUTINY_REVIEW;


  const prayersToRender = hasResponse ?
    caseFileResponseDetail?.prayer?.prayers
    : caseFileDetail?.prayers




  return (
    <>
      <div className="d-flex gap-5 flex-column py-5 px-4 h-100 overflow-auto">
        <div className="d-flex flex-column gap-1">
          <h4 className="fs-sm-lg-semibold text-center p-0 m-0">
            {caseFile.resolutionMethod === RM_MEDIATION ? "MEDIATION PROCEEDINGS"
              : caseFile.resolutionMethod === RM_CONCILIATION ? "CONCILIATION PROCEEDINGS" : "Before the Hon'ble Arbitral Tribunal"}
          </h4>
          {/* <h4 className="fs-sm-lg-semibold text-center p-0 m-0">
            WRIT PETITION NO. / 2023 (GM-MM-S)
          </h4> */}
        </div>

        <ViewMoreContainer
          sectionId={CLAIMANT_DETAILS}
          label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "First Party Details" : "Claimant Details"}
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadClaimantStatus}
          setLoadBtnStatus={setLoadClaimantStatus}
          scrutinyCount={scrutinyCount?.claimantList}
          enableEdit={isVersionPage ? false : isViewEditEnabled(CD, permissionState)}
          isVersionPage={isVersionPage}
          hasComment={(claimantListData?.some(data => data.reason) && (isAdmin || isClaimant))}

        >
          {loadClaimantStatus ? (
            <div className="px-3 py-4 overflow-hidden">
              <div className="row row-cols-2 g-5 " >
                {claimantListData?.map((data, key) => (
                  <div className="col text-truncate " key={key}>
                    <div className="d-flex gap-2 align-items-center">
                      <span className="fs-normal-light p-0 m-0">
                        {key + 1}.
                      </span>
                      <span className="fs-normal-med p-0 m-0">
                        {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                          <StringDifferenceHighlighter
                            original={caseFileDetail?.previousClaimants[key]?.name}
                            modified={data?.name}
                          />
                        ) : (
                          <>{data.name}</>
                        )}
                      </span>
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousClaimants[key]?.orgRepresentative}
                          modified={data?.orgRepresentative}
                        />
                      ) : (
                        <>{data.orgRepresentative}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousClaimants[key]?.designation}
                          modified={data?.designation}
                        />
                      ) : (
                        <>{data.designation}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <HTMLDiffComponent
                          oldContent={caseFileDetail?.previousClaimants[key]?.address ?? ''}
                          newContent={data?.address ?? ''}
                        />
                      ) : (
                        <>{data.address}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousClaimants[key]?.mobileNo}
                          modified={data?.mobileNo}
                        />
                      ) : (
                        <>{data.mobileNo}</>
                      )}
                    </div>

                    <Box className="fs-normal-light p-0 ms-3 text-truncate w-100">
                      <Tooltip title={data?.email ?? ""}>
                        {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                          <StringDifferenceHighlighter
                            original={caseFileDetail?.previousClaimants[key]?.email}
                            modified={data?.email}
                          />
                        ) : (
                            <Box  className="fs-normal-light p-0  text-truncate">{data.email}</Box>
                        )}
                      </Tooltip>
                    </Box>

                    {data.hasChanges && !isVersionPage && isAcceptRejectEnabled(CD, hasResponse ? permissionStateResponse : permissionState) && (
                      <>
                        <RejectApproveBtns
                          dataKey={CLAIMANT_DETAILS}
                          index={key}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

          ) : (
            <>
              <div className="w-100 overflow-hidden pb-4">
                {claimantListData?.map((data, key) => (
                  <div
                    className={`row d-flex px-3 py-2 align-items-center ${key % 2 !== 0 ? "bg-light-blue2" : ""}`}
                    key={key}
                  >
                    <div className="col d-flex gap-2 align-items-center">
                      <span className=" fs-normal-light">{key + 1}.</span>
                      <span className="fs-normal-light ">{data.name}</span>
                    </div>
                    <div className="col fs-normal-light flex-grow-1 p-0 m-0">
                      {data.address}
                    </div>
                  </div>
                ))}
              </div>

            </>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={RESPONDENT_DETAILS}
          label={caseFile.resolutionMethod === RM_CONCILIATION || caseFile.resolutionMethod === RM_MEDIATION ? "Second Party Details" : "Respondent Details"}
          isVersionPage={isVersionPage}

          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadRespondentStatus}
          setLoadBtnStatus={setLoadRespondentStatus}
          scrutinyCount={scrutinyCount?.respondentList}
          enableEdit={isVersionPage ? false : isViewEditEnabled(RD, permissionState)}
          hasComment={respondentListData?.some(data => data.reason) && (isAdmin || isClaimant)}

        >
          {loadRespondentStatus ? (
            <div className="px-3 py-4 overflow-hidden">
              <div className="row row-cols-2 g-5">
                {respondentListData?.map((data, key) => (
                  <div className="col" key={key}>
                    <div className="d-flex gap-2 align-items-center">
                      <span className="fs-normal-light p-0 m-0">
                        {key + 1}.
                      </span>
                      <span className="fs-normal-med p-0 m-0">
                        {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                          <StringDifferenceHighlighter
                            original={caseFileDetail?.previousRespondents[key]?.name}
                            modified={data?.name}
                          />
                        ) : (
                          <>{data.name}</>
                        )}
                      </span>
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousRespondents[key]?.orgRepresentative}
                          modified={data?.orgRepresentative}
                        />
                      ) : (
                        <>{data.orgRepresentative}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousRespondents[key]?.designation}
                          modified={data?.designation}
                        />
                      ) : (
                        <>{data.designation}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <HTMLDiffComponent
                          oldContent={caseFileDetail?.previousRespondents[key]?.address ?? ''}
                          newContent={data?.address ?? ''}
                        />
                      ) : (
                        <>{data.address}</>
                      )}
                    </div>

                    <div className="fs-normal-light p-0 ms-3">
                      {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                        <StringDifferenceHighlighter
                          original={caseFileDetail?.previousRespondents[key]?.mobileNo}
                          modified={data?.mobileNo}
                        />
                      ) : (
                        <>{data.mobileNo}</>
                      )}
                    </div>


                    <div className="fs-normal-light p-0 ms-3 email-container">
                      <Tooltip title={data?.email ?? ""}>
                        {((isUnderScrutinyReview && data.hasChanges && !isAdmin) || showHighlighter) ? (
                          <StringDifferenceHighlighter
                            original={caseFileDetail?.previousRespondents[key]?.email}
                            modified={data?.email}
                          />
                        ) : (
                          <>{data.email}</>
                        )}
                      </Tooltip>
                    </div>


                    {data.hasChanges && !isVersionPage && isAcceptRejectEnabled(RD, hasResponse ? permissionStateResponse : permissionState) && (
                      <>
                        <RejectApproveBtns
                          dataKey={RESPONDENT_DETAILS}
                          index={key}
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

          ) : (<div className="w-100 overflow-hidden pb-4">
            {respondentListData?.map((data, key) => (
              <div
                className={`row d-flex px-3 py-2 align-items-center ${key % 2 !== 0 ? "bg-light-blue2" : ""}`}
                key={key}
              >
                <div className="col d-flex gap-2 align-items-center">
                  <span className=" fs-normal-light">{key + 1}.</span>
                  <span className="fs-normal-light ">{data.name}</span>
                </div>
                <div className="col fs-normal-light flex-grow-1 p-0 m-0">
                  {data.address}
                </div>
              </div>
            ))}
          </div>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={SYNOPSIS}
          label="Synopsis"
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadSynopsisStatus}
          setLoadBtnStatus={setLoadSynopsisStatus}
          scrutinyCount={scrutinyCount?.synopsis}
          enableEdit={isVersionPage ? false : isViewEditEnabled(SY, permissionState)}
          isVersionPage={isVersionPage}
          hasComment={synopsisListData?.some(data => data.reason) && (isAdmin || isClaimant)}


        >
          {loadSynopsisStatus ? (
            <div className="px-3 py-4">
              <div className="d-flex align-items-center h-100">
                <div
                  className="flex-shrink-0 p-2"
                  style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
                >
                  <p className="fs-normal-med text-center p-0 m-0">Date </p>
                </div>
                <div
                  className="flex-grow-1 p-2"
                  style={{ border: "0.5px solid #E5E5FF" }}
                >
                  <p className="fs-normal-med text-center p-0 m-0">Events</p>
                </div>
                <div
                  className="flex-shrink-0 p-2"
                  style={{ border: "0.5px solid #E5E5FF", flexBasis: "20%" }}
                >
                  <p className="fs-normal-med text-center p-0 m-0">Evidences</p>
                </div>
              </div>

              <div className="d-flex gap-3 flex-column">
                {synopsisListData?.map((data, key) => (
                  <div className="d-flex h-100 flex-column" key={key}>
                    <div className="d-flex">
                      <div
                        className="flex-shrink-0 d-flex justify-content-center align-items-center p-2"
                        style={{
                          border: "0.5px solid #E5E5FF",
                          flexBasis: "20%",
                        }}
                      >
                        {/* <p className="fs-normal-light text-center p-0 m-0">
                          {data.dateTime}
                        </p> */}
                        <p className="fs-normal-light text-center p-0 m-0">
                          {/* {formatDateTime(data.dates[0]??"",false)} */}
                          {((isUnderScrutinyReview &&
                            data?.hasChanges &&
                            !isAdmin) || showHighlighter) ? (
                            <HTMLDiffComponent oldContent={caseFileDetail.previousSynopsis[key]?.dateType === DateOption.NOT_APPLICABLE
                              ? "N/A"
                              : caseFileDetail.previousSynopsis[key]?.dates?.length
                                ? caseFileDetail.previousSynopsis[key]?.dates
                                  .map((date) =>
                                    data?.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                  )
                                  .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : ''}

                              newContent={data?.dateType === DateOption.NOT_APPLICABLE
                                ? "N/A"
                                : data?.dates?.length
                                  ? data?.dates
                                    .map((date) =>
                                      data?.dateType !== DateOption.ONLY_YEAR ? (
                                        formatDateTime(date, false)
                                      ) : (
                                        date
                                      )
                                    )
                                    .join(data?.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                  : ''} />
                          ) :
                            data?.dateType === DateOption.NOT_APPLICABLE
                              ? "N/A"
                              : data?.dates?.length
                                ? data?.dates
                                  .map((date) =>
                                    data?.dateType !== DateOption.ONLY_YEAR ? (
                                      formatDateTime(date, false)
                                    ) : (
                                      date
                                    )
                                  )
                                  .join(data?.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                                : ''}



                          {/* {data?.dateType === DateOption.NOT_APPLICABLE
                            ? "N/A"
                            : data?.dates?.length
                              ? data.dates
                                .map((date) =>
                                  data.dateType !== DateOption.ONLY_YEAR ? (
                                    formatDateTime(date, false)
                                  ) : (
                                    date
                                  )
                                )
                                .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                              : null} */}

                        </p>
                      </div>

                      <div
                        className="flex-grow-1 p-2"
                        style={{ border: "0.5px solid #E5E5FF" }}
                      >
                        <div className="fs-normal-light text-start p-0 m-0">
                          {((isUnderScrutinyReview &&
                            data?.hasChanges &&
                            !isAdmin) || showHighlighter) ? (
                            <>
                              <StringDifferenceHighlighter
                                original={
                                  (synopsisTab === 1
                                    ? caseFileResponseDetail
                                    : caseFileDetail
                                  )?.previousSynopsis[key]
                                    ?.eventDetail as string
                                }
                                modified={data?.eventDetail}
                              />
                            </>
                          ) : (
                            <>{data?.eventDetail}</>
                          )}
                        </div>
                        {isAcceptRejectEnabled(SY,
                          hasResponse
                            ? permissionStateResponse
                            : permissionState
                        ) &&
                          !isVersionPage &&
                          data.hasChanges && (
                            <>
                              <RejectApproveBtns
                                dataKey={SYNOPSIS}
                                index={key}
                              />
                            </>
                          )}
                      </div>
                      <div
                        className="flex-shrink-0 d-flex justify-content-start  align-items-start flex-column p-2"
                        style={{
                          border: "0.5px solid #E5E5FF",
                          flexBasis: "20%",
                        }}
                      >
                        {/* <div className="text-center w-100">
                          <p className="fs-normal-light text-center p-0 m-0">
                            {"DC"}
                          </p>
                       </div> */}
                        <div className="d-flex gap-2 align-items-start flex-wrap">
                          {
                            data.evidences?.map((evidence, evidenceIndex) => (
                              <p className="fs-normal-light p-0 m-0 custom-file-link" onClick={() => handleViewFile(evidence)} key={evidenceIndex}>
                                <AttachFileIcon fontSize="small" />
                                {evidence.actualFileName}
                              </p>
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="pb-4">
              <div className="d-flex gap-3 align-items-center px-3 py-2">
                <div
                  className="fs-normal-med text-start flex-shrink-0 p-0 m-0"
                  style={{ flexBasis: "20%" }}
                >
                  Date
                </div>
                <div className="fs-normal-med text-center flex-grow-1 p-0 m-0">
                  Events
                </div>
                <div
                  className="fs-normal-med text-end flex-shrink-0 p-0 m-0"
                  style={{ flexBasis: "10%" }}
                >
                  Evidences
                </div>
              </div>
              {synopsisListData?.map((data, key) => (
                <div
                  className={`d-flex gap-3 align-items-center ${key % 2 === 0 && "bg-light-blue2"
                    } px-3 py-2`}
                  key={key}
                >
                  <div
                    className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "20%" }}
                  >

                    {data.dateType === DateOption.NOT_APPLICABLE
                      ? "N/A"
                      : data?.dates?.length
                        ? data.dates
                          .map((date) =>
                            data.dateType !== DateOption.ONLY_YEAR ? (
                              formatDateTime(date, false)
                            ) : (
                              date
                            )
                          )
                          .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                        : null}


                  </div>
                  <div
                    className="fs-normal-light text-start flex-grow-1 p-0 m-0"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 0,
                    }}
                  >
                    {data.eventDetail}
                  </div>
                  <div
                    className="fs-normal-light text-end flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "10%" }}
                  >
                    {hasResponse ? "DR : " : "DC : "}{data.evidences.length}

                  </div>
                </div>
              ))}
            </div>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={DETAILED_FACTS}
          label="Detailed Facts"
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadFactsStatus}
          setLoadBtnStatus={setLoadFactsStatus}
          scrutinyCount={scrutinyCount?.detailedFacts}
          enableEdit={isVersionPage ? false : isViewEditEnabled(DF, permissionState)}
          isVersionPage={isVersionPage}
          hasComment={(caseFileDetail.detailedFacts?.reason && (isAdmin || isClaimant)) ? true : false}


        >
          {loadFactsStatus ? (
            <div className="p-2">
              {((isUnderScrutinyReview &&
                caseFileDetail.detailedFacts.hasChanges &&
                !isAdmin) || showHighlighter) ? (
                <HTMLDiffComponent oldContent={caseFileDetail?.previousDetailedFacts?.factsDetail ?? ''} newContent={detailedFacts?.factsDetail ?? ''} />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: caseFileDetail.detailedFacts.factsDetail,
                  }}
                ></div>
              )}

              <div className="p-2 mb-3">
                {!isVersionPage && isAcceptRejectEnabled(DF, permissionState) &&
                  caseFileDetail?.detailedFacts?.hasChanges && (
                    <>
                      <RejectApproveBtns
                        dataKey={DETAILED_FACTS}
                        index={0}
                      />
                    </>
                  )}
              </div>
            </div>
          ) : (
            <>
              {" "}
              <div className="pb-4">
                <div className="d-flex align-items-center px-3 py-2">
                  <div className="fs-normal-light  fst-italic  p-0 m-0">
                    Preview is not available click on "view more" to view
                    complete detailed facts.
                  </div>
                </div>
              </div>
            </>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={GROUNDS_REASONING}
          label="Grounds / Reasoning"
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadGroundsStatus}
          setLoadBtnStatus={setLoadGroundsStatus}
          scrutinyCount={scrutinyCount?.grounds}
          enableEdit={isVersionPage ? false : isViewEditEnabled(GR, permissionState)}
          isVersionPage={isVersionPage}
          hasComment={groundsData?.some(data => data.reason) && (isAdmin || isClaimant)}


        >
          {loadGroundsStatus ? (
            <div className="px-3 py-4">
              <div className="d-flex gap-3 flex-column">
                {groundsData?.map((data, key) => (
                  <div className="d-flex h-100" key={key}>
                    <div className="flex-shrink-0 d-flex p-2">{key + 1}</div>
                    <div className="flex-grow-1 p-2">
                      <p className="fs-normal-light text-start p-0 m-0">
                        {((isUnderScrutinyReview &&
                          data.hasChanges &&
                          !isAdmin) || showHighlighter) ? (
                          <StringDifferenceHighlighter
                            original={
                              caseFileDetail?.previousGrounds[key]
                                ?.groundsDetail as string
                              // caseFileDetail?.previousGrounds[key]
                              //   ?.groundsDetail
                            }
                            modified={data?.groundsDetail}
                          />
                        ) : (
                          <>{data.groundsDetail}</>
                        )}
                      </p>
                      {data.hasChanges &&
                        !isVersionPage &&
                        isAcceptRejectEnabled(GR,
                          hasResponse
                            ? permissionStateResponse
                            : permissionState
                        ) && (
                          <>
                            <RejectApproveBtns
                              dataKey={GROUNDS_REASONING}
                              index={key}
                            />
                          </>
                        )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="pb-4">
              {groundsData?.map((data, key) => (
                <div
                  className={`d-flex gap-5 align-items-center px-3 py-2`}
                  key={key}
                >
                  <div>{key + 1}</div>
                  <div
                    className="fs-normal-light flex-grow-1 p-0 m-0"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 0,
                    }}
                  >
                    {data.groundsDetail}
                  </div>
                </div>
              ))}
            </div>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={PRAYERS}
          label="Prayer"
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadPrayersStatus}
          setLoadBtnStatus={setLoadPrayersStatus}
          scrutinyCount={null}
          enableEdit={isVersionPage ? false : isViewEditEnabled(PR, permissionState)}

          isVersionPage={isVersionPage}


        >
          {loadPrayersStatus ? (
            <div className="px-3 py-4">
              {/* <h4 className="fs-sm-lg-semibold mb-3">Prayers</h4> */}
              <div className="row row-cols-2 g-5">
                {prayerData?.map((data: any, key: any) => (
                  <div className="col h-100" key={key}>
                    {data.prayerDetail ? (
                      <div className="d-flex gap-2">
                        <span className="fs-normal-light p-0 m-0">
                          {key + 1}.
                        </span>
                        <div className="d-flex gap-2 flex-column">
                          <span
                            className={`fs-normal-light ${prayersTab === 1 ? "text-grey" : ""
                              }`}
                          >
                            {data.prayerDetail}

                          </span>

                          <span
                            className={` fs-normal-light ${prayersTab === 1 ? "text-grey" : ""
                              }`}
                          >
                            Intensity of wants: {getIntensity(data.intensity)}
                          </span>
                          <div className="d-flex gap-2">
                            <ShowLabel label={data.status === 1 ? "Agreed" : data.status === 2 ? "Rejected" : data.status === 3 ? "Partial" : null}
                              status={data.status === 1 ? StatusType.SUCCESS : data.status === 2 ? StatusType.DANGER : data.status === 3 ? StatusType.WARNING : null}
                            />

                            {data.objection &&
                              (
                                <span className="  fs-normal-light text-grey">
                                  :  {data.objection}
                                </span>
                              )
                            }
                          </div>
                        </div>
                      </div>
                    ) : null}

                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="pb-4">
              {prayerData?.map((data, key: number) => (
                <div
                  className={`d-flex gap-3 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                    } px-3 py-2`}
                  key={key}
                >
                  <div
                    className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "5%" }}
                  >
                    {key + 1}
                  </div>
                  <div
                    className="d-flex gap-2 flex-column fs-normal-light text-start flex-grow-1 p-0 m-0"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 0,
                    }}
                  >
                    <span className="fs-normal-light">{data.prayerDetail}
                    </span>
                  </div>
                  <div
                    className="d-flex gap-2 flex-column fs-normal-med text-end flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "25%" }}
                  >
                    <span
                      className={`fs-normal-light${prayersTab === 1 ? "text-grey" : ""
                        }`}
                    >
                      Intensity of wants: {getIntensity(data.intensity)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </ViewMoreContainer>

        <ViewMoreContainer
          sectionId={EVIDENCES}
          label="Evidences"
          showLoadBtn={showLoadBtn}
          loadBtnStatus={loadEvidencesStatus}
          setLoadBtnStatus={setLoadEvidencesStatus}
          scrutinyCount={scrutinyCount?.evidences}

          isVersionPage={isVersionPage}
          hasComment={synopsisListData?.some(data => data.reason != null) && (isAdmin || isClaimant)}

        >
          {loadEvidencesStatus ? (
            <>
              {synopsisListData?.map((data, synopsisIndex) => (

                <div className="mb-3 p-2" key={synopsisIndex}>
                  <div className="fs-normal-light text-start "
                    style={{ flexBasis: "10%" }}>

                    {data.dateType === DateOption.NOT_APPLICABLE
                      ? "N/A"
                      : data?.dates?.length
                        ? data.dates
                          .map((date) =>
                            data.dateType !== DateOption.ONLY_YEAR ? (
                              formatDateTime(date, false)
                            ) : (
                              date
                            )
                          )
                          .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                        : null}



                  </div>

                  {data.evidences.map((evidence, evidenceIndex) => (
                    <div key={evidenceIndex} >
                      <span className={`fs-normal-light `}>
                        <AttachFileIcon fontSize="small" />
                        <span
                          className={`${evidence.tempReUpload ? "text-success" : ""
                            } cursor-pointer`}
                          onClick={() => {
                            handleViewFile(evidence)
                          }}
                        >
                          {evidence.actualFileName
                            ? evidence.actualFileName
                            : "- - -"}


                        </span>


                        {evidence.reUpload === 1 && caseFile.status === SENT_FOR_RESCRUTINY && isAdmin ? (
                          <Tooltip title="Re-uploaded file !">
                            <CloudSyncIcon color="error" fontSize="small" />

                          </Tooltip>
                        ) : null
                        }
                        {evidence.tempReUpload && (
                          <CheckCircleIcon
                            className="ms-2 text-success "
                            fontSize="small"
                          />
                        )}
                      </span>
                      <div className="text-grey ms-4">
                        {evidence.description}
                      </div>

                      {(isAcceptRejectEnabled(CE,
                        hasResponse
                          ? permissionStateResponse
                          : permissionState
                      ) && !isVersionPage) &&
                        evidence.reUpload === 1 && (
                          <>
                            <div className="px-3 my-3">
                              <div className={`w-100 rounded p-2 bg-light-red`}>
                                <div className={`fs-normal-semibold `}>
                                  Remarks By TruPact
                                </div>
                                {evidence.reUpload === 1 && (
                                  <div className="fs-normal-med">
                                    {evidence?.remark}
                                  </div>
                                )}
                              </div>

                              <div className="w-75 mx-auto">
                                <div className="mt-2">Reupload evidences</div>




                                <FileUploadLayout
                                  fileToView={
                                    evidence.tempReUpload
                                      ? evidence
                                      : null
                                  }
                                  addFile={(file: CaseAgreement | Evidence) => {
                                    reuploadEvidence(file, synopsisIndex, evidenceIndex)
                                  }}
                                  isLoading={isUploading}
                                  fileUploadApiType={FileUploadApiType.CASE}
                                  uploadArguments={[
                                    caseFile.status === NEW_CASE ? DRAFT : caseFile.status,
                                    caseFile.uniqueId,
                                  ]}
                                  removeFile={() => {
                                    removeFile(synopsisIndex, evidenceIndex);
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}

                      {(activeEvidenceIndex !== evidenceIndex ||
                        activeSynopsisIndex !== synopsisIndex) &&
                        isViewAddRemarksEnabled(CE,
                          hasResponse
                            ? permissionStateResponse
                            : permissionState
                        ) &&
                        isAdmin && (
                          <>
                            {((!hasResponse ||
                              (hasResponse && evidenceTab === 1))
                              && !isVersionComparision)
                              && (
                                <div className="text-center mb-2 ">
                                  <Button
                                    className="rounded-2"
                                    variant="text"
                                    sx={{ textTransform: "none" }}
                                    onClick={() => {
                                      setActiveEvidenceIndex(evidenceIndex);
                                      setActiveSynopsisIndex(synopsisIndex);
                                    }}
                                  >
                                    Add Remark
                                  </Button>
                                </div>
                              )}
                          </>
                        )}

                      {activeEvidenceIndex === evidenceIndex &&
                        activeSynopsisIndex === synopsisIndex &&
                        isAdmin && (
                          <>
                            {(!hasResponse ||
                              (hasResponse && evidenceTab === 1)) && (
                                <div className="p-4">
                                  <TextField
                                    className="w-100"
                                    name="remark"
                                    label="Add remark"
                                    placeholder="Add your remarks"
                                    multiline
                                    value={evidence.remark}
                                    onChange={(event) => {
                                      handelEvidenceChange(
                                        event.target.name,
                                        event.target.value,
                                        synopsisIndex,
                                        evidenceIndex
                                      );
                                    }}
                                    rows={3}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        name="reUpload"
                                        checked={
                                          evidence.reUpload === 1 ? true : false
                                        }
                                        onChange={(event) => {
                                          handelEvidenceChange(
                                            event.target.name,
                                            event.target.checked ? 1 : 0,
                                            synopsisIndex,
                                            evidenceIndex
                                          );
                                        }}
                                      />
                                    }
                                    label="Reupload attachment"
                                  />
                                </div>
                              )}
                          </>
                        )}
                    </div>
                  ))}
                </div>
              ))}
            </>
          ) : (
            <>
              {(evidenceTab === 1
                ? caseFileResponseDetail
                : caseFileDetail
              )?.synopsis.map((data, key) => (
         

                <div
                  className={`d-flex gap-3 mb-3 align-items-center ${key % 2 !== 0 && "bg-light-blue2"
                    } px-3 py-2`}
                  key={key}
                >
                  <div
                    className="fs-normal-light text-start flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "20%" }}
                  >

                    {data.dateType === DateOption.NOT_APPLICABLE
                      ? "N/A"
                      : data?.dates?.length
                        ? data.dates
                          .map((date) =>
                            data.dateType !== DateOption.ONLY_YEAR ? (
                              formatDateTime(date, false)
                            ) : (
                              date
                            )
                          )
                          .join(data.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                        : null}


                  </div>
                  <div
                    className="fs-normal-light text-start flex-grow-1 p-0 m-0"
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: 0,
                    }}
                  >
                    {data.eventDetail}
                  </div>
                  <div
                    className="fs-normal-light text-end flex-shrink-0 p-0 m-0"
                    style={{ flexBasis: "10%" }}
                  >
                     DC : {data.evidences.length}

                  </div>
                </div>
              ))}
            </>
          )}
        </ViewMoreContainer>
      </div>


      <MediaLayout
        title={fileToOpen?.actualFileName ?? "Media Preview"}
        open={openFile}
        handleClose={() => {
          setOpenFile(false)
        }}
      >
        <ViewMedia isPdf={isFilePdf} url={fileUrl ?? ""} />     </MediaLayout>
    </>
  );
};
export default ViewCaseContent;
