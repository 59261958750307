export const NO_PERMISSION = 0;
export const PERMISSION_ALLOWED = 1;

export const VIEW = 2;
export const VIEW_EDIT = 3;
export const VIEW_ADD_REMARKS = 4;
export const VIEW_ACCEPT_REJECT = 5;
export const VIEW_RESOLVE = 6;

export const CM = "CM";
export const CD = "CD";
export const RD = "RD";
export const MR = "MD";
export const RT = "RT";
export const SY = "SY";
export const SO = "SO";
export const RP = "RP"; //?RESPONDENT_PRAYER
export const DF = "DF";
export const GR = "GR";
export const PR = "PR";
export const CE = "CE";
export const RC = "RC";
export const CS = "CS";
