import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserService from "../../network/UserService";

export const orgListThunk = createAsyncThunk(
    "/organization-list",
    async (_params: any, thunkApi) =>
      await UserService.apiOrganizationList(_params)
);

export const orgDetailsThunk = createAsyncThunk(
  "/organization-details",
  async (uniqueId: string, thunkApi) =>
    await UserService.apiOrgDetails(uniqueId)
);

export const orgKycVerifyThunk = createAsyncThunk(
  "/org-kyc-verify",
  async (body: any, thunkApi) => {
    return await UserService.apiOrgKycVerify(body);
  }
);

export const orgListSlice = createSlice({
    name: "orgList",
    initialState: {
      organizationList: [],
      isLoading: false,
      errorMessage: "",
      recordCount: 0,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(orgListThunk.pending, (state, action) => {
          state.isLoading = true;
          state.errorMessage = "";
        })
        .addCase(orgListThunk.fulfilled, (state, action) => {
          const { recordCount, data } = action.payload as any;
          
          state.organizationList = data;
          state.isLoading = false;
          state.errorMessage = "";
          state.recordCount = recordCount;
        })
        .addCase(orgListThunk.rejected, (state, action) => {
          state.errorMessage = action.error.message ?? "";
          state.isLoading = false;
        });
    },
});

const initOrgDetail = {
  organization: {},
  kycWrapper: {
    kyc: {},
    kycData: [
      {
        uniqueId: "",
        kycType: "",
        fieldValue: "",
        attachment: "",
        remark: "",
        status: "",
      },
    ],
  },
}
export const orgDetailsSlice = createSlice({
  name: "user-details",
  initialState: {
    organizationDetails: initOrgDetail,
    isLoading: false
  },
  reducers: {
    editKycDataAdmin: (state, action) => {
      const { index, key, value } = action.payload;

      state.organizationDetails.kycWrapper.kycData[index] = {
        ...state.organizationDetails?.kycWrapper?.kycData[index],
        [key]: value,
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(orgDetailsThunk.pending, (state, action) => {
        state.organizationDetails = initOrgDetail;
        state.isLoading = true;
      })
      .addCase(orgDetailsThunk.fulfilled, (state, action) => {
        const data = action.payload as any;
        state.organizationDetails = data;
        state.isLoading = false;
      })
      .addCase(orgDetailsThunk.rejected, (state, action) => {
        state.organizationDetails = initOrgDetail;
      })
  },
});

export const { editKycDataAdmin } = orgDetailsSlice.actions;
