import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  AccordionDetails,
} from "@mui/material";
import React, { useEffect } from "react";

import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, store } from "../../../../redux/store";


import SynopsisRow from "./SynopsisRow";
import { addSynopsis, deleteSynopsis, setSynopsisExpanded } from "../../../../redux/slice/caseResponseSlice";
import { useSnackbar } from "../../../../context/SnackbarContext";

import { formatDateTime } from "../../../../util/stringUtils";
import { DateOption } from "../../../../redux/slice/components/initCaseSliceState";

const SynopsisList = () => {
  const { synopsisExpanded } = store.getState().caseResponse;
  const { isAdmin } = store.getState().case;

  

  const dispatch: AppDispatch = useDispatch();

  const { synopsis } = useSelector(
    (state: RootState) => state.caseResponse
  ).caseFileResponseDetail;

  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        dispatch(setSynopsisExpanded(synopsisExpanded === panel ? -1 : panel));
      }
    };
  const { showSnackbar } = useSnackbar()
  const handleDeleteSynopsis = (index: number) => {
    dispatch(deleteSynopsis(index));
    if (index !== 0) {
      setSynopsisExpanded(synopsis?.length||0);
    }
    showSnackbar("Deleted synopsis")


  };
  // useEffect(() => {
  //   if (!synopsis.length) {
  //     dispatch(addSynopsis());

  //   }
  // }, [])
  return (
    <div className="d-flex flex-column  w-100 overflow-auto">
      {synopsis?.map((synopsis: any, i: number) => (
        <Accordion
          key={i}
          className="border-0"
          expanded={synopsisExpanded === i}
          onChange={handleChangeAccordion(i)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="d-flex w-100 gap-3 justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <img src={DotIcon} alt="dot" width={24} height={24} />
                <Box
                  className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                  width={28.21}
                  height={28.21}
                >
                  {i + 1}
                </Box>

                {!(synopsisExpanded === i) && (
                  <>
                    <div>  
                      {synopsis.dateType === DateOption.NOT_APPLICABLE
                        ? "N/A"
                        : synopsis?.dates?.length
                          ? synopsis.dates
                            .map((date:any) =>
                              synopsis.dateType !== DateOption.ONLY_YEAR ? (
                                formatDateTime(date, false)
                              ) : (
                                date
                              )
                            )
                            .join(synopsis.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                          : null}</div>
                   

                    {synopsis.eventDetail && (
                      <Box className="text-grey text-truncate" maxWidth={400}>
                        {synopsis.eventDetail}
                      </Box>
                    )}
                    <IconButton className="p-0">
                      <img src={PenIcon} alt="pen" width={24} height={24} />
                    </IconButton>
                  </>
                )}
              </div>

              {synopsisExpanded === i && (
                <div className="fs-med-slg-medium  p-0 m-0">Synopsis</div>
              )}
              {!isAdmin?(
                <IconButton
                  className="p-0"
                  onClick={() => handleDeleteSynopsis(i)}
                >
                  <Box
                    className={`${synopsisExpanded === i ? "bg-light-red " : " bg-light-blue1"
                      } p-3 rounded-circle d-flex justify-content-center align-items-center`}
                    width={40}
                    height={40}
                  >
                    <DeleteOutlinedIcon
                      className={`${synopsisExpanded === i ? "text-danger " : ""}`}
                      fontSize="medium"
                    />

                  </Box>
                </IconButton>
              ):<div></div>}
            
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex h-100 flex-column">
              <SynopsisRow index={i} synopsis={synopsis} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SynopsisList;
