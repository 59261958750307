import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { caseResponseThunk, setResponse } from "./caseResponseSlice";

import {
  CASE_FILE_PREVIEW,
  CLAIMANT_DETAILS,
  METHOD_OF_RESOLUTION,
} from "../../constants/case-state";
import NetworkService from "../../network/NetworkService";
import {
  CT_INDIVIDUAL,
  DRAFT,
  NON_DRAFT,
  RESPONDENT_TYPE_INDIVIDUAL,
  SCRUTINY_REVIEW,
  SENT_TO_RESPONDENT,
} from "../../constants/case-constants";
import { Claimant, Respondent } from "../../model/fileCase";
import { Evidence, SynopsisModel } from "../../model/caseDocument/synopsis";
import { Grounds } from "../../model/caseDocument/grounds";

import { caseSliceValidation } from "./components/validation";
import { removeData } from "./components/removeData";
import { AgreementStatus, DateOption, initCaseSliceState } from "./components/initCaseSliceState";
import { checkForChanges } from "../../util/case/checkForChanges";
import { checkForResponse } from "../../util/case/checkForResponse";
import { checkForChangesResponse } from "../../util/case/checkForChangesResponse";
import { convertPermissionString } from "../../util/stringUtils";
import FileService from "../../network/FileService";

export const saveCaseFileThunk = createAsyncThunk(
  "/save-case-file",
  //TODO : use type for body
  async (body: any, thunkApi) => {
    const tempBody = removeData({ ...body });
    await NetworkService.saveCaseFile(tempBody);
  }
);

export const caseDetailThunk = createAsyncThunk(
  "/case-detail",
  async (
    {
      draftStatus,
      uniqueId,
      queryParams = null,
    }: {
      draftStatus: number;
      uniqueId: string;
      queryParams?: any;
    },
    thunkAPI
  ) =>
    await NetworkService.getCaseDetail({
      draftStatus: draftStatus === 0 ? DRAFT : NON_DRAFT,
      uniqueId: uniqueId,
      queryParams: queryParams,
    })
);

export const initiateCaseThunk = createAsyncThunk(
  "/file-case",
  async (_, thunkApi) =>
    await NetworkService.saveCaseFile({
      caseFile: { status: DRAFT },
      caseFileDetail: {},
      claimantList: [],
      respondentList: [],
    })
);

export const caseNeutralListThunk = createAsyncThunk(
  "/neutral-list",
  async (data: any, thunkApi) => await NetworkService.getNeutralList(data)
);

export const chooseNeutralThunk = createAsyncThunk(
  "/choose-neutral",
  async (data: any, thunkApi) => await NetworkService.chooseNeutralApi(data)
);
export const finalizeNeutralThunk = createAsyncThunk(
  "/finalize-neutral",
  async (data: any, thunkApi) => await NetworkService.finalizeNeutralApi(data)
);
export const caseNeutralHistoryThunk = createAsyncThunk(
  "/case-neutral-history",
  async (uniqueId: string, thunkApi) =>
    await NetworkService.getCaseNeutralHistory(uniqueId)
);
export const startResolutionPhase = createAsyncThunk(
  "/start-resolution-phase",
  async (uniqueId: string, thunkApi) =>
    await NetworkService.startResolutionPhase(uniqueId)
);

export const verifyResolutionAgreement = createAsyncThunk(
  "/verify-agreement",
  //TODO : use type for body
  
  async (body: any, thunkApi) => {
    
    await NetworkService.verifyResolutionAgreement(body);
  }
);
export const updateResolutionAgreement = createAsyncThunk(
  "/update-agreement",
  //TODO : use type for body
  async (body: any, thunkApi) => {
    await NetworkService.updateResolutionAgreement(body);
  }
);


export const reUploadCaseAgreement = createAsyncThunk(
  "/re-upload-caseAgreement",
  //TODO : use type for body
  async (body: any, thunkApi) => {
    const {reqBody, uniqueId} = body
    await FileService.reUploadAgreement(reqBody, uniqueId);
  }
);



const initState = initCaseSliceState;

export const caseSlice = createSlice({
  name: "caseDetails",
  initialState: { ...initState },
  reducers: {
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    goToNextSection: (state) => {
      if (state.currentSection !== CASE_FILE_PREVIEW) {
        state.currentSection = ++state.currentSection;
      }
    },
    goToNextPage: (state) => {
      state = {
        ...state,
        currentPage: ++state.currentPage,
        currentSection: ++state.currentSection,
      };
    },
    goToPrevPage: (state) => {
      state = {
        ...state,
        currentPage: --state.currentPage,
        currentSection: --state.currentSection,
      };
    },
    goToPrevSection: (state) => {
      if (state.currentSection !== METHOD_OF_RESOLUTION) {
        state.currentSection = --state.currentSection;
      }
    },
    setClaimantExpanded: (state, action) => {
      state.claimantExpanded = action.payload;
    },
    setRespondentExpanded: (state, action) => {
      state.respondentExpanded = action.payload;
    },
    setSynopsisExpanded: (state, action) => {
      state.synopsisExpanded = action.payload;
    },
    setReasoningExpanded: (state, action) => {
      state.groundsExpanded = action.payload;
    },
    setPrayersExpanded: (state, action) => {
      state.prayersExpanded = action.payload;
    },

    setCaseFile: (state, { payload }) => {
      state.caseFile = { ...payload };
    },
    updateCaseFile: (state, action) => {
      //to remove error message when updating case file
      const { key } = action.payload;
      if (state.hasResponse && state.response.caseFileResponse) {
        state.response.caseFileResponse = {
          ...state.response.caseFileResponse,
          errors: { ...state.caseFile.errors, [key]: "" },
        };
      } else {
        state.caseFile = {
          ...state.caseFile,
          errors: { ...state.caseFile.errors, [key]: "" },
        };
      }
    },
    setCaseRecommendation: (state, action) => {
      state.caseRecommendation = action.payload;
    },
    setState: (state, action) => {
      return { ...action.payload };
    },

    setCaseDateSlice: (state, action) => {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    },
    addClaimant: (state) => {
      const claimant = {
        claimantType: CT_INDIVIDUAL /*INDIVIDUAL or ORGANIZATION */,
        name: "",
        orgRepresentative: "",
        designation: "",
        authorizationLetter: null,
        eAadhaar: null,
        mobileNo: "",
        email: "",
        address: "",
        isPrimary: state.claimantList?.length === 0 ? 1 : 0,
        errors: {
          claimantType: "",
          name: "",
          orgRepresentative: "",
          designation: "",
          authorizationLetter: "",
          eAadhaar: "",
          mobileNo: "",
          email: "",
          address: "",
        },
      };
      state.claimantList.push(claimant as Claimant);
      state.claimantExpanded = state.claimantList.length - 1;
    },
    addRespondent: (state) => {
      const respondent = {
        respondentType:
          RESPONDENT_TYPE_INDIVIDUAL /*INDIVIDUAL or ORGANIZATION */,
        name: "",
        address: "",
        orgRepresentative: "",
        email: "",
        mobileNo: "",
        authorizationLetter: "",
        eAadhaar: "",
      } as Respondent;
      state.respondentList.push(respondent);
      state.respondentExpanded = state.respondentList.length - 1;
    },
    addSynopsis: (state) => {
      let tempData = state;
      const synopsis = {
        dateType:DateOption.SINGLE_DATE,
        dates: [] as any[],
        eventDetail: "",
        evidences: [] as Evidence[],
      } as SynopsisModel;
      if (state.isRespondent) {
        if (state.response.caseFileResponseDetail)
          tempData = {
            ...state,
            response: {
              ...state.response,
              caseFileResponseDetail: {
                ...state.response.caseFileResponseDetail,
                synopsis: [
                  ...state.response.caseFileResponseDetail.synopsis,
                  { ...synopsis },
                ],
              },
            },
          };
        if (tempData.response.caseFileResponseDetail)
          tempData.synopsisExpanded =
            tempData.response.caseFileResponseDetail.synopsis.length - 1;

        return tempData;
      } else {
        tempData = {
          ...state,
          caseFileDetail: {
            ...state.caseFileDetail,
            synopsis: [...state.caseFileDetail.synopsis, { ...synopsis }],
          },
        };
        tempData.synopsisExpanded = tempData.caseFileDetail.synopsis.length - 1;

        return tempData;
      }
    },
    addSynopsisEvidence: (state, action) => {
      const { index, ...data } = action.payload;
      const evidence: Evidence = data;
      if (state.isRespondent && state.response.caseFileResponseDetail) {
        const updatedSynopsis =
          state.response.caseFileResponseDetail.synopsis.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                evidences: [...item.evidences, evidence],
              };
            }
            return item;
          });

        return {
          ...state,
          response: {
            ...state.response,
            caseFileResponseDetail: {
              ...state.response.caseFileResponseDetail,
              synopsis: updatedSynopsis,
            },
          },
        };
      } else {
        const updatedSynopsis = state.caseFileDetail.synopsis.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              evidences: [...item.evidences, evidence],
            };
          }
          return item;
        });

        return {
          ...state,
          caseFileDetail: {
            ...state.caseFileDetail,
            synopsis: updatedSynopsis,
          },
        };
      }
    },
    reUploadSynopsisEvidence: (state, action) => {
      const {
        synopsisIndex,
        evidenceIndex,
        tempReUpload,
        removeFile,
        ...data
      } = action.payload;
      const evidence = data; // Corrected the assignment of evidence

      let updatedSynopsis = [];
      // Deleted evidence name is added to the deleted list
      let deletedFileName = "";
      if (state.isRespondent && state.response.caseFileResponseDetail) {
        deletedFileName =
          state.response.caseFileResponseDetail.synopsis[synopsisIndex]
            .evidences[evidenceIndex].uploadedFileName;
        updatedSynopsis = state.response.caseFileResponseDetail.synopsis.map(
          (item, i) => {
            if (i === synopsisIndex) {
              const updatedEvidences = item.evidences.map((val, index) => {
                if (index === evidenceIndex) {
                  return {
                    ...val,
                    tempReUpload: tempReUpload,
                    ...evidence,
                  };
                }
                return val;
              });

              return {
                ...item,
                evidences: updatedEvidences,
              };
            }
            return item;
          }
        );
      } else {
        deletedFileName =
          state.caseFileDetail.synopsis[synopsisIndex].evidences[evidenceIndex]
            .uploadedFileName;
        updatedSynopsis = state.caseFileDetail.synopsis.map((item, i) => {
          if (i === synopsisIndex) {
            const updatedEvidences = item.evidences.map((val, index) => {
              if (index === evidenceIndex) {
                return {
                  ...val,
                  tempReUpload: tempReUpload,
                  ...evidence,
                };
              }
              return val;
            });

            return {
              ...item,
              evidences: updatedEvidences,
            };
          }
          return item;
        });
      }

      let updatedState = state;

      if (state.isRespondent && state.response.caseFileResponseDetail) {
        updatedState = {
          ...state,
          response: {
            ...state.response,
            caseFileResponseDetail: {
              ...state.response.caseFileResponseDetail,
              synopsis: updatedSynopsis,
            },
          },
          deletedFiles: {
            ...state.deletedFiles,
            deletedFiles: [...state.deletedFiles, deletedFileName],
          },
        };
      } else {
        updatedState = {
          ...state,
          caseFileDetail: {
            ...state.caseFileDetail,
            synopsis: updatedSynopsis,
          },
          deletedFiles: [...state.deletedFiles, deletedFileName],
        };
      }

      return {
        ...updatedState,
        scrutinyCount: {
          ...state.scrutinyCount,
          evidences:
            removeFile === false
              ? state.scrutinyCount.evidences !== null
                ? state.scrutinyCount.evidences - 1
                : state.scrutinyCount.evidences
              : state.scrutinyCount.evidences !== null
              ? state.scrutinyCount.evidences + 1
              : state.scrutinyCount.evidences,
        },
      };
    },
    addEvidenceDocName: (state) => {
      let currentId = 1;

      return {
        ...state,
        caseFileDetail: {
          ...state.caseFileDetail,
          synopsis: state.caseFileDetail?.synopsis?.map((synopsis) => {
            const updatedEvidences = synopsis?.evidences?.map((doc) => {
              const updatedDoc = { ...doc, docName: `Doc ${currentId}` };
              currentId++;
              return updatedDoc;
            });

            return { ...synopsis, evidences: updatedEvidences };
          }),
        },
      };
    },

    addGrounds: (state) => {
      const grounds = {} as Grounds;

      state.caseFileDetail.grounds.push(grounds);
      state.groundsExpanded = state.caseFileDetail.grounds.length - 1;
    },
    addPrayers: (state) => {
      const prayer = {} as any;

      state.caseFileDetail.prayers?.push(prayer);
      if (state.caseFileDetail.prayers) {
        state.prayersExpanded = state.caseFileDetail.prayers.length - 1;
      }
    },

    editClaimant: (state, action) => {
      const { index, key, value } = action.payload;
      let tempCase = state;
      tempCase.claimantList[index] = {
        ...state.claimantList[index],
        [key]: value,
      };

      state = tempCase;
    },
    updateClaimant: (state, action) => {
      const { index } = action.payload;
      let tempCase = state;
      tempCase.claimantList[index] = {
        ...state.claimantList[index],
        errors: {
          claimantType: "",
          name: "",
          orgRepresentative: "",
          designation: "",
          authorizationLetter: "",
          eAadhaar: "",
          mobileNo: "",
          email: "",
          address: "",
        },
      };
      state = tempCase;
    },

    editPrimaryClaimant: (state, action) => {
      const index = action.payload;

      state.claimantList = state.claimantList.map((item, i) => ({
        ...item,
        isPrimary: i === index ? (item.isPrimary ? 0 : 1) : 0,
      }));

      const noneIsPrimary = state.claimantList.every(
        (item) => item.isPrimary === 0
      );
      if (noneIsPrimary && state.claimantList.length > 0) {
        state.claimantList[0].isPrimary = 1;
      }
    },

    editRespondent: (state, action) => {
      const { index, key, value } = action.payload;
      state.respondentList[index] = {
        ...state.respondentList[index],
        [key]: value,
      };
    },
    updateRespondent: (state, action) => {
      const { index } = action.payload;

      state.respondentList[index] = {
        ...state.respondentList[index],
        errors: {
          respondentType: "",
          name: "",
          orgRepresentative: "",
          designation: "",
          authorizationLetter: "",
          eAadhaar: "",
          mobileNo: "",
          email: "",
          address: "",
        },
      };
    },

    editSynopsis: (state, action) => {
      const { index, key, value } = action.payload;
      let tempData = null;

      if (
        (state.isRespondent || state.hasResponse) &&
        state.response.caseFileResponseDetail
      ) {
        tempData = {
          ...state,
          response: {
            ...state.response,

            caseFileResponseDetail: {
              ...state.response.caseFileResponseDetail,
              synopsis: state.response.caseFileResponseDetail.synopsis.map(
                (item, idx) =>
                  idx === index ? { ...item, [key]: value } : item
              ),
            },
          },
          hasResponse: true,
        };
      } else {
        tempData = {
          ...state,
          caseFileDetail: {
            ...state.caseFileDetail,
            synopsis: state.caseFileDetail.synopsis.map((item, idx) =>
              idx === index ? { ...item, [key]: value } : item
            ),
          },
        };
      }
      return { ...tempData };
    },

    updateSynopsis: (state, action) => {
      const { index, key } = action.payload;
      if (state.isRespondent && state.response.caseFileResponseDetail) {
        state.response.caseFileResponseDetail.synopsis[index] = {
          ...state.response.caseFileResponseDetail.synopsis[index],
          errors: {
            ...state.response.caseFileResponseDetail?.synopsis[index]?.errors,
            [key]: "",
          },
        };
      } else {
        state.caseFileDetail.synopsis[index] = {
          ...state.caseFileDetail.synopsis[index],
          errors: {
            ...state.caseFileDetail.synopsis[index]?.errors,
            [key]: "",
          },
        };
      }
    },
    editSynopsisEvidence: (state, action) => {
      const { synopsysIndex, evidenceIndex, key, value } = action.payload;

      state.caseFileDetail.synopsis[synopsysIndex].evidences[evidenceIndex] = {
        ...state.caseFileDetail.synopsis[synopsysIndex].evidences[
          evidenceIndex
        ],
        [key]: value,
      };
    },
    updateSynopsisEvidence: (state, action) => {
      const { synopsysIndex, evidenceIndex } = action.payload;

      if (state.isRespondent && state.response.caseFileResponseDetail) {
        state.response.caseFileResponseDetail.synopsis[synopsysIndex].evidences[
          evidenceIndex
        ] = {
          ...state.response.caseFileResponseDetail.synopsis[synopsysIndex]
            .evidences[evidenceIndex],
          error: "",
        };
      } else {
        state.caseFileDetail.synopsis[synopsysIndex].evidences[evidenceIndex] =
          {
            ...state.caseFileDetail.synopsis[synopsysIndex].evidences[
              evidenceIndex
            ],
            error: "",
          };
      }
    },
    editGrounds: (state, action) => {
      const { index, key, value } = action.payload;

      state.caseFileDetail.grounds[index] = {
        ...state.caseFileDetail.grounds[index],
        [key]: value,
      };
    },
    updateGrounds: (state, action) => {
      const { index } = action.payload;

      state.caseFileDetail.grounds[index] = {
        ...state.caseFileDetail?.grounds[index],
        errors: "",
      };
    },

    editPrayers: (state, action) => {
      const { index, key, value } = action.payload;

      if (state.caseFileDetail.prayers)
        state.caseFileDetail.prayers[index] = {
          ...state.caseFileDetail.prayers[index],
          [key]: value,
        };
    },

    deleteClaimant: (state, action) => {
      state.claimantList.splice(action.payload, 1);

      // state.claimantExpanded = state.claimantList.length - 1;
    },

    deleteRespondent: (state, action) => {
      state.respondentList.splice(action.payload, 1);
    },
    deleteSynopsis: (state, action) => {
      if (state.isRespondent && state.response.caseFileResponseDetail) {
        state.response.caseFileResponseDetail.synopsis.splice(
          action.payload,
          1
        );
      } else {
        state.caseFileDetail.synopsis.splice(action.payload, 1);
      }
    },
    deleteSynopsisEvidence: (state, action) => {
      const { index, i } = action.payload;

      if (state.isRespondent && state.response.caseFileResponseDetail) {
        const deletedFileName =
          state.response.caseFileResponseDetail.synopsis[index].evidences[i]
            .uploadedFileName;
        if (deletedFileName) {
          state.response.caseFileResponseDetail.synopsis[
            index
          ].evidences.splice(i, 1);
        }
        state.deletedFiles.push(deletedFileName);
      } else {
        const deletedFileName =
          state.caseFileDetail.synopsis[index].evidences[i].uploadedFileName;
        if (deletedFileName) {
          state.caseFileDetail.synopsis[index].evidences.splice(i, 1);
        }
        state.deletedFiles.push(deletedFileName);
      }
    },
    deleteGrounds: (state, action) => {
      let tempState = { ...state };

      if (state.isRespondent && tempState.response.caseFileResponseDetail) {
        tempState.response.caseFileResponseDetail.grounds.splice(
          action.payload,
          1
        );
      } else {
        tempState.caseFileDetail.grounds.splice(action.payload, 1);
      }

      state = { ...tempState };
    },
    deletePrayers: (state, action) => {
      state.caseFileDetail.prayers?.splice(action.payload, 1);
    },

    setRespondentList: (state, action) => {
      state.respondentList = action.payload;
    },
    setSynopsis: (state, action) => {
      state.caseFileDetail.synopsis = action.payload;
    },
    setDetailedFacts: (state, action) => {
      if (
        (state.isRespondent || state.hasResponse) &&
        state.response.caseFileResponseDetail
      ) {
        state.response.caseFileResponseDetail.detailedFacts = action.payload;
        state.hasResponse = true;
      } else {
        state.caseFileDetail.detailedFacts = action.payload;
      }
    },
    setGrounds: (state, action) => {
      state.caseFileDetail.grounds = action.payload;
    },
    setPrayers: (state, action) => {
      state.caseFileDetail.prayers = action.payload;
    },

    setScrutinyCount: (state, action) => {
      state.scrutinyCount = action.payload;
    },

    setArbitratorType: (state, { payload }) => {
      state.caseFile.resolutionType = payload;
    },



    setStateValue: (state, action) => {

      const {key, value} = action.payload
      
      
      return {...state, [key]: value}
    },

    validate: (state, action) => {
      const { status } = action.payload;

      const data = caseSliceValidation(state, status);

      return { ...data };
    },

    reset: () => {
      return { ...initState };
    },

    editDetailedFacts: (state, action) => {
      const { key, value } = action.payload;

      state.caseFileDetail.detailedFacts = {
        ...state.caseFileDetail.detailedFacts,
        [key]: value,
      };
    },

    // addOrUpdateCaseAgreementDoc: (state, action) => {
    //   const caseAgreement = action.payload;

    //   const updatedCaseAgreementList = state.caseFile.caseAgreementList?.map(agreement => {
    //     if(agreement.agreementType === caseAgreement?.agreementType){
    //       return {
    //         ...agreement,
    //         ...caseAgreement
    //       }
    //     } else {
    //       return agreement
    //     }
    //   })

    //   state.caseFile.caseAgreementList = updateCaseAgreementList;
    // },
    updateCaseAgreementList: (state, action) => {
      const updatedCaseAgreementList = action.payload;

     const tempCaseFile = {
        ...state.caseFile,
        caseAgreementList: updatedCaseAgreementList
      };

      return {
        ...state,
        caseFile: tempCaseFile
      }
    },
    removeCaseAgreementDoc: (state, action) => {
      const agreementType = action.payload;
      
      state.caseFile.caseAgreementList = state.caseFile.caseAgreementList
        ? state.caseFile.caseAgreementList.filter(agreement => agreement.agreementType !== agreementType)
        : null;
    }     
  },
  extraReducers(builder) {
    builder
      // Case detail thunk
      .addCase(caseDetailThunk.pending, () => {
        return { ...initState, isLoading: true };
      })
      .addCase(caseDetailThunk.fulfilled, (state, action) => {
        const data = action.payload as any;

        let { tempData } = checkForResponse(data);

        const { permissionState } = data;

        const isAdvocate = data.isAdvocate !== 0;
        const isClaimantAdvocate = data.isAdvocate === 1;
        const isRespondentAdvocate = data.isAdvocate === 2;

        let permissionClaimant = permissionState
          ? convertPermissionString(permissionState)
          : {};

        if (tempData.caseFile.status === SCRUTINY_REVIEW) {
          tempData = checkForChanges(tempData);
        }

        const selectedResponseId = data.caseResponseList?.length
          ? data.caseResponseList.find(
              (caseResponse: any) => caseResponse.responseParty === 1
            )?.uniqueId ||
            (data.caseResponseList.length > 0
              ? data.caseResponseList[0].uniqueId
              : null)
          : null;

        const tempBody = {
          ...tempData,
          hasReupload: false,
          caseFile: {
            ...data.caseFile,
            showReuploadDialog: (data.caseFile.status ===SCRUTINY_REVIEW || isClaimantAdvocate)?
              (data.claimant && !data.isLegalHandover ) ||
              isClaimantAdvocate
                ? data.caseFile.resolutionAgreementVerified ===
                  AgreementStatus.NOT_VERIFIED
                : false:false,
            tempResolutionAgreementStatus: 0,
          },
          hasResponse: data?.caseResponseList?.length > 0 ? true : false,
          selectedResponseId: selectedResponseId,
          claimantExpanded: (data.claimantList?.length ?? 0) - 1,
          respondentExpanded: (data.respondentList?.length ?? 0) - 1,
          synopsisExpanded: (data.caseFileDetail.synopsis?.length ?? 0) - 1,
          groundsExpanded: (data.caseFileDetail.grounds?.length ?? 0) - 1,
          prayersExpanded: (data.caseFileDetail.prayers.length ?? 0) - 1,
          permissionState: permissionClaimant,
          caseDetailTab:0,
          isAdvocate: isAdvocate,
          isClaimantAdvocate: isClaimantAdvocate,
          isRespondentAdvocate: isRespondentAdvocate,
          isRespondent: data.respondent,
          isClaimant: data.claimant,
          isAdmin: data.admin,
          finalState: state.finalState,
          currentSection: state.currentSection,
          currentPage: state.currentPage,
          isLoading: false,
          errorMessage: "",
        };

        return { ...tempBody };
      })
      .addCase(caseDetailThunk.rejected, () => {
        return { ...initState };
      })
      //initiate Case File
      .addCase(initiateCaseThunk.pending, () => {
        return { ...initState, isLoading: true };
      })
      .addCase(initiateCaseThunk.fulfilled, (state, action) => {
        const data = action.payload as any;

        return {
          ...state,
          caseFile: { ...state.caseFile, uniqueId: data.uniqueId },
          finalState: state.finalState,
          currentSection: state.currentSection,
          isLoading: false,
          errorMessage: "",
        };
      })
      .addCase(initiateCaseThunk.rejected, () => {
        return { ...initState };
      })

      // on save
      .addCase(saveCaseFileThunk.pending, (state) => {
        if (state.caseFile.status === SCRUTINY_REVIEW) {
          return { ...state, sending: true };
        } else {
          return { ...state, saving: true };
        }
      })
      .addCase(saveCaseFileThunk.fulfilled, () => {
        return { ...initState };
      })
      .addCase(saveCaseFileThunk.rejected, (state, action) => {
        state = {
          ...state,
          finalState: state.finalState,
          currentSection: state.currentSection,
          saving: false,
          sending: false,
          errorMessage: action.error.message ?? "",
        };
        return state;
      })

      .addCase(caseNeutralListThunk.pending, (state) => {
        return { ...state, isLoading: true };
      })
      .addCase(caseNeutralListThunk.fulfilled, (state, action) => {
        state = {
          ...state,
          arbitratorList: action.payload as [],
          isLoading: false,
        };

        return state;
      })
      .addCase(caseNeutralListThunk.rejected, (state, action) => {
        state = {
          ...state,
          finalState: state.finalState,
          saving: false,
          sending: false,
          isLoading: false,
          errorMessage: action.error.message ?? "",
        };
        return state;
      })
      .addCase(caseNeutralHistoryThunk.pending, (state) => {
        return { ...state, saving: true };
      })
      .addCase(caseNeutralHistoryThunk.fulfilled, (state, { payload }) => {
        state = {
          ...state,
          neutralHistory: payload,
          saving: false,
        };
        return state;
      })
      .addCase(caseNeutralHistoryThunk.rejected, (state, action) => {
        state = {
          ...state,
          finalState: state.finalState,
          saving: false,
          sending: false,
          errorMessage: action.error.message ?? "",
        };
        return state;
      })
      .addCase(updateResolutionAgreement.pending, (state) => {
        return { ...state, saving: true };
      })
      .addCase(updateResolutionAgreement.fulfilled, (state, action) => {
        state = {
          ...state,
          saving: false,
        };
        return state;
      })
      .addCase(updateResolutionAgreement.rejected, (state, action) => {
        state = {
          ...state,
          saving: false,
          sending: false,
          errorMessage: action.error.message ?? "",
        };
        return state;
      })

      // choose neutral
      .addCase(chooseNeutralThunk.pending, (state) => {
        return { ...state, chooseNeutralLoader: true };
      })
      .addCase(chooseNeutralThunk.fulfilled, (state, action) => {
        state = {
          ...state,
          chooseNeutralLoader: false,
        };
        return state;
      })
      .addCase(chooseNeutralThunk.rejected, (state, action) => {
        state = {
          ...state,
          chooseNeutralLoader: false,
        };
        return state;
      })

      // finalize neutral
      .addCase(finalizeNeutralThunk.pending, (state) => {
        return { ...state, chooseNeutralLoader: true };
      })
      .addCase(finalizeNeutralThunk.fulfilled, (state, action) => {
        state = {
          ...state,
          chooseNeutralLoader: false,
        };
        return state;
      })
      .addCase(finalizeNeutralThunk.rejected, (state, action) => {
        state = {
          ...state,
          chooseNeutralLoader: false,
        };
        return state;
      })

      // update resolution agreement ( verified|remark )
      .addCase(verifyResolutionAgreement.pending, (state) => {
        return({...state, isLoading: true});
      })
      .addCase(verifyResolutionAgreement.fulfilled, (state, action) => {
        state = {
          ...state,
          caseFile: {
            ...state.caseFile,
            resolutionAgreementVerified: state.caseFile.tempResolutionAgreementVerified,
            resolutionAgreementRemark: state.caseFile.tempresolutionAgreementRemark,
          },
          isLoading: false
        };

        
        return state;
      })
      .addCase(verifyResolutionAgreement.rejected, (state, action) => {
        return({...state, isLoading: true});
      });
  },
});

export const {
  setCurrentSection,
  setCurrentPage,
  goToNextSection,
  goToPrevSection,
  goToNextPage,
  goToPrevPage,
  setState,

  setCaseDateSlice,

  setCaseFile,
  setCaseRecommendation,

  updateCaseFile,

  //claimant
  setClaimantExpanded,
  addClaimant,
  deleteClaimant,

  editClaimant,
  updateClaimant,
  editPrimaryClaimant,

  // respondent
  setRespondentExpanded,
  addRespondent,
  deleteRespondent,
  setRespondentList,
  editRespondent,
  updateRespondent,

  // synopsis
  setSynopsis,
  setSynopsisExpanded,
  addSynopsis,
  addSynopsisEvidence,
  reUploadSynopsisEvidence,
  addEvidenceDocName,

  editSynopsis,
  updateSynopsis,

  editSynopsisEvidence,
  updateSynopsisEvidence,
  deleteSynopsisEvidence,
  deleteSynopsis,

  setDetailedFacts,

  setReasoningExpanded,
  setGrounds,
  editGrounds,
  updateGrounds,
  addGrounds,
  deleteGrounds,

  setPrayersExpanded,
  setPrayers,
  addPrayers,
  editPrayers,
  deletePrayers,

  setArbitratorType,
  setScrutinyCount,


  setStateValue,
  
  validate,
  reset,

  editDetailedFacts,
  updateCaseAgreementList,
  removeCaseAgreementDoc,
} = caseSlice.actions;

export default caseSlice.reducer;
