import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";

import Badge from "@mui/material/Badge";

import notificationIcon from "../../assets/icon/social/notifications_24px.png";
import menuIcon from "../../assets/icon/navigation/menu_24px.png";
import FileCaseIcon from "../../assets/icon/icon.png";

import { useNavigate } from "react-router-dom";

import pic from "../../assets/profile.png";

import ProfileSideBar from "../profileSideBar";
import { useState } from "react";
import NotificationBar from "../Notification/notificationBar";
import { Button, colors, styled } from "@mui/material";
import { CASE_DETAILS, FILE_CASE } from "../../routes/routes";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  initiateCaseThunk,
  reset,
  saveCaseFileThunk,
} from "../../redux/slice/caseSlice";
import { AppDispatch, RootState, store } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  ROLE_ADMIN,
  ROLE_NEUTRAL,
  ROLE_ORG_USER,
  ROLE_SUPER_ADMIN,
} from "../../constants/nav-constants";
import { DRAFT, NEW_CASE } from "../../constants/case-constants";
import { LoadingButton } from "@mui/lab";
import BalanceIcon from "@mui/icons-material/Balance";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import SecureStorage from "../../util/SecureStorage";
import { getNotificationThunk } from "../../redux/slice/notificationSlice";
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';


export default function CustomAppBar({ onClick }: any) {
  const navigate = useNavigate();
  const [openProfile, setOpenProfile] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [isLoading, setIsLoadinng] = useState(false);

  const dispatch: AppDispatch = useDispatch();

  const toggleProfileSideBar = () => {
    setOpenProfile(!openProfile);
  };

  const toggleNotificationBar = () => {
    setOpenNotification(!openNotification);
    if (!openNotification) {
      dispatch(getNotificationThunk())
    }

  };

  const newCase = () => {
    setIsLoadinng(true);
    dispatch(reset());
    dispatch(initiateCaseThunk())
      .unwrap()
      .then(() => {
        const data = store.getState().case;
        if (data) {
          setIsLoadinng(false);
          navigate(FILE_CASE + `/${NEW_CASE}/` + data.caseFile.uniqueId);
        }
      })
      .catch((e) => console.error(e));
  };
  const { profileDetail } = useSelector(
    (state: RootState) => state.profileDetail
  );
  const { notificationCount } = useSelector(
    (state: RootState) => state.notification
  );


  return (
    <>
      <AppBar className="shadow-none bg-light" position="sticky">
        <Toolbar className="px-4">
          <IconButton
            edge="start"
            color="default"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={onClick}
            className="d-none d-md-block"
          >
            <MenuIcon sx={{ color: "#000", fontSize: "28px" }} />
          </IconButton>

          <img
            className="d-block d-md-none ms-2"
            src="/rad-logo-v2.png"
            alt="logo"
            // width={73.35}
            height={25.31}
            onClick={() => {
              navigate("/");
            }}
            
          />

          <Box sx={{ flexGrow: 1 }} />

          <Box className="d-flex align-items-center gap-md-3 gap-2">
            {profileDetail?.personalDetail.roleRid !== ROLE_ADMIN &&
              profileDetail?.personalDetail.roleRid !== ROLE_SUPER_ADMIN &&
              profileDetail?.personalDetail.roleRid !== ROLE_NEUTRAL && (
                <LoadingButton
                  variant="contained"
                  loading={isLoading}
                  onClick={newCase}
                  startIcon={<BalanceIcon />}
                  sx={{ textTransform: "none" }}
                  color="primary"
                  className="px-3 my-2"
                  loadingPosition="start"
                  disableElevation
                >
                  {/* <div className="align-items-center ">
                    <img src={FileCaseIcon} alt="File Case" />
                    <span className="text-primary fs-normal-med ms-2"> */}
                  File case
                  {/* </span>
                  </div> */}
                </LoadingButton>
              )}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="default"
              onClick={toggleNotificationBar}
            >
              <Badge badgeContent={notificationCount} color="error">
                <MarkunreadOutlinedIcon 
                  sx={{ color: "#000", fontSize: "28px" }}
                />
              </Badge>
            </IconButton>

            {/* <IconButton
              size="large"
              edge="end"
              onClick={toggleProfileSideBar}
              color="inherit"
            >
              <AccountCircleIcon color="primary" fontSize="large" />
            </IconButton> */}
            <img
              className="profile-picture-50 cursor-pointer"
              src={
                profileDetail?.personalDetail?.profilePic
                  ? profileDetail?.personalDetail?.profilePictureUrl
                  : '/user-placeholder.png'
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/user-placeholder.png';
              }}
              alt="..."
              onClick={toggleProfileSideBar}
            />
          </Box>

          {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              color="default"
            >
              <MoreIcon />
            </IconButton>
          </Box> */}
        </Toolbar>
      </AppBar >
      <ProfileSideBar
        openProfile={openProfile}
        toggleProfileSideBar={toggleProfileSideBar}
      />
      <NotificationBar
        openNotification={openNotification}
        toggleNotificationBar={toggleNotificationBar}

      />
    </>
  );
}
