export const LSK_IS_LOGGED_IN = "XpjqpRzXjh";
export const LSK_TOKEN = "ApjqpRzXjk";
export const LSK_REFRESH_TOKEN = "bpjqpRzXji";

export const LSK_IS_FORGOT_PASSWORD = "ffpppwwrrrdd";
export const LSK_USER_DETAIL = "ysYSKBnnn";
export const LSK_SELECTED_MENU = "sdERERER";

export const EDIT_CASE_DETAILS = "sdfgdfgytsdfgywemxcvmRER";
export const EDIT_RESPONSE = "sdfgdfgytsdfgywemxcvmdfgidfgihdfuighuiyRER";

export const IS_RESPONDENT = "sdgjudysuiwedbnuixc";
export const IS_CLAIMANT = "mvnfuyfnmfik";
export const IS_ADMIN = "mvnfuyfnmfik";

export const OPEN_EXPAND_CASE = "fhghsdjghsdduibnxc";
export const IS_COUNTER_PRAYER = "uiwerjkdiowqe";

export const CURRENT_SECTION_KEY = "sdfsdfgsduf768grufs8723rufys";

export const FIRST_TIME_NEUTRAL_KEY = "sdfsdadsdfyiyuifgf768grufs872fys";
export const OPEN_DIALOG = "sdfsdadsdfyiyuifgf768grufsghfgiopio872fys";



export const IS_VIEW_MEDIA = "sdfsdadsdfyiysd43df56456fgf768grufsghfgiopio872fys";
export const VIEW_CASE_DATA = "sdfsdadsdfyiysd4sdfsd3df5645we6fgf7683grufsghfgiopio872fys";


export const SELECTED_RESPONDENT = "asdvasfdhfas348563478asgydhsvfc ";
