// Example usage:
const notification = {
  uniqueId: "77327cd1-f19a-11ee-9145-5254858378e8",
  userUniqueId:"",
  caseUniqueId: null || "",
  responseUniqueId: null || "",
  title: "TruPact scrutiny",
  description: "TruPact scrutiny - ",
  status:null||"",
  profilePic: null || "",
  fullName: null || "",
  isViewed: 0, //?   0: false 1:true
  createdAt: "2024-04-03 14:43:42",
  viewedAt: null,
};

export type NotificationType = typeof notification;

export const initNotification = {
  notificationList: null as NotificationType[] | null,
  notificationCount: 0,
  isLoading: false,
};

export type NotificationSliceType = typeof initNotification;
