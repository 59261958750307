import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppDispatch, store } from "../../redux/store";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { caseDetailThunk } from "../../redux/slice/caseSlice";

import { ShowLabel } from "../../components/common/ShowLabel";
import { CASE, CASE_EXPAND } from "../../routes/routes";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CustomLoader from "../../components/common/CustomLoader";
import ViewCaseContent from "../CaseDetails/components/ViewCaseContent";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import { StatusType } from "../../model/fileCase";
import ViewCaseResponseContent from "../CaseDetails/components/RespondentSide/ViewCaseResponseContent";
import { convertPermissionString } from "../../util/stringUtils";
import { caseResponseThunk, setResponse } from "../../redux/slice/caseResponseSlice";
import { RESPONSE_TAB } from "../../constants/case-constants";

export default function VersionComparison() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState<null | string>(null);

  const { uniqueId, caseVersion,isResponse } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      caseDetailThunk({
        draftStatus: 1,
        uniqueId: uniqueId as any,
        queryParams:isResponse==="1"?null: { version: caseVersion },
      })
    )
      .unwrap()
      .then((data:any) => {
        if(data.response){
          const permissionResponse = convertPermissionString(
            data.response.permissionState
          );
          dispatch(
            setResponse({
              ...data.response,
              uniqueId: uniqueId as string,
              permissionState: permissionResponse,
            })
          );
        }
        setIsLoading(false);
      })
      .catch((err) => { });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      caseDetailThunk({
        draftStatus: 1,
        uniqueId: uniqueId as any,
        queryParams:isResponse==="1"?null:  { version: caseVersion, preVersion: selectedVersion },
      })
    )
      .unwrap()
      .then((data:any) => {
        if(data.response){
         
        }
        setIsLoading(false);
      })
      .catch((err) => { });


      // if(isRespondent||isRespondentAdvocate){
      //   dispatch(caseResponseThunk({
      //     uniqueId: uniqueId as any,
      //     queryParams: { version: caseVersion, preVersion: selectedVersion },
      //   })).unwrap().then((data: any) => {
      //     if (data.response !== null) {
      //       dispatch(
      //         setResponse({ ...data, uniqueId: data?.caseFileResponse?.uniqueId as string })
      //       );
      //     } else {
      //       dispatch(setResponse({ uniqueId: data?.caseFileResponse?.uniqueId as string }));
      //     }
         
      //   })
      //     .catch((e) => console.error(e));
      // }
  }, [selectedVersion]);

  const { caseFile, caseFileDetail, versions, isLoading: isApiLoading, isClaimant, isAdmin, isClaimantAdvocate, isRespondent, isRespondentAdvocate, caseDetailTab } = store.getState().case;
  const { version }: any = caseFileDetail;

  const {caseFileResponse}=store.getState().caseResponse

  const handleClose = () => {
    navigate(CASE + "/" + uniqueId);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedVersion(event.target.value as string);
  };

  const handleExpandView = () => {

      navigate(CASE_EXPAND + "/" + caseFile.uniqueId);
    
  };

  return (!isLoading || !isApiLoading )? (
    <div className=" bg-light  h-100  ">
      <div className="d-flex align-items-center justify-content-between bg-light-white sticky-top px-4 pt-2 pb-1 ">
        <div className=" d-flex gap-3 ">
          <p className="fs-sm-heading-med p-0 m-0"> Version {caseVersion} </p>
          <div className="d-flex align-items-center">
            <ShowLabel status={StatusType.INFO} label="current version" />
          </div>
        </div>
        <div className="d-flex align-items-center gap-3 ">
          {versions?.length ? (
            <FormControl sx={{ minWidth: 250 }} size="small">
              <InputLabel id="demo-simple-select-label">
                Select Version to Compare
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedVersion as string}
                label="Select Version to Compare"
                onChange={handleChange}
              >
                {versions?.map((data: any, i) => {
                  return (
                    <MenuItem key={i} value={data.version}>
                      version {data.version}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : null}
          <div className="cursor-pointer" onClick={handleClose}>
            <CloseIcon color="primary" />
          </div>
        </div>
      </div>
      {/* <div className="d-flex justify-content-center container overflow-auto "> */}
        <div className=" d-flex row  gap-3  justify-content-center py-2">
          <div className="col bg-white h-100 overflow-auto ">
            {selectedVersion ? (
              <div className="d-flex align-items-center bg-light-white  px-3 py-1">
                <div className="fs-sm-lg-light flex-grow-1 p-0 m-0  ">
                  <p className="fs-sm-heading-light p-0 m-0">Version {caseVersion}</p>
                </div>
                <div
                  className="d-flex gap-2 align-items-center cursor-pointer"
                  onClick={handleExpandView}
                >
                  <CropFreeRoundedIcon color="primary" fontSize="small" />
                  <p className="fs-normal-med text-primary p-0 m-0">Expand</p>
                </div>
              </div>
            ) : null}
            {/* <ViewCaseContent showLoadBtn={false} 
          showHighlighter={
            selectedVersion ? true : false

          } /> */}
            {!isRespondent && !isRespondentAdvocate ? (
              <ViewCaseContent
                showLoadBtn={false}
                showHighlighter={selectedVersion ? true : false}
                isVersionPage={true}
              />
            ) : null}
            {!isClaimant && !isClaimantAdvocate && !isAdmin ? (
              <ViewCaseResponseContent
                showLoadBtn={false}
                showHighlighter={selectedVersion ? true : false}
                isVersionPage={true}
                version={version ?? null}
                selectedVersion={selectedVersion ?? ""}
              />
            ) : null}

          </div>
        <div className={` ${selectedVersion ? "" :"d-none"} col bg-white h-100 overflow-auto `}>
            {selectedVersion ? (
              <div className="d-flex align-items-center bg-light-white  px-3 py-1">
                <div className="fs-sm-lg-light flex-grow-1 p-0 m-0  ">
                  <p className="fs-sm-heading-light p-0 m-0">
                    {" "}
                    Version {selectedVersion}{" "}
                  </p>
                </div>
                <div
                  className="d-flex gap-2 align-items-center cursor-pointer"
                  onClick={handleExpandView}
                >
                  <CropFreeRoundedIcon color="primary" fontSize="small" />
                  <p className="fs-normal-med text-primary p-0 m-0">Expand</p>
                </div>
              </div>
            ) : null}
            {(selectedVersion && (!isRespondent && !isRespondentAdvocate)) ? (
              <ViewCaseContent
                showLoadBtn={false}
                showHighlighter={selectedVersion ? true : false}
                showPreVersion={selectedVersion ? true : false}
                isVersionPage={true}

              />
            ) : null}
            {(selectedVersion && (!isClaimant && !isClaimantAdvocate) && !isAdmin) ? (
              <ViewCaseResponseContent
                showLoadBtn={false}
                showHighlighter={selectedVersion ? true : false}
                showPreVersion={selectedVersion ? true : false}
                version={version ?? null}
                selectedVersion={selectedVersion ?? ""}
              />
            ) : null}

          </div>
        </div>
      {/* </div> */}
    </div>
  ) : (
    <CustomLoader />
  );
}
