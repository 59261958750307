import {
  Accordion,
  AccordionSummary,
  Box,
  IconButton,
  AccordionDetails,
} from "@mui/material";
import React from "react";

import DotIcon from "../../../../assets/icon/drag_indicator_24px.png";
import PenIcon from "../../../../assets/icon/Pen button.png";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import SynopsisEditBox from "./SynopsisRow";

import { useDispatch } from "react-redux";
import { AppDispatch, store } from "../../../../redux/store";
import {
  addSynopsis,
  deleteSynopsis,
  setSynopsisExpanded,
} from "../../../../redux/slice/caseSlice";
import { Evidence, SynopsisModel } from "../../../../model/caseDocument/synopsis";
import { useSnackbar } from "../../../../context/SnackbarContext";
import { formatDateTime } from "../../../../util/stringUtils";
import { DateOption } from "../../../../redux/slice/components/initCaseSliceState";


const SynopsisList = () => {
  const { showSnackbar } = useSnackbar()

  const { caseFileDetail, synopsisExpanded, response, hasResponse, isRespondent, isAdmin } =
    store.getState().case;

  const caseFileResponseDetail = response?.caseFileResponseDetail;

  const dispatch: AppDispatch = useDispatch();
  const { synopsis }: any =
    isRespondent || hasResponse ? caseFileResponseDetail : caseFileDetail;

  if (!synopsis.length) {
    dispatch(
      addSynopsis()
    );
  }


  const handleChangeAccordion =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (event.type === "click" || event.type === "keydown") {
        dispatch(setSynopsisExpanded(synopsisExpanded === panel ? -1 : panel));
      }
    };

  const handleDeleteSynopsis = (index: number) => {
    if (index !== 0) {
      setSynopsisExpanded(index);
    }
    dispatch(deleteSynopsis(index))
    showSnackbar("Deleted synopsis")
  };

  return (
    <div className="d-flex flex-column  w-100 overflow-auto">
      {synopsis.map((synopsis: SynopsisModel, i: number) => (
        <Accordion
          key={i}
          className="border-0"
          expanded={synopsisExpanded === i}
          onChange={handleChangeAccordion(i)}
        >
          <AccordionSummary
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <div className="d-flex w-100 gap-3 justify-content-between align-items-center">
              <div className="d-flex gap-3 align-items-center">
                <img src={DotIcon} alt="dot" width={24} height={24} />
                <Box
                  className="bg-light-blue1 d-flex justify-content-center text-primary rounded-circle align-items-center"
                  width={28.21}
                  height={28.21}
                >
                  {i + 1}
                </Box>

                {!(synopsisExpanded === i) && (
                  <>
                    <div>  

                      {synopsis.dateType === DateOption.NOT_APPLICABLE
                        ? "N/A"
                        : synopsis?.dates?.length
                          ? synopsis.dates
                            .map((date:any) =>
                              synopsis.dateType !== DateOption.ONLY_YEAR ? (
                                formatDateTime(date, false)
                              ) : (
                                date
                              )
                            )
                            .join(synopsis.dateType === DateOption.RANGE_OF_DATES ? " - " : " , ")
                          : null}</div>
                   

                    {synopsis.eventDetail && (
                      <Box className="text-grey text-truncate" maxWidth={400}>
                        {synopsis.eventDetail}
                      </Box>
                    )}
                    <IconButton className="p-0">
                      <img src={PenIcon} alt="pen" width={24} height={24} />
                    </IconButton>
                  </>
                )}
              </div>

              {synopsisExpanded === i && (
                <div className="fs-med-slg-medium  p-0 m-0">Synopsis</div>
              )}
              {!isAdmin ? (
              <IconButton
                className="p-0"
                onClick={() => handleDeleteSynopsis(i)}
              >
                <Box
                  className={`${synopsisExpanded === i ? "bg-light-red " : " bg-light-blue1"
                    } p-3 rounded-circle d-flex justify-content-center align-items-center`}
                  width={40}
                  height={40}
                >
                  <DeleteOutlinedIcon fontSize="large" className={`${synopsisExpanded === i ? "text-danger " : null}`} />
                </Box>
              </IconButton>
              ):<div></div>}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="d-flex h-100 flex-column">
              <SynopsisEditBox index={i} synopsis={synopsis} />
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SynopsisList;
