import { DateOption } from "../../redux/slice/components/initCaseSliceState";

export interface SynopsisModel {
  dates: string[];
  dateType: number;
  eventDetail: string;
  uniqueId: string;
  Year: number;
  selectedOption: string;
  evidences: Evidence[];
  errors: {
    dates: string;
    dateType: string;
    eventDetail: string;
    evidences: string;
    Year: number;
    fromDate: string;
    toDate: string;
    multipleDateTime: string | null;
    uniqueId: string;
    selectedOption: string;
  };
  hasChanges: boolean;
  resolution: string;
  resolved: boolean;
  rejected: RejectStatus | null;
  reason: string;
}


export interface Evidence {
  actualFileName: string;
  uploadedFileName: string;
  description: string;
  mimeType: string;
  evidenceType: string;
  evidenceName: string;
  sensitivity: number;
  remark: string;
  reUpload: number;
  docName: string;
  tempReUpload: boolean;
  error: string;
}

export enum RejectStatus {
  ACCEPTED = "APPROVED",  // change 'ACCEPTED' to 'APPROVED'
  REJECTED = "REJECTED",
}
