import { error } from "console";
import { Grounds } from "../../model/caseDocument/grounds";
import { PrayerModel } from "../../model/caseDocument/prayer";
import { Evidence, SynopsisModel } from "../../model/caseDocument/synopsis";
import { DateOption } from "../../redux/slice/components/initCaseSliceState";
import { formatDateTime } from "../stringUtils";

export const validateSynopsis = (synopsisList: SynopsisModel[]) => {
  let isValid = true;
  let errorMessageList = [] as string[];
  let synopsisErrIndex = [] as number[];

  const tempList: SynopsisModel[] = synopsisList.map((synopsis, index) => {
    const errors: any = {};
    let tempEvidence: Evidence[] = [];
    const currentDate = new Date().toISOString().split("T")[0];


    function parseDate(dateStr:string) {
      // Try to parse the date in YYYY-MM-DD format
      const ymdMatch = dateStr.match(/^(\d{4})-(\d{2})-(\d{2})$/);
      if (ymdMatch) {
        return new Date(`${ymdMatch[1]}-${ymdMatch[2]}-${ymdMatch[3]}`);
      }

      // Try to parse the date in DD-MM-YYYY format
      const dmyMatch = dateStr.match(/^(\d{2})-(\d{2})-(\d{4})$/);
      if (dmyMatch) {
        return new Date(`${dmyMatch[3]}-${dmyMatch[2]}-${dmyMatch[1]}`);
      }

      throw new Error(`Date format for '${dateStr}' is not recognized.`);
    }
    
    const mindate =parseDate("01-01-1900")
  

    
    if (!synopsis.dateType) {
      errors.dateType = "Please Select Date Option";
      isValid = false;
    }
    if (
      synopsis.dates[0] > currentDate ||
      synopsis.dates[1] > currentDate ||
      synopsis.dates[0] < mindate.toISOString() ||
      synopsis.dates[1] < mindate.toISOString() 
      
    ) {
      if (synopsis.dateType === DateOption.ONLY_YEAR) {
        errors.dates = "Year is Invalid";
        errorMessageList.push("Year is Invalid");
        isValid = false;
      } else {
        errors.dates = "Date is Invalid ";
        errorMessageList.push("Date is Invalid");
        isValid = false;

        synopsisErrIndex.push(index);
      }
    }
    if (synopsis.dateType === DateOption.SINGLE_DATE) {
      
      if (!synopsis.dates.length || synopsis.dates[0] === "") {
        errors.dates = "Date is required!";
        errorMessageList.push("Date is Required");
        isValid = false;
                synopsisErrIndex.push(index);

      }
    }
    if (synopsis.dateType === DateOption.MULTIPLE_DATES) {
      if (!synopsis.dates.length) {
        errors.dates = "Date is required!";
        errorMessageList.push("Date is Required");
        isValid = false;
                synopsisErrIndex.push(index);

      }
    }

    if (synopsis.dateType === DateOption.RANGE_OF_DATES) {
      if (
        !(synopsis.dates.length === 2) ||
        synopsis.dates[0] === "" ||
        synopsis.dates[1] === ""
      ) {
        errors.dates = "Date is required!";
        errorMessageList.push("Date is Required");
        isValid = false;
               synopsisErrIndex.push(index);

      } else if (synopsis.dates[0] > synopsis.dates[1]) {
        errors.dates = "From Date can't be later than To Date!";
        errorMessageList.push("From Date can't be later than To Date");
        isValid = false;
              synopsisErrIndex.push(index);

      } else if (synopsis.dates[0] === synopsis.dates[1]) {
        errors.dates = "From Date and To Date can't be same!";
        errorMessageList.push("From Date and To Date can't be same");
        isValid = false;
             synopsisErrIndex.push(index);

      }
    }
    if (synopsis.dateType === DateOption.ONLY_YEAR) {
      if (!synopsis.dates.length || synopsis.dates[0] === "") {
        errors.dates = "Year is required!";
        errorMessageList.push("Year is Required");
        isValid = false;
                synopsisErrIndex.push(index);

      } else if (synopsis.dates[0] !== "") {
        if (synopsis.dates[0].length < 4 || synopsis.dates[0] < "1947") {
          errors.dates = "Year is Invalid!";
          errorMessageList.push("Year is Invalid");
          isValid = false;
                  synopsisErrIndex.push(index);

        }
      }
    }

    if (!synopsis.eventDetail?.trim()) {
      errors.eventDetail = "Event is required!";
      errorMessageList.push("Event is Required");
      isValid = false;
             synopsisErrIndex.push(index);

    }

    if (!synopsis.evidences.length) {
      errors.evidences = "Evidences is required!";
      errorMessageList.push("Evidences is Required");
      isValid = false;
             synopsisErrIndex.push(index);

    }

    if (synopsis.evidences.length) {
      tempEvidence = synopsis.evidences.map((evidence) => {
        if (!evidence.description) {
          isValid = false;
          errorMessageList.push("Description cannot be empty !");
                   synopsisErrIndex.push(index);

          return { ...evidence, error: "Description cannot be empty !" };
        }
        return { ...evidence, error: "" };
      });
    }

    return {
      ...synopsis,
      evidences: tempEvidence,
      errors,
    };
  });

  return { tempList, isValid, errorMessageList, synopsisErrIndex};
};
export const validateGrounds = (groundsList: Grounds[]) => {
  let isValid = true;
  let errorMessageList = [] as Array<string>;
  const tempList: Grounds[] = groundsList.map((ground) => {
    // let tempEvidence: Evidence[] = [];

    if (!ground.groundsDetail) {
      isValid = false;
      errorMessageList.push("Please fill in the Grounds before proceeding!");
      return {
        ...ground,

        errors: "Enter the Grounds!",
      };
    }

    // if (ground.evidences.length) {
    //   tempEvidence = ground.evidences.map((evidence) => {
    //     if (!evidence.description) {
    //       isValid = false;
    //       return { ...evidence, error: "Description cannot be empty !" };
    //     }
    //     return { ...evidence, error: "" };
    //   });
    // }

    return {
      ...ground,
    };
  });

  return { tempList, isValid, errorMessageList };
};
// export const validateDetailedFacts = (detailedFacts: DetailedFacts) => {
//   let isValid = true;
//   let tempBody = {};
//   if (!detailedFacts.factsDetail) {
//     tempBody = { ...detailedFacts, errors: "Prayers is required!" };
//     isValid = false;
//   }

//   return { tempBody, isValid };
// };
export const validatePrayers = (prayersList: PrayerModel[]) => {
  let isValid = true;
  let errorMessageList = [] as Array<string>;
  const tempList: PrayerModel[] = prayersList.map((prayers) => {
    const errors: any = {};

    if (!prayers.prayerDetail) {
      errors.prayerDetail = "Prayers is required!";
      errorMessageList.push("Prayers is Required");
      isValid = false;
    }

    return {
      ...prayers,
      errors,
    };
  });

  return { tempList, isValid, errorMessageList };
};
