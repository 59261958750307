import { CircularProgress } from '@mui/material';
import React, { useState, useEffect } from "react";
import { AppDispatch } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { verifyEmailThunk } from '../../redux/slice/userSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';

const VerifyEmailLP = () => {
    const [isLoading, setIsLoading] = useState(true);
    // const [statusType, setStatusType] = useState(0);
    // const [statusMessage, setStatusMessage] = useState('');
    const dispatch: AppDispatch = useDispatch()
    const { token } = useParams();

    const handleVerifyEmail = () => {
        setIsLoading(true);
        dispatch(verifyEmailThunk(token))
            .unwrap()
            .then((data) => {
                setIsLoading(false);
                // setStatusType(1);
                // setStatusMessage('Email Verified')
            })
            .catch((error) => {
                // setIsLoading(false);
            });
    }

    useEffect(() => {
        handleVerifyEmail();
    }, []);

    return (
        <>
            {isLoading ?
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <CircularProgress />
                </div> :
                <div className="d-flex justify-content-center align-items-center vh-100">
                    <div className="d-flex gap-3 flex-column align-items-center">
                        <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
                        <p className="fs-sm-lg-semibold text-center p-0 m-0">Email Verified</p>
                    </div>
                </div>}
        </>
    );
}

export default VerifyEmailLP;