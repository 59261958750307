import React from 'react';
import { Button, Drawer } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface ButtonProps {
  label: string;
  onClick: () => void;
}

interface DrawerLayoutProps {
    open: boolean;
    drawerWidth?: string;
    title: string;
    handleClose: () => void;
    children?: React.ReactNode;
    negativeActions?: ButtonProps[];
    positiveActions?: ButtonProps[];
}

const DrawerLayout: React.FC<DrawerLayoutProps> = ({ open, drawerWidth, title, handleClose, children, negativeActions, positiveActions }) => {
  
    return (
      <Drawer
        anchor="right"
        open={open}
        sx={{
          "& .MuiDrawer-content": {
            padding: (theme) => theme.spacing(2),
          },
          "& .MuiDrawer-paper": {
            width: drawerWidth || '50vw',
          },
          "& .MuiDrawer-paperAnchorLeft": {
            width: 800,
          },
          "& .MuiDrawer-paperAnchorTop": {
            height: '100vh',
          }, 
        }}
        className='position-relative'
        onClose={handleClose}>
          <div className="d-flex flex-column vh-100">
            <div className="d-flex gap-3 align-items-center" style={{padding: '20px'}}>
              <div className="d-flex flex-grow-1">
                <span className="fs-med-lg-semibold">{title}</span>
              </div>
              <div className="d-flex flex-shrink-0">
                <div className="d-flex justify-content-center align-items-center cursor-pointer" style={{border: '1px solid #E5E5FF', borderRadius: '50%', width: '40px', height: '40px'}} onClick={handleClose}>
                  <CloseIcon color="primary" />
                </div>
              </div>
            </div>

            <div className="flex-grow-1 overflow-auto" style={{padding: '20px'}}>
              {children}
            </div>

            <div className="d-flex gap-2 justify-content-end align-items-center" style={{padding: '20px'}}>
              {
                negativeActions?.map((data, key) => (
                  <Button
                    sx={{textTransform: 'none', minWidth: '100px'}}
                    variant="text"
                    color="primary"
                    onClick={data.onClick}
                    key={key}>
                      <span className="fs-normal-med">{data.label}</span>
                  </Button>
                ))
              }
              {
                positiveActions?.map((data, key) => (
                  <Button
                    sx={{textTransform: 'none', minWidth: '100px'}}
                    variant="contained"
                    color="primary"
                    onClick={data.onClick}
                    disableElevation
                    key={key}>
                      <span className="fs-normal-med">{data.label}</span>
                  </Button>
                ))
              }
            </div>
          </div>
      </Drawer>
    );
  };
  
export default DrawerLayout;