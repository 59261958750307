import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AppDispatch, RootState, store } from "../../redux/store";
import {
  CLAIMANT_DETAILS,
  COUNTER_PRAYER,
  DETAILED_FACTS,
  GROUNDS_REASONING,
  METHOD_OF_RESOLUTION,
  PRAYERS,
  REPRESENTATION_TYPE,
  RESPONDENT_DETAILS,
  RESPONDENT_PRAYER,
  RESPONDENT_STATEMENT_OF_OBJECTION,
  RESPONDENT_SYNOPSIS,
  SYNOPSIS,
} from "../../constants/case-state";
import ClaimantList from "./components/Claimant/ClaimantList";
import RespondentList from "./components/Respondent/RespondentList";
import ClaimantSynopsisList from "./components/Synopsis/SynopsisList";
import SynopsisList from "../CaseDetails/components/synopsis/SynopsisList";
import DetailedFacts from "./components/DetailedFact/DetailedFacts";
import Reasoning from "./components/Reasoning/ReasoningList";
import PrayerList from "./components/Prayers/PrayersList";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addClaimant,
  addGrounds,
  addPrayers,
  addRespondent,
  addSynopsis,
  caseDetailThunk,
  setState,
  validate,
} from "../../redux/slice/caseSlice";

import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../../constants/nav-constants";
import StatementOfObjectionRow from "../CaseDetails/components/StatementOfObjection/StatementOfObjectionRow";
import { caseResponseThunk, setResponse, validateResponse } from "../../redux/slice/caseResponseSlice";
import {
  CURRENT_SECTION_KEY,
  EDIT_CASE_DETAILS,
  EDIT_RESPONSE,
} from "../../constants/local-storage-constants";
import { Evidence } from "../../model/caseDocument/synopsis";
import SecureStorage from "../../util/SecureStorage";
import CustomLoader from "../../components/common/CustomLoader";
import { convertPermissionString } from "../../util/stringUtils";
import { checkForChangesResponse } from "../../util/case/checkForChangesResponse";
import { useSnackbar } from "../../context/SnackbarContext";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { RM_ARBITRATION } from "../../constants/case-constants";

const EditCase = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [savedData, setSavedData] = useState<any>();
  const [savedResponse, setSavedResponse] = useState<any>();

  const caseData = useSelector((state: RootState) => state.case);
  const caseResponseData = useSelector((state: RootState) => state.caseResponse);
  const { isRespondent, hasResponse, isLoading,caseResponseList,selectedResponseId ,caseFile,isAdmin} = caseData;
  const { isResponseLoading} = caseResponseData

  const { showSnackbar } = useSnackbar();

  
  let currentSection = SecureStorage.getItem(CURRENT_SECTION_KEY);
  

  currentSection = currentSection ? currentSection : 0; //?getting null if we save 0 in localStorage
  const { uniqueId } = useParams();

  useEffect(() => {
    const editFlag = SecureStorage.getItem(EDIT_CASE_DETAILS);
    if (editFlag) {
      return;
    } else {
      dispatch(
        caseDetailThunk({
          draftStatus: 1,
          uniqueId: uniqueId as string,
        })
      )
        .unwrap()
        .then((data: any) => {
          if (data.response !== null) {
            if(data.caseResponseList.length > 0) {

              dispatch(caseResponseThunk(data.response.caseResponseFile.uniqueId?{ uniqueId:uniqueId}:null))
            }
            } else {
            dispatch(setResponse({
              uniqueId: uniqueId as string,
              caseFileResponseDetail: {
                synopsis: [
                  {
                    dates:[],
                    eventDetail: "",
                    evidences: [] as Evidence[],
                    rejected: null,
                    reason: "",
                    hasChanges: false,
                  },
                ],
                prayer: {
                  prayers: data.caseFileDetail.prayers, counterPrayer: [
                    {
                      prayerDetail: "",
                      intensity: 1,
                    },
                  ],
                },
              }
            }));
          }
        })
        .catch((e) => {
          console.error(e);

          navigate(-1);
        });
    }
  }, []);

  const [isSecondLoad, setIsSecondLoad] = useState(false);

  const { roleRid } =
    store.getState().profileDetail.profileDetail.personalDetail;

  useEffect(() => {
    if (isSecondLoad) {
      if (caseResponseList?.length) {
        setSavedResponse(store.getState().caseResponse);
      } else {
        setSavedData(store.getState().case);
      }
    }

    if (!isSecondLoad) {
      setIsSecondLoad(true);
      // navigate(CASE + "/" + caseData.caseFile.uniqueId);
    }
  }, [isSecondLoad]);

  const labelList = [
    {
      label: "Claimant",
      id: CLAIMANT_DETAILS,
      element: <ClaimantList />,
    },
    {
      label: "Respondent",
      id: RESPONDENT_DETAILS,
      element: <RespondentList />,
    },

    {
      label: "Synopsis",
      id: SYNOPSIS,
      element: hasResponse ? <SynopsisList /> : <ClaimantSynopsisList />,
    },
    {
      label: "Detailed Facts",
      id: DETAILED_FACTS,
      element: <DetailedFacts />,
    },
    {
      label: "Grounds/ Reasoning",
      id: GROUNDS_REASONING,
      element: <Reasoning />,
    },
    {
      label: "Prayer",
      id: PRAYERS,
      element: <PrayerList />,
    },
    {
      label: "Statement of Defence",
      id: RESPONDENT_STATEMENT_OF_OBJECTION,
      element: <StatementOfObjectionRow />,
    },
    {
      label: "Synopsis",
      id: RESPONDENT_SYNOPSIS,
      element: <SynopsisList />,
    },
  ];



  const handleCancel = () => {
    if (hasResponse) {
      dispatch(setResponse({ ...savedResponse }));
    } else {
      dispatch(setState({ ...savedData }));
    }
    SecureStorage.removeItem(CURRENT_SECTION_KEY);

    navigate(-1);
  };


  const onUpdate = () => {
    if (currentSection === RESPONDENT_SYNOPSIS || currentSection === RESPONDENT_STATEMENT_OF_OBJECTION || currentSection === RESPONDENT_PRAYER || currentSection === COUNTER_PRAYER){
      SecureStorage.setItem(EDIT_RESPONSE, selectedResponseId);

      dispatch(
        validateResponse()
      );
      const { isValid, errorMessage } = store.getState().caseResponse;

      if (isValid) {
        navigate(-1);
        SecureStorage.removeItem(CURRENT_SECTION_KEY);

      } else {
        showSnackbar(errorMessage);
      }
      
    }else{

      SecureStorage.setItem(EDIT_CASE_DETAILS, true);


      dispatch(
        validate({
          SaveAsDraft: false,
        })
      );
      const { valid, errorMessage } = store.getState().case;

      if (valid) {
        SecureStorage.removeItem(CURRENT_SECTION_KEY);

        navigate(-1);
      } else {
        showSnackbar(errorMessage);
      }
    }
    
  
  
  };


  const addMore = () => {
    if (currentSection === CLAIMANT_DETAILS) {
      dispatch(addClaimant());
    }
    if (currentSection === RESPONDENT_DETAILS) {
      dispatch(addRespondent());
    }
    if (currentSection === SYNOPSIS) {
      dispatch(addSynopsis());
    }
    if (currentSection === GROUNDS_REASONING) {
      dispatch(addGrounds());
    }
    if (currentSection === PRAYERS) {
      dispatch(addPrayers());
    }
  };

  const getTooltipContent = () => {
    if (currentSection === RESPONDENT_DETAILS && caseFile.resolutionMethod === RM_ARBITRATION) {
      return "Click “Add more Respondent” to enter details for each Respondent separately";
    } else if (currentSection === RESPONDENT_DETAILS && caseFile.resolutionMethod !== RM_ARBITRATION) {
      return "Click “Add more Second Party” to enter details for each Second Party separately";
    } else if (currentSection === CLAIMANT_DETAILS && caseFile.resolutionMethod === RM_ARBITRATION) {
      return "Click “Add more Claimant” to enter details for each Claimant separately";
    } else if (currentSection === CLAIMANT_DETAILS && caseFile.resolutionMethod !== RM_ARBITRATION) {
      return "Click “Add more First Party” to enter details for each First Party separately";
    } else if (currentSection === SYNOPSIS) {
      return "For each event of Synopsis, Click “Add more Synopsis”";
    } else if (currentSection === GROUNDS_REASONING) {
      return "For each new Grounds, Click “Add more Grounds”";
    } else if (currentSection === PRAYERS) {
      return "For each Prayer, Click “Add more Prayer”"
    }
  }

  if (isLoading ||isResponseLoading) {
    return <CustomLoader />;
  } else {
    return (
      <div className="vh-100 bg-light d-flex flex-column ">
        {/* <div className="mt-4 text-center fs-sm-heading-med  ">
        {labelList.find((item) => item.id === currentSection)?.label}
      </div> */}
        <div className="container d-flex flex-column h-100 mt-5 mb-3 overflow-hidden">
          
           {labelList.find((item) => item.id === currentSection)?.element}
        
          <div className="text-center mt-auto">
            <Button
              className="fs-normal-med p-0 fw-bold"
              variant="text"
              sx={{ textTransform: "none" }}
              onClick={addMore}
            >
              {roleRid === ROLE_ADMIN || roleRid === ROLE_SUPER_ADMIN ? null : (
                <>
                  {currentSection === RESPONDENT_DETAILS
                            ? caseFile.resolutionMethod === RM_ARBITRATION ? "+ Add More Respondent" : "+ Add More Second Party"
                            : ""}
                          {currentSection === CLAIMANT_DETAILS
                            ? caseFile.resolutionMethod === RM_ARBITRATION ? "+ Add More Claimant" : "+ Add More First Party"
                            : ""}
                  {currentSection === SYNOPSIS ? "+ Add More Synopsis" : ""}
                  {currentSection === GROUNDS_REASONING
                    ? "+ Add More Gounds / Reasoning"
                    : null}

                  {currentSection === PRAYERS && !isRespondent
                    ? "+ Add More Prayer"
                    : null}
                </>
              )}
            </Button>
            {currentSection === REPRESENTATION_TYPE || currentSection===METHOD_OF_RESOLUTION || currentSection===DETAILED_FACTS ||isAdmin? "" : 
                        <><Tooltip title={getTooltipContent()}><InfoOutlinedIcon />
                          </Tooltip></>}

          </div>
        </div>

        <div className="mt-auto d-flex justify-content-between bg-white px-5 py-3">
          <Button variant="text" onClick={handleCancel}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            sx={{ textTransform: "none" }}
            onClick={onUpdate}
          >
            Update
          </LoadingButton>
        </div>
      </div>
    );
  }
};

export default EditCase;
