import React, { Suspense, useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "../context/UserContext";
import { LOGIN } from "../routes/routes";
import CustomLoader from "../components/common/CustomLoader";
import CircularWithValueLabel from "../components/common/CircularWithValueLabel";
import { IconButton, Typography } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import { AppDispatch, RootState, store } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { LSK_USER_DETAIL } from "../constants/local-storage-constants";
import { setProfileDetail, profileDetailThunk, getUserKycThunk, initKycData, initLawFirmKycData, initNeutralKycData, initOrgKycData } from "../redux/slice/userSlice";
import SecureStorage from "../util/SecureStorage";
import { ROLE_INDIVIDUAL, ROLE_ORG_ADMIN, ROLE_LAW_FIRM_ADMIN, ROLE_NEUTRAL } from "../constants/nav-constants";

const FileCaseLayout: React.FC = () => {
  const { isLoggedIn } = useUserContext();
  const dispatch: AppDispatch = useDispatch();
  const { profileDetail, profileUpdated } = useSelector(
    (state: RootState) => state.profileDetail
  );
  useEffect(() => {
    if (!isLoggedIn) return;
    let userData: any = SecureStorage.getItem(LSK_USER_DETAIL);
    if (userData?.orgDetail) {
      //check organization detail is stored, otherwise call profile-detail api
      dispatch(
        setProfileDetail({
          ...profileDetail,
          personalDetail: {
            ...profileDetail.personalDetail,
            ...userData.personalDetail,
          },
          orgDetail: { ...profileDetail.orgDetail, ...userData.orgDetail },
        })
      );
    }
    // dispatch(profileDetailThunk())
    //   .unwrap()
    //   .then((data: any) => {
    //     let userDetail = {
    //       personalDetail: {
    //         ...profileDetail.personalDetail,
    //         ...data.personalDetail,
    //       },
    //       orgDetail: { ...profileDetail.orgDetail, ...data.orgDetail },
    //     };

    //     SecureStorage.setItem(LSK_USER_DETAIL, userDetail);

    //     dispatch(setProfileDetail(userDetail));
    //   })
    //   .catch((error: any) => {});

      dispatch(getUserKycThunk())
      .unwrap()
      .then((data: any) => {
        if (data) {
          let updatedDetails = {};
          const { profileDetail } = store.getState().profileDetail;

          if (data.kycData) {
            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: data.kyc?.kycStatus,
                kycData: data.kycData,
              },
            };
          } else {
            const kycData =
              profileDetail?.personalDetail?.roleRid === ROLE_INDIVIDUAL
                ? initKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_ORG_ADMIN
                ? initOrgKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_LAW_FIRM_ADMIN
                ? initLawFirmKycData
                : profileDetail?.personalDetail?.roleRid === ROLE_NEUTRAL
                ? initNeutralKycData
                : [];

            updatedDetails = {
              ...profileDetail,
              kycDetail: {
                kycStatus: 0,
                kycData: kycData,
              },
            };
          }
          

          dispatch(setProfileDetail(updatedDetails));
        }
      })
      .catch((error: any) => {});
  }, []);

  return isLoggedIn ? (
    <Suspense fallback={<CustomLoader />}>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to={LOGIN} />
  );
};

export default FileCaseLayout;
